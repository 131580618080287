import AdminFilterMenu from './AdminFilterMenu';
import { useEffect, useRef } from 'react';

const AdminFilterElement = ({
  open,
  setOpen,
  options,
  value,
  setValue,
  defaultText,
  part,
  handleUpdateValue,
  color = 'blue',
}) => {

  const anchorRef = useRef(null);

  useEffect(() => {
    handleUpdateValue({ value, part })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])


  return (
    <div className="relative">
      <div
        className={`p-2 rounded bg-${color}-200 hover:bg-${color}-300 flex space-x-2 items-center border border-gray-500 text-xs text-gray-700 cursor-pointer`}
        onClick={() => { setOpen(state => !state); console.log('2') }}
        ref={anchorRef}
      >
        {/* {returnPlusIcon('h-4 w-4')} */}
        <span>{options.find(elem => elem.value === value)?.label || defaultText}</span>
      </div>
      {!open ? '' : (
        <div>
          <AdminFilterMenu
            open={open}
            setOpen={setOpen}
            handleSelectOption={(option) => { setValue(option.value) }}
            filterOptions={options}
            anchorRef={anchorRef}
            color={color}
          />
        </div>
      )}
    </div>
  );
};

export default AdminFilterElement;