import LoadingPage from './LoadingPage';
import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import { exclamationIcon } from "../helpers/icons";
import {useParams} from 'react-router-dom';
import FallbackPage from './FallbackPage';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { getTopicWithPublishedArticle } from '../actions/topics';
import { createArticleAndPublishWithStream } from '../actions/articles';
import ViewTopicSidePanel from './ViewTopicSidePanel';
import Paywall from './Paywall';
import SearchAtBottom from './SearchAtBottom';
import { convertStringToReadingTime } from '../helpers/utilities';
import TopicShowcase from './TopicShowcase';
import { trackEvent } from '../dependencies/mixpanel';
import { usePrevious } from '../helpers/usePrevious';
import remarkGfm from 'remark-gfm'
import { generateAnchorSlugForReactMarkdown } from '../helpers/utilities';
import { extractH2 } from '../helpers/utilities';


export const ViewStreamingTopicWithPublishedArticlePage = () => {

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [title, setTitle] = useState(''); // eslint-disable-line no-unused-vars
  const [code, setCode] = useState(''); // eslint-disable-line no-unused-vars
  const [content, setContent] = useState('');
  const [upvoted, setUpvoted] = useState(false);
  const [numUpvotes, setNumUpvotes] = useState(0);
  const user = useStore(state => state.user);
  const {topicId} = useParams();
  const [behindPaywall, setBehindPaywall] = useState(false);
  const [streamingResponse, setStreamingResponse] = useState(false); // eslint-disable-line no-unused-vars
  const [streamingResponseComplete, setStreamingResponseComplete] = useState(false);
  const [streamingSuccessful, setStreamingSuccessful] = useState(false);
  const [readingTime, setReadingTime] = useState('');
  const [anchorList, setAnchorList] = useState([]);
  const [markdownComponents, setMarkdownComponents] = useState(null); // eslint-disable-line no-unused-vars

  // 1. This page does not automatically reload when the params change.  Therefore, I'm manualy reloading it.
  const prevTopicId = usePrevious(topicId);
  useEffect(() => {
    // console.log('topicId changed to: ', topicId)
    // console.log('prevTopicId changed to: ', prevTopicId)
    if (prevTopicId && prevTopicId !== topicId) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId])


  // 2. Check if the article is published.
  //    If so, retrieve it and show it.
  //    If not, begin streaming it.
  useEffect(() => {
    if (title) return // console.log('Not retrieving topic');
    getTopicWithPublishedArticle({
      user,
      topicId
    }).then((result) => {
      setBehindPaywall(result?.behindPaywall ? result?.behindPaywall : false)
      setTitle(result?.title || '');
      setCode(result?.code || '');
      setUpvoted(result?.upvoted || false);
      setNumUpvotes(result?.numUpvotes || 0);
      if (result?.publishedArticleId && result?.content) {
        console.log('The topic or article was found.');
        setContent(result?.content);
        setLoading(false);
        setStreamingResponseComplete(true)
      } else {
        console.log('The topic or article could not be found or was not published.');
        setStreamingResponse(true);
        // setLoading(false);
      }      
    }).catch((error) => {
      console.log('Error retrieving topic and article', error?.status)
      setLoading(false);
      setErrorMessage('This article could not be found or generated.')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const appendToContent = (str) => {
    setContent(state => state + str);
  }

  // 3. If the article was not published, generate an article with a readable stream.
  useEffect(() => {
    if (!streamingResponse) return;
    if (content) {
      setStreamingResponse(false);
      console.log('Will not stream because content exists.')
    }
    createArticleAndPublishWithStream({
      user,
      topicId
    }, {
      handleStartStreaming: () => {setLoading(false)},
      appendToContent,
    }).then(() => {
      // setBehindPaywall(result?.behindPaywall ? result?.behindPaywall : false)
      console.log('Ended stream');
      setStreamingResponse(false);
      setStreamingResponseComplete(true);
      setStreamingSuccessful(true);
      trackEvent({ name: 'Article with published stream created', params: { topicId } })
    }).catch((error) => {
      console.log('Error creating article', error)
      setLoading(false);
      setErrorMessage('This article could not be found or generated.')
      setStreamingResponse(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingResponse])

  useEffect(() => {
    if (!streamingSuccessful) return;
    setTimeout(() => {
      setStreamingSuccessful(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingSuccessful, topicId]);

  useEffect(() => {
    if (!streamingResponseComplete) return // console.log('Cannot calculate reading time until streaming is complete.');
    if (!content) return // console.log('Cannot calculate reading time.  No content');
    if (streamingResponse) return // console.log('Cannot calculate reading time.  Response is streaming.');
    const readingTime = convertStringToReadingTime({ str: content })
    setReadingTime(readingTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingResponseComplete]);

  useEffect(() => {
    if (!streamingResponseComplete) return // console.log('Cannot calculate anchorList until streaming is complete.');
    if (!content) return // console.log('Cannot calculate anchorList.  No content');
    if (streamingResponse) return // console.log('Cannot calculate anchorList.  Response is streaming.');
    let anchorList = extractH2(content);
    anchorList = anchorList.map((elem) => {
      return {
        label: elem,
        value: generateAnchorSlugForReactMarkdown(elem)
      }
    })
    setAnchorList(anchorList);

    setMarkdownComponents({
      h2: ({ node, ...props }) => {
        return (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h2
            id={generateAnchorSlugForReactMarkdown(props?.children[0])}
            {...props}
            style={{ scrollPaddingTop: '40px' }}
          ></h2>
        )
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingResponseComplete]);


  if (loading) {
    return <LoadingPage />
  } else if (errorMessage) {
    return <FallbackPage subMessage={errorMessage} />
  } else {
    return (

        <div className="min-h-screen-adj bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">

          <div className="flex space-x-8 w-full ">
            
          <div className="">
              <ViewTopicSidePanel 
                topicId={topicId}
                numUpvotes={numUpvotes}
                setNumUpvotes={setNumUpvotes}
                upvoted={upvoted}
                setUpvoted={setUpvoted}
                streamingResponse={streamingResponse}
                streamingSuccessful={streamingSuccessful}
                readingTime={readingTime}
                behindPaywall={behindPaywall}
                anchorList={anchorList}
              />
            </div>


            <div className="flex flex-col space-y-4 w-full">

              {/* Content section */}
              <div className="flex flex-col items-center w-full">

                <div className="prose w-full bg-white p-10 rounded-lg border max-w-7xl">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                  >{behindPaywall ? content.concat('...') : content}</ReactMarkdown>
                </div>

              {behindPaywall ? <Paywall /> : ''}  
              <div className="flex justify-center my-4 py-4">
                {!behindPaywall && streamingResponseComplete ? <TopicShowcase /> : ''}
              </div>
              <div className="flex justify-center max-w-xl w-full">
                {!behindPaywall && streamingResponseComplete ? <SearchAtBottom /> : ''}
                </div>


              
              </div>

            </div>

          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

        </div>
    )
  }
}

export default ViewStreamingTopicWithPublishedArticlePage;