import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import { updateAccount } from "../actions/accounts";
import { spinnerIcon, exclamationIcon, returnChevronRight, returnCheckCircleIcon } from "../helpers/icons";
import RequiredAsterisk from './RequiredAsterisk';
import {/* returnProfilePath, */ returnHomePath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../dependencies/mixpanel'
import Select from 'react-select';
import { validateEmail } from "../helpers/validation";

const ProfileTab = () => {

  const account = useStore(state => state.account);
  const user = useStore(state => state.user);
  const setAccount = useStore(state => state.setAccount);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState(account?.name ? account?.name : '');
  const [buttonSucceeded, setButtonSucceeded] = useState(false);
  const navigate = useNavigate();
  let email = account.email;
  const [eduEmail, setEduEmail] = useState(account?.eduEmail ? account?.eduEmail : '');
  const [lawSchool, setLawSchool] = useState(account?.lawSchool ? account?.lawSchool : '');
  const yearOfGraduationList = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  const convertListToOptions = (array) => array.map(elem => ({ value: elem, label: elem }))
  const yearOfGraduationOptions = convertListToOptions(yearOfGraduationList);
  const [yearOfGraduation, setYearOfGraduation] = useState(account?.yearOfGraduation ? account?.yearOfGraduation : yearOfGraduationOptions[0]);
  const degreeProgramList = ['JD', 'LLM', 'PhD', 'BA', 'Other'];
  const degreeProgramOptions = convertListToOptions(degreeProgramList);
  const [degreeProgram, setDegreeProgram] = useState(account?.degreeProgram ? account?.degreeProgram : degreeProgramOptions[0]);


  // const [shortBio, setShortBio] = useState(account.shortBio ? account.shortBio : '');
  // const [title, setTitle] = useState(account.title ? account.title : '');
  // const [company, setCompany] = useState(account?.company ? account?.company : '');
  // const [accountType, setAccountType] = useState(account?.accountType ? account.accountType : null);
  // const shortBioMaxLength = 65;


  const validateForm = () => {
    let validated = false
    if (name.length <= 2 || !name.includes(' ')) {
      setErrorMessage('You must enter your full name.');
    } else if (!eduEmail || !validateEmail(eduEmail) || !eduEmail.endsWith('.edu')) {
      setErrorMessage('You must enter a valid .edu email address.');
    } else if (name.length <= 3) {
      setErrorMessage('You must enter a valid law school.');
    } else if (!yearOfGraduation) {
      setErrorMessage('You must select a year of graduation.');
    } else if (!degreeProgram) {
      setErrorMessage('You must select a degree program.');
    } else {
      validated = true;
    }
    console.log(errorMessage);
    return validated;
  }


  const handleClick = () => {
    if (!validateForm()) return;
    setButtonLoading(true);
    let params = {
      name,
      eduEmail,
      lawSchool,
      yearOfGraduation,
      degreeProgram,
    }
    updateAccount({ user, account: { ...params } }).then(() => {
      setAccount({ ...account, ...params });
      setButtonLoading(false);
      setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({name: 'Account updated', params})
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Account could not be updated.');
      setButtonLoading(false);
    })
  }

  useEffect(() => {
    if (!buttonLoading) return;
    setTimeout(() => {
      setButtonSucceeded(false);
      navigate(returnHomePath())
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonLoading])


  return (
    <div className="w-full">
      <div className="font-semibold text-3xl">Profile</div>
      <div className="divide-y my-2 border-t border-gray-300" />

      <div className="mt-2 space-y-4 max-w-xl">

        {/* Name */}
        <div>
          <label htmlFor="title" className="text-sm font-semibold">Name<RequiredAsterisk /></label>
          <input
            id="name"
            type="text"
            autoComplete="name"
            required
            className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            value={name}
            onChange={(e) => { setName(e.target.value) }}
            placeholder="John Smith"
          />
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="text-sm font-semibold">Email</label>
          <div className="text-xs text-gray-500">Used to sign in</div>
          <input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            required
            className="bg-gray-300 rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            value={email}
            disabled={true}
          />
        </div>

        {/* Law school */}
        <div>
          <label htmlFor="law-school" className="text-sm font-semibold">Law School<RequiredAsterisk /></label>
          <input
            id="law-school"
            type="text"
            autoComplete="name"
            required
            className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            value={lawSchool}
            onChange={(e) => { setLawSchool(e.target.value) }}
            placeholder="John Smith"
          />
        </div>

        {/* .edu email */}
        <div>
          <label htmlFor="edu-email" className="text-sm font-semibold">Law School Email<RequiredAsterisk /></label>
          <div className="text-xs text-gray-500">Must be .edu.</div>
          <input
            id="edu-email"
            type="text"
            autoComplete="email"
            required
            className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            value={eduEmail}
            onChange={(e) => { setEduEmail(e.target.value) }}
            placeholder="John Smith"
          />
        </div>

        {/* Year of graduation */}
        <div>
          <label htmlFor="year-of-graduation" className="text-sm font-semibold">Year of graduation<RequiredAsterisk /></label>
          <Select
            id="year-of-graduation"
            classNamePrefix="select"
            isClearable={false}
            // isRtl={isRtl}
            isSearchable={true}
            value={yearOfGraduationOptions.filter(elem => elem.value === yearOfGraduation)[0]}
            options={yearOfGraduationOptions}
            onChange={(option) => setYearOfGraduation(option.value)}
          />
        </div>

        {/* Degree program */}
        <div>
          <label htmlFor="degree-program" className="text-sm font-semibold">Degree program<RequiredAsterisk /></label>
          <Select
            id="degree-program"
            classNamePrefix="select"
            isClearable={false}
            // isRtl={isRtl}
            isSearchable={true}
            value={degreeProgramOptions.filter(elem => elem.value === degreeProgram)[0]}
            options={degreeProgramOptions}
            onChange={(option) => setDegreeProgram(option.value)}
          />
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={(e) => { handleClick(e.target.value) }}
            disabled={buttonLoading}
          >
            {buttonLoading ? spinnerIcon :
              (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                {returnChevronRight()}
              </span>)
            }
            {buttonLoading || buttonSucceeded ? '' : 'Save'}
            {buttonLoading || !buttonSucceeded ? '' : <span
            >{returnCheckCircleIcon('w-5 h-5')}</span>}
          </button>
        </div>

        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
    </div>
  )
}

export default ProfileTab;