import AdminSmallArticleItem from './AdminSmallArticleItem';

const AdminSmallArticlesList = ({
  selectedArticle, 
  setSelectedArticleId, 
  articles,
  publishedArticleId,
  topicId
}) => {

  return (
    <div className="bg-white rounded w-72 shadow">

      <div className="flex justify-between items-center px-4">
        <div className="font-semibold py-2">Articles</div>
        <div>
          <button
            className="border rounded hover:bg-blue-800 px-2 py-1 text-xs text-white bg-blue-600"
            onClick={() => {setSelectedArticleId(null)}}
          >+ New</button>
        </div>


      </div>
      <div className="divide-y border-t border-gray-300" />

      <div className="">{articles?.map((article, index, arr) => (
        <AdminSmallArticleItem 
          article={article} 
          key={index} 
          index={index} 
          arr={arr} 
          selectedArticle={selectedArticle}
          setSelectedArticleId={setSelectedArticleId}
          publishedArticleId={publishedArticleId}
          topicId={topicId}
        />))}
      </div>
    </div>
  )
}

export default AdminSmallArticlesList;