import { returnApiUrl } from '../config/config';
import { captureAndFormatException } from '../dependencies/sentry';
const apiUrl = returnApiUrl();

export const reportTopic = async ({ user, topicId, reportReasonLabel, reportReasonValue, reportMessage }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicReports`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, reportReasonLabel, reportReasonValue, reportMessage })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const getTopicReports = async ({ user, page, elemsPerPage }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicReports?` + new URLSearchParams({
      page,
      elemsPerPage
    }), {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      console.error(error.message);
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const saveTopicReportResolution = async ({ user, topicReportId, resolved }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicReportResolution`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicReportId, resolved })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}