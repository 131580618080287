import { useRef } from 'react'


const AdminFilterMenu = ({
  // open,
  setOpen,
  handleSelectOption,
  filterOptions,
  // anchorRef,
  color = 'blue',
}) => {

  const menuRef = useRef(null);

  const handleClick = ({option}) => {
    handleSelectOption(option)
    setOpen(false)
  }


  return (
    <div ref={menuRef} className={`bg-${color}-200 border border-gray-500 text-gray-700 text-xs absolute right-0 top-8 text rounded flex flex-col shadow-lg z-10`}>
      
      
      {filterOptions.map((option, index) => {
        return (
          <button 
            key={index} 
            className={`hover:bg-${color}-300 p-2 w-36 text-center`} 
            onClick={() => {handleClick({option})}}
            // onClick={() => { console.log('1')}}
            // onClick={() => { console.log('1') }}

          >
              {option.label}
          </button>
        )
      })}
    </div>
  )
}

export default AdminFilterMenu;