import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useStore } from '../store/store';
import { spinnerIcon, exclamationIcon, returnPencilIcon } from "../helpers/icons";
import { /* getStorage, */ ref, uploadBytes } from 'firebase/storage';
import { getProfileImageCroppedUrl } from '../actions/profileImages';
import { storage } from '../firebase/firebase';
import { returnProfileImageUrl } from '../helpers/urls';
import {updateAccount} from '../actions/accounts';
import { trackEvent } from '../dependencies/mixpanel';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  /* Crop,
  PixelCrop, */
} from 'react-image-crop'
import { CanvasPreview } from './CanvasPreview'
import { useDebounceEffect } from '../helpers/useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'



const ProfileImageModal = ({
  open,
  setOpen,
}) => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const user = useStore(state => state.user);
  const uid = user?.uid;
  const [errorMessage, setErrorMessage] = useState('');
  const account = useStore(state => state.account);
  const setAccount = useStore(state => state.setAccount);

  // const setProfileImageCroppedUrl = useStore(stage => stage.setProfileImageCroppedUrl);
  // const navigate = useNavigate();
  // let profileImageCroppedUrl = account?.profileImageCroppedUrl;

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState();
  const scale = 1;
  const rotate = 0;
  const aspect = 1;
  // const [scale, setScale] = useState(1)
  // const [rotate, setRotate] = useState(0)
  // const [aspect, setAspect] = useState(1 / 1)




  // This is to demonstate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  // Generate cropped image as a blob
  const getCroppedImg = async ({ canvas, crop }) => {
    if (!crop || !canvas) return;
    return await new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = "newFile.jpeg";
        resolve(blob);
      }, "image/jpeg", 1);
    });
  }


  const handleSave = async () => {

    // Generate cropped image
    const croppedImage = await getCroppedImg({
      crop: completedCrop,
      canvas: previewCanvasRef.current,
    });
    // const croppedImgUrl = URL.createObjectURL(croppedImage);
    // setProfileImageCroppedUrl(croppedImgUrl);


    // Save cropped image to storage bucket
    // const storage = getStorage();
    const croppedStorageRef = ref(storage, returnProfileImageUrl({ uid }));
    setButtonLoading(true);
    await uploadBytes(croppedStorageRef, croppedImage)
      .then(async (snapshot) => {
        
        const profileImageCroppedUrl = await getProfileImageCroppedUrl({ uid });
        let tabsCompleted = account?.tabsCompleted || [];

        let newAccount = {
          ...account, 
          profileImageCroppedUrl,
          tabsCompleted: [
            ...tabsCompleted, 'profile-image'
        ]}
        await updateAccount({user, account: newAccount})
        setAccount({...newAccount, profileImageCroppedUrl});
        // setProfileImageCroppedUrl(profileImageCroppedUrl);

        setErrorMessage('');
        setButtonLoading(false);
        console.log('Uploaded cropped image', JSON.stringify(snapshot?.metadata?.fullPath));
        trackEvent({name: 'Profile image uploaded'});
        setOpen(false);        
      }).catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        setErrorMessage(errorMessage);
        setButtonLoading(false);
      })


    // Save original image to storage bucket
    // if (!fullImage) return;
    // const fullStorageRef = ref(storage, `orgs/${orgId}/users/${uid}/profileImages/full/${uid}.jpeg`);
    // await uploadBytes(fullStorageRef, fullImage)
    //   .then((snapshot) => {
    //     // setOpen(false);
    //     // setErrorMessage('');
    //     console.log('Uploaded full image');
    //     // setButtonLoading(false);
    //   }).catch(error => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     console.log(errorCode, errorMessage)
    //     setErrorMessage(errorMessage);
    //     // setButtonLoading(false);
    //   })


    // navigate(returnHomePath());

  }









  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob)
      hiddenAnchorRef.current.href = blobUrlRef.current
      hiddenAnchorRef.current.click()
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        CanvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined)
  //   } else if (imgRef.current) {
  //     const { width, height } = imgRef.current
  //     setAspect(16 / 9)
  //     setCrop(centerAspectCrop(width, height, 16 / 9))
  //   }
  // }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus="" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen-adj" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" /> */}
                    {returnPencilIcon()}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {/* {!imageEl.current ? '' : `Edit your profile image`} */}
                    </Dialog.Title>

                    <div className="flex justify-center items-center">
                      {/* <div className="Crop-Controls flex justify-center"> */}

                      <input
                        type="file"
                        accept="image/*"
                        onChange={onSelectFile}
                        className="py-4"
                      />

                      {/* <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div> */}
                      {/* <div>
          <button onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? 'off' : 'on'}
          </button>
        </div> */}
                    </div>
                    {!!imgSrc && (
                      <div className="flex justify-center">
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={aspect}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                      </div>
                    )}
                    {!!completedCrop && (
                      <>
                        <div>
                          <canvas
                            ref={previewCanvasRef}
                            className="hidden"
                            style={{
                              border: '1px solid black',
                              objectFit: 'contain',
                              width: completedCrop.width,
                              height: completedCrop.height,
                            }}
                          />
                        </div>
                        <div className="hidden">
                          <button onClick={onDownloadCropClick}>Download Crop</button>
                          <a
                            href="/" // Required to make this keyboard accessible.
                            ref={hiddenAnchorRef}
                            download
                            style={{
                              position: 'absolute',
                              top: '-200vh',
                              visibility: 'hidden',
                            }}
                          >
                            Hidden download
                          </a>
                        </div>
                      </>
                    )}


                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {!!imgSrc && (<div>
                  <button
                    type="submit"
                    className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => { handleSave() }}
                    disabled={buttonLoading}
                  >
                    <span className={buttonLoading ? "invisible" : ''}>Save</span>
                    {buttonLoading ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                  </button>
                </div>)}
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                // ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
              <div>
                {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ProfileImageModal;