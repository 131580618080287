

const VerticalTabListItem = ({
  listItem,
  index,
  arr,
  selectedListItemId,
  setSelectedListItemId,
}) => {
  return (
    <div>
      <button
        className={`
        text-left px-4 py-2 w-full
        ${selectedListItemId !== listItem?.value && 'hover:bg-gray-50'}
        ${selectedListItemId === listItem?.value && 'bg-blue-50 hover:bg-blue-100'}
        ${index === 0 && 'rounded-t'} 
        ${index === arr.length - 1 && 'rounded-b'} 
      `}
        onClick={() => {
          // if (selectedListItemId === listItem?.value) {
          //   setSelectedListItemId(null);
          // } else {
            setSelectedListItemId(listItem?.value);
          // }
        }}
      >
        <div className="text-gray-700 text-sm">{listItem.label}</div>
      </button>
      {index !== arr.length - 1 && <div className={`divide-y border-t border-gray-300`} />} { /* my-2 */}
    </div>
  );
};


export default VerticalTabListItem;