import {useState} from 'react';
import AdminDeleteTopicModal from './AdminDeleteTopicModal';
import AdminUpdateTopicModal from './AdminUpdateTopicModal';
import { returnViewTopicWithPublishedArticlePath, returnAdminSearchForTopicPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';



const AdminTopicHeader = ({
  topicId,
  title,
  setTitle,
  code,
  setCode,
}) => {

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div>

      <div className="flex justify-between items-end">
        <div>
          <div className="font-semibold text-3xl">{title}</div>
          <div className="text-xs text-gray-500">{code}</div>
        </div>

        <div className="flex space-x-2">

          <button
            className=" rounded border border-gray-500  hover:bg-gray-200 px-2 py-1 text-sm"
            onClick={() => { window.open(returnViewTopicWithPublishedArticlePath({ topicId }), '_blank')}}
          >
            View as user
          </button>

          <button
            className=" rounded border border-gray-500  hover:bg-gray-200 px-2 py-1 text-sm"
            onClick={() => { setUpdateModalOpen(true) }}
          >
            Update
          </button>
          <AdminUpdateTopicModal
            open={updateModalOpen}
            setOpen={setUpdateModalOpen}
            topicId={topicId}
            title={title}
            setTitle={setTitle}
            code={code}
            setCode={setCode}
          />


          <button
            className=" rounded border border-red-200 bg-red-200 hover:bg-red-300 px-2 py-1 text-sm"
            onClick={() => { setDeleteModalOpen(true) }}
          >
            Delete
          </button>
          <AdminDeleteTopicModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            topicId={topicId}
            callback={() => {navigate(returnAdminSearchForTopicPath())}}
          />
        </div>

      </div>

      <div className="divide-y my-2 border-t border-gray-300" />
    </div>
  );
};

export default AdminTopicHeader;