import { returnApiUrl } from '../config/config';
import { captureAndFormatException } from '../dependencies/sentry';
const apiUrl = returnApiUrl();


export const searchForTopics = async ({ user, query, maxResults }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    // fetch(`${apiUrl}/my`, {
    fetch(`${apiUrl}/searchForTopics?` + new URLSearchParams({
      query,
      maxResults
    }), {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const createTopic = async ({ user, topic, withArticle }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topics`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topic, withArticle })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const saveTopicPublication = async ({ user, topicId, articleId, publish }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicPublication`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, articleId, publish })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const deleteTopicAndArticles = async ({ user, topicId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topics/`, {
      method: 'DELETE',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const updateTopic = async ({ user, topicId, title, code }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topics`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, title, code })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const getTopicWithPublishedArticle = async ({ user, topicId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topics/${topicId}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve({...data, status: res.status})
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(err => reject({err, status: res.status}));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      console.error(error.message);
      reject(error);
    });
  });
}

export const requestTopic = async ({ user, topicRequest }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicRequests`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicRequest })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const saveTopicUpvote = async ({ user, topicId, upvoted }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topic-upvote`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, upvoted })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const getTopicsWithoutArticles = async ({ user, page, elemsPerPage, filterBy, lastTopicId, direction, sort }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    // fetch(`${apiUrl}/my`, {
    fetch(`${apiUrl}/topics?` + new URLSearchParams({
      page,
      elemsPerPage,
      filterBy: JSON.stringify(filterBy),
      lastTopicId,
      direction,
      sort: JSON.stringify(sort),
    }), {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const saveTopicApproval = async ({ user, topicId, approved }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicApproval`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, approved })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const createTopicAndArticle = async ({ user, topic }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicAndArticle`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topic })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const saveTopicShowcase = async ({ user, topicId, showcased }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicShowcase`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, showcased })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const getTopicsInShowcaseWithoutArticles = async ({ user }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/topicsInShowcaseWithoutArticles`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const retrieveTitleOrCodeFromTopic = async ({user, topicId}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/retrieveTitleOrCodeFromTopic`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}