import { spinnerIcon, lockIcon, exclamationIcon } from "../helpers/icons";
import {useState} from 'react'; 
import { auth } from '../firebase/firebase';
import { useStore } from '../store/store';
import { returnHomePath, returnSignInPath, privacyPolicyUrl, termsOfUseUrl} from '../helpers/urls';
import { validateEmail } from '../helpers/validation';
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { trackEvent } from '../dependencies/mixpanel';
import { convertFirebaseErrorToHumanReadable } from '../helpers/errorHandling';



const SignUp = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const setUser = useStore(state => state.setUser);
  const homePath = returnHomePath();
  const signinPath = returnSignInPath();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);

  const validateSignupForm = () => {
    let validated = false
    if (!email) {
      setErrorMessage('Please enter your email.');
    } else if (!password) {
      setErrorMessage('Please enter your a password.');
    } else if (password.length < 8) {
      setErrorMessage('Your password must be at least 8 characters');
    } else if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else if (!terms) {
      setErrorMessage('In order to sign up please check the box to agree to the Terms of Use and Privacy Policy.');
    } else {
      validated = true;
    }
    return validated;
  }
  
  const handleClick = () => {

    if (!validateSignupForm()) return;

    setButtonLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        setUser(user);
        console.log('About to create user: ', user);
        setButtonLoading(false);
        navigate(homePath);
        trackEvent({name: 'User signed up with email and password'})
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;
        console.log('Error creating user', errorCode, errorMessage)
        errorMessage = convertFirebaseErrorToHumanReadable(error);
        setErrorMessage(errorMessage);
        setButtonLoading(false);
      });
  }


  return (
    <div className="flex flex-col min-h-screen-adj bg-gray-100 py-24 px-4 sm:px-6 lg:px-8 items-center">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className ="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create your account
          </h2>
          <p className ="mt-2 text-center text-sm text-gray-600">
            {`Or  `}  
          <span onClick={() => { navigate(signinPath) }} className ="cursor-pointer font-medium text-blue-600 hover:text-blue-500">
            sign in
          </span> to an existing account.
          </p>
        </div>
        <div className="mt-8 space-y-4" action="#" method="POST">

          {/* Email */}
          <div>
            <label htmlFor="email" className="text-sm font-semibold">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="bruce@wayneindustries.com"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
            />
          </div>

          {/* Password */}
          <div>
            <label htmlFor="password" className="text-sm font-semibold">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="••••••••"
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </div>

          {/* Terms */}
          <div 
            className="flex items-center"
          >
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              value={terms}
              onChange={() => {setTerms(state => !state)}}
            />
            <label 
              htmlFor="password" 
              className="text-sm font-semibold ml-2 block"
            >
              I agree to the <a href={termsOfUseUrl} rel="noreferrer" target="_blank" className="text-blue-600">Terms of Use</a> and <a href={privacyPolicyUrl} rel="noreferrer" target="_blank" className="text-blue-600">Privacy Policy</a>.
            </label>
          </div>


          <div>
          <button 
            type ="submit" 
            className ="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={(e) => {handleClick(e.target.value)}}
            disabled={buttonLoading}
          >
            {buttonLoading ? spinnerIcon : 
              (<span className ="absolute left-0 inset-y-0 flex items-center pl-3">
                {lockIcon}
              </span>)
            }
            {buttonLoading ? '' : 'Create your account'}
          </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </div>
      </div>
    </div>
  )
}

export default SignUp;