
import { useState } from 'react';
import { useStore } from '../store/store';
import { exclamationIcon } from "../helpers/icons";
import Switch from './Switch';
import { saveTopicApproval } from '../actions/topics';
import { trackEvent } from '../dependencies/mixpanel';



export const AdminApprovedSwitch = ({
  topicId,
  approved,
  setApproved,
}) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);

  // When an article is published, update state
  const handleApproveTopic = async () => {
    saveTopicApproval({
      user,
      topicId,
      approved: !approved
    }).then(({ topicId, approved }) => {
      setApproved(approved);
      setErrorMessage('');
      trackEvent({name: 'Admin topic approved', params: {topicId, approved}})
    }).catch((error) => {
      console.log(error)
      console.error('Error changing topic approved status', error)
      setErrorMessage('Topic approved status could not be changed.');
    })
  }

  return (
    <div className="mt-1 flex space-x-2 items-center">
        <Switch
          on={!!approved}
          setOn={() => { handleApproveTopic() }}
        />
        <div className={`text-xs ${approved ? 'text-blue-500' : 'text-gray-500'}`}>{approved ? 'Approved' : 'Not approved'}</div>
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
  );
};

export default AdminApprovedSwitch;