import VerticalTabList from './VerticalTabList';
import {useState} from 'react';
import ProfileTab from './ProfileTab';
import ProfilePhotoTab from './ProfilePhotoTab';
import BillingTab from './BillingTab';

const AccountPage = () => {


  const tabOptions = [{
    value: 'profile',
    label: 'Profile'
  }, {
    value: 'profile-picture',
    label: 'Profile picture'
    }, {
    value: 'billing',
    label: 'Billing'
  }]
  const [selectedTabId, setSelectedTabId] = useState(tabOptions[0]?.value);

  return (
    <div className="flex min-h-screen-adj bg-gray-100 p-4 sm:p-6 lg:p-8 space-x-8">

      <div>
        <VerticalTabList
          selectedListItemId={selectedTabId}
          setSelectedListItemId={setSelectedTabId}
          tabOptions={tabOptions}
          verticalTabListHeading="Account"
        />
      </div>

      {selectedTabId === 'profile' && (
        <ProfileTab />
      )}

      {selectedTabId === 'profile-picture' && (
        <ProfilePhotoTab />
      )}

      {selectedTabId === 'billing' && (
        <BillingTab />
      )}

      



      
    </div>
  );
};

export default AccountPage;