// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from 'firebase/firestore/lite';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { returnFirebaseConfig, returnFirebaseToggleEmulator } from "../config/config";

// import { connectFirestoreEmulator } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const prodFirebaseConfig = {
//   apiKey: "AIzaSyBXXFMsVfCJjz_dOBtnzzfXcVzJsB9whKU",
//   authDomain: "skill-feather-2.firebaseapp.com",
//   projectId: "skill-feather-2",
//   storageBucket: "skill-feather-2.appspot.com",
//   messagingSenderId: "686916156895",
//   appId: "1:686916156895:web:1be21fc1afaf207d43d86a",
//   measurementId: "G-7CNXS8EDJZ"
// };

// const devxFirebaseConfig = {
//   apiKey: "AIzaSyDLSt1x3d4LJU6F0FO88H9KWPpMuNsbOig",
//   authDomain: "studybook-6912e.firebaseapp.com",
//   projectId: "studybook-6912e",
//   storageBucket: "studybook-6912e.appspot.com",
//   messagingSenderId: "492741382353",
//   appId: "1:492741382353:web:deea1217eb0a2072b1b5f0",
//   measurementId: "G-X7M89QXJYE"
// }

const firebaseConfig = returnFirebaseConfig()
export const toggleEmulator = returnFirebaseToggleEmulator()
console.log({toggleEmulator})

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
if (toggleEmulator) {
  connectFirestoreEmulator(db, 'http://localhost:8080')
};


/* Firestore emulator */
const auth = getAuth(app);
if (toggleEmulator) {
  connectAuthEmulator(auth, "http://localhost:9099");
}

/* Firebase cloud storage */
const storage = getStorage(app);
if (toggleEmulator) {
  connectStorageEmulator(storage, "localhost", 9199);
}


export { analytics, auth, storage, db as default };