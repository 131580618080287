// import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useStore } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LoadingPage from '../components/LoadingPage';
import { returnSignInPath } from '../helpers/urls';
import FallbackPage from './FallbackPage';
import { Suspense } from 'react';
import { motion } from 'framer-motion';


const NavRoute = ({ authRequired, children, hideSearchBar, availableToRoles}) => {

  let navigate = useNavigate();
  const user = useStore(state => state.user);
  const roles = useStore(state => state.roles);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    if (authRequired && !user?.uid) {
      navigate(returnSignInPath());
      window.location.reload()
    }
    setAuthLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])


  if (authLoading) {
    return <LoadingPage />
  } else if (availableToRoles && availableToRoles.length > 0 && availableToRoles.filter(permittedRole => roles?.includes(permittedRole)).length === 0) {
    return (
      <Suspense fallback={< LoadingPage />}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Navbar hideSearchBar={hideSearchBar} />
          <div className="min-h-screen-adj">
            <FallbackPage subMessage="You must be an administrator to access this page." loginButton={!user?.uid}/>
          </div>
        </motion.div>
      </Suspense>

    )
  } else {
    return (
      <Suspense fallback={< LoadingPage />}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Navbar hideSearchBar={hideSearchBar} />
          <div className="min-h-screen-adj">
            {children}
          </div>
        </motion.div>
      </Suspense>
    )
  }

}
export default NavRoute;
