import { returnApiUrl } from '../config/config';
import { captureAndFormatException } from '../dependencies/sentry';
const apiUrl = returnApiUrl();


export const beginStripeCheckout = async ({user}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/stripe/create-checkout-session`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const beginStripeBilling = async ({ user }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/stripe/create-billing-session`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}