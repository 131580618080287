import VerticalTabListItem from './VerticalTabListItem';

const VerticalTabList = ({
  selectedListItemId,
  setSelectedListItemId,
  tabOptions,
  verticalTabListHeading,
}) => {

  return (
    <div className="bg-white rounded w-72 shadow">

      <div className="flex justify-between items-center px-4">
        <div className="font-semibold py-2">{verticalTabListHeading}</div>
      </div>
      <div className="divide-y border-t border-gray-300" />

      <div className="">{tabOptions?.map((listItem, index, arr) => (
        <VerticalTabListItem
          listItem={listItem}
          index={index}
          arr={arr}
          selectedListItemId={selectedListItemId}
          setSelectedListItemId={setSelectedListItemId}
        />))}
      </div>
    </div>
  )
}

export default VerticalTabList;