import {useState, useEffect} from 'react';
import { returnCheckCircleIcon, spinnerIcon, exclamationIcon } from '../helpers/icons';
import { createArticleAndPublishWithStream } from '../actions/articles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStore } from '../store/store';
import { getTopicWithPublishedArticle } from '../actions/topics';
import { trackEvent } from '../dependencies/mixpanel';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';


const AdminCreateArticleWithAutoPrompt = ({
  topicId,
  published,
  handleUpdateTopicsArray,
  pushArticlesToArticlesArray,
  setPublished,
  setPublishedArticleId,
  setSelectedArticleId,
}) => {

  const [createAndPublishArticleLoading, setCreateAndPublishArticleLoading] = useState(false);
  const [createAndPublishArticleSucceeded, setCreateAndPublishArticleSucceeded] = useState(false);
  const [createAndPublishArticleErrorMessage, setCreateAndPublishArticleErrorMessage] = useState('');
  const [content, setContent] = useState('');
  const user = useStore(state => state.user);


  const launchToast = (message) => {
    toast(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const appendToContent = (str) => {
    setContent(state => state + str);
  }

  const handleCreateAndPublishArticle = () => {
    setCreateAndPublishArticleLoading(true)
    createArticleAndPublishWithStream({
      user,
      topicId
    }, {
      appendToContent,
    }).then(async () => {
      console.log('Ended stream');
      return getTopicWithPublishedArticle({ user, topicId })
    }).then((topic) => {
      console.log('Created topic.  The topic is: ', topic);
      setCreateAndPublishArticleLoading(false);
      setCreateAndPublishArticleSucceeded(true);
      setCreateAndPublishArticleErrorMessage('')

      // If on ReviewTopicPage
      pushArticlesToArticlesArray({topicId, articleId: topic?.publishedArticleId, content: topic?.content});
      setPublishedArticleId(topic?.publishedArticleId);
      setPublished(topic?.published);
      setSelectedArticleId(topic?.publishedArticleId);
      launchToast(`Article created and published: ${topicId}`);
      
      let params = { topicId, published: topic?.published, publishedArticleId: topic?.publishedArticleId, datePublished: topic?.datePublished, publishedBy: topic?.publishedBy }
      trackEvent({ name: 'Admin article created and published', params })

    }).catch((error) => {
      console.log('Error creating article', error)
      setCreateAndPublishArticleLoading(false);
      setCreateAndPublishArticleErrorMessage('Article could not be created.')
    })
  }

  useEffect(() => {
    if (!createAndPublishArticleSucceeded) return;
    setTimeout(() => {
      setCreateAndPublishArticleSucceeded(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAndPublishArticleSucceeded]);



  return (

    <div className="min-h-screen-adj bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">

      <div className="flex flex-col justify-center mr-4 mb-8">
        {published ? '' : <button
          className={`${published ? 'text-gray-700 border border-gray-500  hover:bg-gray-200' : 'text-white bg-blue-500 hover:bg-blue-600'} px-2 py-2 rounded relative flex items-center justify-center`}
          onClick={() => { handleCreateAndPublishArticle() }}
          disabled={createAndPublishArticleLoading}
        >
          <div className={`absolute text-center flex space-x-2 items-center ${createAndPublishArticleLoading ? '' : 'invisible'}`}>{spinnerIcon} <div>Creating article...</div></div>
          <div className={`absolute text-center flex space-x-2 items-center ${createAndPublishArticleSucceeded ? '' : 'invisible'}`}>{returnCheckCircleIcon('h-4 w-4')} <div>Article created</div></div>
          <div className={`${!createAndPublishArticleSucceeded && !createAndPublishArticleLoading ? '' : 'invisible'}`}>Create and publish article</div>
        </button>}
        <ToastContainer />
        {!createAndPublishArticleErrorMessage ? '' : <label className="mt-2 text-blue-500 flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {createAndPublishArticleErrorMessage}</label>}
      </div>

      <div className="prose w-full">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>

    </div>
  )

}

export default AdminCreateArticleWithAutoPrompt;