// import { toggleEmulator } from "../firebase/firebase";
// import queryString from 'query-string';

// export const apiUrl = process.env.REACT_APP_CLOUD_ENV === 'PROD' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEVX;
// export const apiUrlEmulator = `http://localhost:5001/studybook-6912e/us-central1/api`;

// export const returnApiUrl = () => {
//   return toggleEmulator ? apiUrlEmulator : apiUrl;
// }

import { returnLandingPageUrl as returnLPU } from "../config/config";

// Basic urls

export const returnLandingPageUrl = () => {
  return returnLPU()
}

export const termsOfUseUrl = `${returnLandingPageUrl()}/terms`

export const privacyPolicyUrl = `${returnLandingPageUrl() }/privacy`;

export const contactUrl = `${returnLandingPageUrl()}/contact`

// App specific urls

export const returnEmployersRejectedPath = () => {
  return '/employers/rejected/';
}

export const returnEmployersSavedPath = () => {
  return '/employers/saved/';
}

export const returnEmployersUnreviewedPath = () => {
  return `/employers/unreviewed`;
}

export const returnEmployersBrowsePath = ({candidateId} = {candidateId: ''}) => {
  return `/employers/browse/${candidateId}`;
}

export const returnViewCandidatePath = ({ candidateId } = { candidateId: '' }) => {
  return `/employers/view-candidate/${candidateId}`;
}

export const returnCandidatesPath = () => {
  return '/candidates';
}

export const returnModeFromEmployersPath = ({pathname}) => {
  return pathname.split('/')[2];
}

export const mapLikesToReviewMode = (like) => {
  return like === 'like' ? 'saved' : 'rejected';
}

export const mapReviewModeToLikes = (reviewMode) => {
  if (reviewMode === 'saved') {
    return 'like';
  } else if (reviewMode === 'rejected') {
    return 'dislike';
  } else if (reviewMode === 'unreviewed') {
    return 'unreviewed';
  } else {
    return null;
  }
}

export const returnEmailTemplateEditorPath = () => {
  return '/employers/email-template-editor';
}

export const returnSignUpPath = () => {
  return '/signup';
}

export const returnSignInPath = () => {
  return '/signin';
}

export const returnHomePath = () => {
  return '/';
}

export const returnAccountPath = () => {
  return '/account';
} 

export const returnAccountTypePath = () => {
  return '/accountType';
} 

export const returnCandidateProfilePath = ({candidateId}) => {
  return `/candidates/${candidateId}`;
}

export const returnUploadAssessmentPage = () => {
  return `/upload-assessments`;
}

export const returnProfileImageUrl = ({uid}) => {
  return `profileImages/users/${uid}/cropped/${uid}.jpeg`
}

export const returnPathAfterSignIn = () => {
  returnAccountPath();
}

export const returnBrowseCandidatesPath = () => {
  return `/browse-candidates`;
}

// export const convertObjToQueryString = (params) => {
//   const query = queryString.stringify(params);
//   return query;
// }

export const returnProfilePath = () => {
  return `/edit-profile`;
}

export const returnEmployerMessagesPath = () => {
  return `/employer-messages`;
}

export const returnCompanyPath = () => {
  return `/company`;
}

export const returnFindJobsPath = () => {
  return `/find-jobs`;
}

export const returnEmployersJobsPath = () => {
  return `/employers-jobs`;
}

export const returnSeedDbPath = () => {
  return `/seed`;
}

export const returnLandingPagePath = () => {
  return `/landing`;
}

export const returnLogoPath = () => {
  return '/logo-white.png';
}

export const returnBlackLogoPath = () => {
  return '/logo-black.png';
}

export const returnBrowseArticlesPath = () => {
  return '/browse-articles';
}

export const returnUpdateArticlePath = () => {
  return '/update-article';
}

export const returnViewArticlePath = ({articleId}) => {
  return `/view-article/${articleId}`;
}

export const returnLoadingWithQuotePath = () => {
  return `/loading-with-quote`;
}

export const returnAdminSearchForTopicPath = () => {
  return '/admin/search-for-topic';
}

export const returnAdminCreateTopicPath = () => {
  return '/admin/create-topic';
}

export const returnAdminReviewTopicPath = ({topicId}) => {
  return `/admin/review-topic/${topicId}`;
}

export const returnAdminConsolePath = () => {
  return `/admin/console`;
}

export const returnSearchForTopicPath = () => {
  return `/search`;
}

export const returnViewTopicWithPublishedArticlePath = ({topicId}) => {
  return `/view-topic/${topicId}`;
}

export const returnAdminViewTopicReportsPath = () => {
  return '/admin/view-topic-report';
}

export const returnAdminTypesensePath = () => {
  return '/admin/typesense';
}

export const returnAdminViewUnapprovedTopicsPath = () => {
  return '/admin/view-unapproved-topics';
}

export const returnCreateTopicAndArticlePath = () => {
  return '/create-topic-and-article';
}

export const returnRequestTopicPath = () => {
  return '/request-topic';
}

export const returnEnterAccountInformationPath = () => {
  return '/get-started';
}

export const returnTrialExpiredPath = () => {
  return '/trial-expired'
}

export const returnFallbackPath = () => {
  return '/fallback'
}

export const returnViewStreamingTopicWithPublishedArticlePath = ({topicId}) => {
  return returnViewTopicWithPublishedArticlePath({topicId});
}