function Switch({ onColor = 'bg-blue-500', on = true, setOn = null}) {

  const onClass = `transform translate-x-3`;

  return (
    <>
        <div
          className={`w-9 h-5 ${on ? onColor : 'bg-gray-400'} flex items-center rounded-full p-1 cursor-pointer`}
          onClick={() => {
            if (setOn) {setOn(!on)}
          }}
        >
          {/* Switch */}
          <div
            className={
              "bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out" +
              (on ? onClass : null)
            }
          ></div>
        </div>
    </>
  );
}

export default Switch;
