const HorrizontalTabs = ({tabs, selectedTabValue, setSelectedTabValue}) => {

  return (
    <div className="flex justify-between space-x-4">
        {tabs?.map((tab, index) => (
          <button
            onClick={() => { setSelectedTabValue(tab?.value) }}
            className={`
                ${tab?.value === selectedTabValue ? 'border-gray-500' : 'border-gray-100'} 
                border-b text-sm pt-1 text-gray-700 cursor-pointer hover:bg-gray-200`
              }
            key={index}
          >
            {tab?.label}
          </button>
        ))}
    </div>
  );
};

export default HorrizontalTabs;