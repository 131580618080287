import { auth } from '../firebase/firebase';
import { signOut } from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import { useStore } from '../store/store';
import { useRef, useEffect } from 'react'
import {returnSignInPath, returnAccountPath, contactUrl, returnLandingPageUrl} from '../helpers/urls';
import {trackEvent} from '../dependencies/mixpanel'
import { captureAndFormatException } from '../dependencies/sentry';


const AccountMenu = ({
  setOpen
}) => {

  const user = useStore(state => state.user);
  const setUser = useStore(state => state.setUser);
  const menuRef = useRef(null);

  const signinPath = returnSignInPath();
  const accountPath = returnAccountPath();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef])


  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate(signinPath);
      setOpen(false);
      setUser(null);
      trackEvent({name: 'User logged out'})
    }).catch((error) => {
      captureAndFormatException(error);
      console.log(error);
    })
  }


  return (
    <div ref={menuRef} className="absolute right-0 top-8 text border bg-white text-black rounded flex flex-col shadow-lg z-10">
      {user ? 
        <button className="hover:bg-gray-50 p-2 w-36 text-center" onClick={() => { handleLogout() }}>Log out</button>
      : 
        <button className="hover:bg-gray-50 p-2 w-36 text-center"  onClick={() => {navigate(signinPath)}}>Sign in</button>
      }
      {!user ? '' : <button className="hover:bg-gray-50 p-2 w-36 text-center"  onClick={() => {navigate(accountPath)}}>Account</button>}
      {/* {!user ? '' : <button className="hover:bg-gray-50 p-2 w-32 text-center" onClick={() => { navigate(orgPath) }}>Manage users</button>} */}
      <button className="hover:bg-gray-50 p-2 w-36 text-center" onClick={() => { window.open(contactUrl, '_blank') }}>Contact</button>
      <button className="hover:bg-gray-50 p-2 w-36 text-center" onClick={() => { window.open(returnLandingPageUrl(), '_blank') }}>Homepage</button>

    </div>
  )
}

export default AccountMenu;