// import CreateTopicAndArticlePage from '../components/CreateTopicAndArticlePage';
// import ReviewedCandidates from '../components/ReviewedCandidates';
// import ViewArticlePage from '../components/ViewTopicWithPublishedArticlePage';
// import BrowseArticlesPage from '../components/BrowseArticlesPage';
// import UpdateArticlePage from '../components/UpdateArticlePage';
// import LoadingWithQuotePage from '../components/LoadingWithQuotePage';
// import ViewTopicWithPublishedArticlePage from '../components/ViewTopicWithPublishedArticlePage';

import { Route, Routes, Navigate } from 'react-router-dom';
import NavRoute from '../components/NavRoute';
import {
  // returnBrowseArticlesPath,
  // returnCreateTopicAndArticlePath,
  // returnViewArticlePath,
  // returnUpdateArticlePath,
  // returnLoadingWithQuotePath,
  // returnViewTopicWithPublishedArticlePath,
  // returnHomePath,
  returnSignInPath,
  returnSignUpPath,
  returnAccountPath,
  returnAdminSearchForTopicPath,
  returnAdminCreateTopicPath,
  returnAdminReviewTopicPath,
  returnAdminConsolePath,
  returnAdminViewTopicReportsPath,
  returnSearchForTopicPath,
  returnAdminTypesensePath,
  returnAdminViewUnapprovedTopicsPath,
  returnRequestTopicPath,
  returnEnterAccountInformationPath,
  returnTrialExpiredPath,
  returnFallbackPath,
  returnViewStreamingTopicWithPublishedArticlePath,
} from '../helpers/urls';
import { didUserCompleteExtraSignUpInfo, isUserSubscribed } from '../helpers/roles';
import SignUp from '../components/SignUp';
import SignIn from '../components/SignIn';
import { useStore } from '../store/store';
import AccountPage from '../components/AccountPage';
import AdminSearchForTopicPage from '../components/AdminSearchForTopicPage';
import AdminCreateTopicPage from '../components/AdminCreateTopicPage';
import AdminReviewTopicPage from '../components/AdminReviewTopicPage';
import AdminConsolePage from '../components/AdminConsolePage';
import SearchForTopicPage from '../components/SearchForTopicPage';
import AdminViewTopicReportsPage from '../components/AdminViewTopicReportsPage';
import AdminTypesensePage from '../components/AdminTypesensePage';
import AdminViewUnapprovedTopicsPage from '../components/AdminViewUnapprovedTopicsPage';
import RequestTopicPage from '../components/RequestTopicPage';
import EnterAccountInformationPage from '../components/EnterAccountInformationPage';
import TrialExpiredPage from '../components/TrialExpiredPage';
import FallbackPage from '../components/FallbackPage';
import ViewStreamingTopicWithPublishedArticlePage from '../components/ViewStreamingTopicWithPublishedArticlePage';



const AppRouter = () => {

  const user = useStore(state => state.user);
  const account = useStore(state => state.account);
  const roles = useStore(state => state.roles);
  const userHasOnboarded = didUserCompleteExtraSignUpInfo({ account });
  const userHasSubscribed = isUserSubscribed({ account });
  const userNeedsOnboarding = user?.uid && !userHasOnboarded;
  console.log({ uid: user?.uid, account, roles, userHasOnboarded, userHasSubscribed, goToCondition: user?.uid && !userHasOnboarded, path: returnEnterAccountInformationPath() })

  return (
    <Routes>

      {/* Public routes */}
      <Route path={returnSignUpPath()} element={<NavRoute authRequired={true}><SignUp /></NavRoute>} />
      <Route path={returnSignInPath()} element={<NavRoute authRequired={false}><SignIn /></NavRoute>} />
      <Route path={returnFallbackPath()} element={<NavRoute authRequired={false}><FallbackPage /></NavRoute>} />

      {/* Paywalled routes */}
      {/* Route is visible.  Paywalling occurs on back-end. Inside the component is conditional logic for rendering the paywall. */}
      <Route path={returnViewStreamingTopicWithPublishedArticlePath({ topicId: ':topicId' })} element={<NavRoute ><ViewStreamingTopicWithPublishedArticlePage /></NavRoute>} />
      {/* <Route path={returnViewTopicWithPublishedArticlePath({ topicId: ':topicId' })} element={<NavRoute ><ViewTopicWithPublishedArticlePage /></NavRoute>} /> */}

      {/* Conditional private routes */}
      <Route path={returnEnterAccountInformationPath()} element={<NavRoute authRequired={true} hideSearchBar={true}><EnterAccountInformationPage /></NavRoute>} />
      {userNeedsOnboarding && <Route path='*' element={<Navigate to={returnEnterAccountInformationPath()} />} />} {/* Important, Navigate routes must redirect to routes that were defined BEFORE the navigate component. */}

      {/* {user?.uid && !userHasSubscribed && !roles?.includes('admin') ? (
        <Route path='*' element={<Navigate to={returnTrialExpiredPath()} />} />
      ) : ''} */}

      {/* Private routes */}
      {!userNeedsOnboarding && <Route path={returnAccountPath()} element={<NavRoute authRequired={true}><AccountPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnSearchForTopicPath()} element={<NavRoute authRequired={true} hideSearchBar={true} ><SearchForTopicPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnRequestTopicPath({ topicId: ':topicId' })} element={<NavRoute authRequired={true}><RequestTopicPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnTrialExpiredPath()} element={<NavRoute authRequired={true}><TrialExpiredPage /></NavRoute>} />}


      {/* Admin routes */}
      {!userNeedsOnboarding && <Route path={returnAdminConsolePath()} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminConsolePage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnAdminSearchForTopicPath()} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminSearchForTopicPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnAdminCreateTopicPath()} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminCreateTopicPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnAdminReviewTopicPath({ topicId: ':topicId' })} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminReviewTopicPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnAdminViewTopicReportsPath()} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminViewTopicReportsPage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnAdminTypesensePath()} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminTypesensePage /></NavRoute>} />}
      {!userNeedsOnboarding && <Route path={returnAdminViewUnapprovedTopicsPath()} element={<NavRoute authRequired={true} availableToRoles={['admin']}><AdminViewUnapprovedTopicsPage /></NavRoute>} />}


      {/* Catch-all routes */}
      {user?.uid ? (
        <Route path='*' element={<Navigate to={returnSearchForTopicPath()} />} />
      ) : (
        <Route path='*' element={<Navigate to={returnSignUpPath()} />} />
      )}


      {/* Deleted routes */}
      {/* <Route path={returnBrowseArticlesPath()} element={<NavRoute authRequired={true}><BrowseArticlesPage /></NavRoute>} /> */}
      {/* <Route path={returnViewArticlePath({articleId: ':articleId'})} element={<NavRoute authRequired={true}><ViewArticlePage /></NavRoute>} /> */}
      {/* <Route path={returnCreateTopicAndArticlePath({ topicId: ':topicId' })} element={<NavRoute authRequired={true}><CreateTopicAndArticlePage /></NavRoute>} /> */}
      {/* <Route path={returnUpdateArticlePath()} element={<NavRoute authRequired={true}><UpdateArticlePage /></NavRoute>} /> */}
      {/* <Route path={returnLoadingWithQuotePath()} element={<NavRoute authRequired={true}><LoadingWithQuotePage /></NavRoute>} /> */}


    </Routes>
  )
}

export default AppRouter;