import { useState, useEffect } from 'react';
import {getTopicReports} from '../actions/topicReports';
import LoadingPage from './LoadingPage';
import FallbackPage from './FallbackPage';
import { useStore } from '../store/store';
import AdminTopicReportListItem from './AdminTopicReportListItem';

const AdminViewTopicReportsPage = props => {

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const user = useStore(state => state.user);
  const [topicReports, setTopicReports] = useState([]);
  const [lengths, setLengths] = useState([]);



  useEffect(() => {
    setLoading(true);
    getTopicReports({
      user,
      page: 1,
      elemsPerPage: 100,
    }).then(({ topicReports, lengths }) => {
      setTopicReports(topicReports);
      setLengths(lengths);
      setLoading(false);
    }).catch((error) => {
      setErrorMessage(errorMessage);
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateTopicReportsArray = ({topicReportId, resolved}) => {
    const updatedTopicReports = topicReports.map((topicReport) => {
      return topicReport?.topicReportId === topicReportId ? {...topicReport, resolved} : topicReport;
    })
    setTopicReports(updatedTopicReports);
  }


  if (loading) {
    return <LoadingPage />
  } else if (errorMessage) {
    return <FallbackPage subMessage={errorMessage} />
  } else {
    return (

        <div className="p-4">



        {/* Title */}
        <div className="border-b">
          <div className="font-semibold text-3xl">Topic reports</div>
          <div className="text-sm text-gray-500">{lengths?.numberOfTopicReports} reports</div>

        </div>

        {/* List */}
        <div className="flex flex-col space-y-4 mt-4">

          <div className="grid grid-cols-6 text-sm font-semibold text-gray-700">
            <div className="col-span-1 text-gray-700">Reported</div>
            <div className="col-span-1 text-gray-700">Issue</div>
            <div className="col-span-2 text-white-500">Message</div>
            <div className="col-span-1 text-gray-700">Resolved</div>
            <div className="col-span-1">Topic</div>
          </div>


          {topicReports.map((topicReport, index) => {
            return (
              <AdminTopicReportListItem 
                key={index}
                topicReport={topicReport}
                handleUpdateTopicReportsArray={handleUpdateTopicReportsArray}
              />
            )
          })}
        </div>

      </div>
    )
  } 
};

export default AdminViewTopicReportsPage;