import {Suspense} from 'react';
import { captureAndFormatException } from './dependencies/sentry';
import './App.css';
import AppRouter from './routers/AppRouter';
import { onAuthStateChanged } from "firebase/auth";
import { useStore } from './store/store';
import { auth } from './firebase/firebase';
import { useEffect } from 'react';
import LoadingPage from './components/LoadingPage';
import { getAccount } from './actions/accounts';
import {
  // returnFallbackPath,
  returnHomePath,
  returnSearchForTopicPath,
  returnSignInPath,
  returnSignUpPath,
} from './helpers/urls';
import { useNavigate, useLocation } from "react-router-dom";
import { getProfileImageCroppedUrl } from './actions/profileImages';
// import { returnRoles } from './helpers/roles';
import { identifyUser } from './dependencies/mixpanel';
import './dependencies/hotjar';


function App() {

  const user = useStore(state => state.user);
  const setUser = useStore(state => state.setUser);
  const setRoles = useStore(state => state.setRoles);
  const authLoading = useStore(state => state.authLoading);
  const setAuthLoading = useStore(state => state.setAuthLoading);
  const appLoading = useStore(state => state.appLoading);
  const setAppLoading = useStore(state => state.setAppLoading);
  const setAccount = useStore(state => state.setAccount);
  const uid = user ? user.uid : null;
  const navigate = useNavigate();
  const setProfileImageCroppedUrl = useStore(state => state.setProfileImageCroppedUrl);
  const location = useLocation();
  const pathname = location.pathname;



  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setUser(user);
      // const roles = await returnRoles({user});
      // setRoles(roles);
      setAuthLoading(false);
      identifyUser({uid})
    } else {
      setUser(null);
      setAuthLoading(false);
    }
  });


  // When user logs in, init data
  useEffect(() => {
    let isMounted = true;

    const setUpApp = async () => {

      if (authLoading) {
        return;
      } else if (!uid) {
        return setAppLoading(false);
      } else {

        // 1. Get account data
        setAppLoading(true);
        let accountData;
        await getAccount({ user })
          .then((results) => {
            if (!isMounted) return;
            setAccount(results);
            setRoles(results?.roles);
            accountData = results;
            setAppLoading(false);
          })
          .catch((error) => {
            if (!isMounted) return;
            console.error({ error })
            setAppLoading(false);
            navigate(returnHomePath())
            captureAndFormatException(error);
            console.error('Stopping execution because account not found.')
            return // Stop execution if account is not found.
          })


        // 2. Get profile image
        try {
          if (accountData?.profileImageCroppedUrl) {
            const profileImageCroppedUrl = await getProfileImageCroppedUrl({ uid });
            setProfileImageCroppedUrl(profileImageCroppedUrl)
          }
        } catch (error) {
          captureAndFormatException(error);
          console.error(`Could not get profile image: `, error)
        }

        // 3. If signin or signup, redirect
        if (pathname === returnSignInPath() || pathname === returnSignUpPath()) {
          navigate(returnSearchForTopicPath()); // For some reason, it doesn't like navigating to returnHomePath().
        }
        
      }
    }

    setUpApp();
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, authLoading]);

  if (
    appLoading ||
    authLoading
  ) {
    return <LoadingPage />
  } else {
    return (
      <div className="h-screen">
        <Suspense fallback={<LoadingPage />}>
          <AppRouter />
        </Suspense>
      </div>
    );
  }
}

export default App;
