import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";


const override = css`
  border: none;
`;


const LoadingPage = () => {
  return (
    <div className="flex justify-center items-center h-screen-adj">
      <BeatLoader color='#3B82F6' css={override} loading={true} size={25} />
    </div>
  )
}

export default LoadingPage;