import { appName } from "../helpers/data";
import { returnLandingPageUrl, returnSignUpPath, returnLogoPath, returnSignInPath } from "../helpers/urls";
import { useNavigate } from "react-router-dom";

const Paywall = props => {

  const navigate = useNavigate();

  return (
    <div className="w-full rounded bg-gray-700 p-4 shadow text-white flex flex-col items-center">
      <div>Continue reading with a subscription to</div>
      <div
        className=" font-semibold flex space-x-1 items-center text-center"
      >
        <img src={returnLogoPath()} className="w-6 h-6 " alt={appName} />
        <span className="text-2xl p-0.5">{appName}</span>
      </div>
      <div className="px-8 flex justify-center space-x-8">
        <button
          className=" rounded border bg-white text-black hover:bg-gray-100 border-white  px-2 py-1 text-sm"
          onClick={() => { navigate(returnSignUpPath()) }}
        >
          Sign up
        </button>
        <button
          className=" rounded border border-gray-500  hover:bg-gray-600 px-2 py-1 text-sm"
          onClick={() => { navigate(returnLandingPageUrl()) }}
        >
          Learn more
        </button>
      </div>  
      <div className="my-2 mt-4 text-center text-sm ">
        {`Or  `}
        <span onClick={() => { navigate(returnSignInPath()) }} className="cursor-pointer font-medium underline hover:no-underline">
          sign in
        </span> to an existing account.
      </div>
    </div>
  );
};

export default Paywall;