import { ref, getDownloadURL } from "firebase/storage";
import { returnProfileImageUrl } from '../helpers/urls';
import {storage} from '../firebase/firebase';
import { useStore } from '../store/store';

export const getProfileImageCroppedUrl = async ({ uid }) => {
  const profileImageUrl = await getDownloadURL(ref(storage, returnProfileImageUrl({uid})));
  console.log('Retrieved profileImageUrl, ', profileImageUrl);
  return profileImageUrl;
}


export const useProfileImageUrl = async ({uid}) => {

  const account = useStore(state => state.account);
  const profileImageCroppedUrl = account?.profileImageCroppedUrl;

  if (profileImageCroppedUrl) {
    return profileImageCroppedUrl;
  } else {
    return await getProfileImageCroppedUrl({uid})
  }
}

// export const getProfileImages = async ({ user }) => {
//   const idToken = !user ? null : await user.getIdToken();
//   return new Promise((resolve, reject) => {
//     fetch(`${apiUrl}/profile-images`, {
//       method: 'GET',
//       headers: {
//         'authorization': `Bearer ${idToken}`,
//         'Content-Type': 'application/json'
//       },
//     }).then(res => {
//       if (res.ok) {
//         res.json().then(data => {
//           console.log({ data });
//           resolve(data)
//         }).catch((error) => {
//           console.log(error);
//         });
//       } else {
//         res.text().then(text => reject(text));
//       };
//     }).catch((error) => {
//       reject(error);
//     });
//   });
// }