import moment from 'moment';
import { returnAdminReviewTopicPath } from '../helpers/urls';
import Switch from './Switch';
import { useNavigate } from 'react-router-dom';
import {saveTopicReportResolution} from '../actions/topicReports';
import {useStore} from '../store/store';
import { trackEvent } from '../dependencies/mixpanel';

const AdminTopicReportListItem = ({
  topicReport,
  handleUpdateTopicReportsArray
}) => {

  const {
    topicReportId,
    dateCreated,
    topicId,
    reportReasonLabel,
    reportMessage,
    resolved
  } = topicReport;
  const navigate = useNavigate();
  const user = useStore(state => state.user);


  const handleSaveTopicReportResolution = () => {
    saveTopicReportResolution({
      user,
      topicReportId,
      resolved: !resolved,
    }).then(({topicReportId, resolved}) => {
      handleUpdateTopicReportsArray({ topicReportId, resolved });
      trackEvent({ name: 'Admin topic report resolution saved', params: { topicReportId, resolved }})
    }).catch((error) => {
      console.log(error)
      console.error('Error changing topicReport status', error)
      // setErrorMessage('Topic publication status could not be changed.');
    })
  }

  return (
    <div className="grid grid-cols-6 text-sm">
      <div className="col-span-1 text-gray-700">{moment(dateCreated).format('MMM Do YY h:mm:ss')}</div>
      <div className="col-span-1 text-gray-700">{reportReasonLabel}</div>
      <div className="col-span-2 text-white-500">{reportMessage}</div>
      <div className="col-span-1 mt-1 flex space-x-2">
        <Switch
          on={!!resolved}
          setOn={() => handleSaveTopicReportResolution({ topicId })}
        />
        <div className={`text-xs ${resolved ? 'text-blue-500' : 'text-gray-500'}`}>{resolved ? 'Resolved' : 'Not resolved'}</div>
      </div>
      <div className="col-span-1">
        <button
          className="text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded"
          onClick={() => { navigate(returnAdminReviewTopicPath({ topicId })) }}
        >
          View topic
        </button>
      </div>
    </div>
  );
};

export default AdminTopicReportListItem;