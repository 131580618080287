export const makeSuspenseful = (promise) => {

  let status = "pending";
  let result = null;

  promise.then(
    value => {
      status = "success";
      result = value;
    },
    error => {
      status = "success"; // Promise can only resolve.  Alternative is // status = "error";
      result = error;
    }
  );

  return {
    read() {
      if (status === "pending") {
        throw promise;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}
