import { useState, useEffect } from 'react';
import { getTopicsWithoutArticles } from '../actions/topics';
import LoadingPage from './LoadingPage';
import FallbackPage from './FallbackPage';
import { useStore } from '../store/store';
import AdminQueryBuilder from './AdminQueryBuilder';
import AdminTopicsList from './AdminTopicsList';
import PaginationBar from './PaginationBar';
import { trackEvent } from '../dependencies/mixpanel';
// import {usePrevious} from '../helpers/usePrevious';

const AdminViewUnapprovedTopicsPage = props => {

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const user = useStore(state => state.user);
  // const [loadingTopics, setLoadingTopics] = useState(false);
  const [topics, setTopics] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(0);
  // const prevPage = usePrevious(page);
  const elemsPerPage = 25;
  const totalPages = Math.ceil(lengths?.numberOfTopics / elemsPerPage);
  const [sorts, setSorts] = useState([])


  const handleRetrieveTopics = async ({lastTopicId, direction}) => {
    if (page * elemsPerPage >= lengths?.numberOfTopics) return console.log('Cannot retrieve more topics.');
    if (page < 0) return console.log('Cannot retrieve fewer than page 0.');

    // setLoadingTopics(true);
    await getTopicsWithoutArticles({
      user,
      page,
      elemsPerPage,
      filterBy: filters,
      lastTopicId,
      direction,
      sort: sorts.length > 0 ? sorts[0] : {},
    }).then(({ topics, lengths }) => {
      setTopics(topics);
      setLengths(lengths);
      trackEvent({ name: 'Admin topics without articles retrieved', params: { numberOfResults: topics?.length, lengths } })
      // setLoadingTopics(false);
    }).catch((error) => {
      setErrorMessage(errorMessage);
      // setLoadingTopics(false);
      console.error(error);
    })
  }

  const handleClickPrevious = () => {
    page === 0 ? console.log('Cannot decrement page below 0') : setPage(page - 1)
    handleRetrieveTopics({ 
      lastTopicId: topics[0]?.topicId,
      direction: 'previous',
    });
  }

  const handleClickNext = () => {
    page === totalPages - 1 ? console.log('Cannot increment page above max.') : setPage(page + 1)
    handleRetrieveTopics({
      lastTopicId: topics[topics.length - 1]?.topicId,
      direction: 'next',
    });
  }

  const handleApplyQuery = async () => {
    setPage(0);
    await handleRetrieveTopics({ lastTopicId: undefined, direction: 'next' });
    setLoading(false);
  }

  useEffect(() => {
    handleRetrieveTopics({ lastTopicId: undefined, direction: 'next' });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateTopicsArray = ({topicId, approved, approvedBy, dateApproved, showcased, showcasedBy, dateShowcased, published, datePublished, publishedArticleId, publishedBy }) => {
    const updatedTopicsArray = topics.map((topic) => {
      return topic?.topicId === topicId ? { ...topic, approved, approvedBy, dateApproved, showcased, showcasedBy, dateShowcased, published, datePublished, publishedArticleId, publishedBy } : topic;
    })
    setTopics(updatedTopicsArray);
  }

  const handleUpdateTopicsArrayWithBasicTopicData = ({ topicId, title, code }) => {
    const updatedTopicsArray = topics.map((topic) => {
      return topic?.topicId === topicId ? { ...topic, title, code } : topic;
    })
    setTopics(updatedTopicsArray);
  }

  const handleRemoveTopicFromArray = ({topicId}) => {
    const updatedTopicsArray = topics.filter((topic) => {
      return topic?.topicId !== topicId;
    })
    setTopics(updatedTopicsArray);
  }


  if (loading) {
    return <LoadingPage />
  } else if (errorMessage) {
    return <FallbackPage subMessage={errorMessage} />
  } else {
    return (

      <div className="p-4">


        {/* Title */}
        <div className="border-b">
          <div className="font-semibold text-3xl">Topics</div>
          <div className="text-sm text-gray-500">{lengths?.numberOfTopics} topics</div>
        </div>

        {/* Query Builder */}
        <AdminQueryBuilder 
          handleRetrieveData={handleApplyQuery}
          filters={filters}
          setFilters={setFilters}
          sorts={sorts}
          setSorts={setSorts}
        />

        {/* Divider */}
        <div className="divide-y my-2 border-t border-gray-300" />


        {/* List */}
        <AdminTopicsList 
          topics={topics}
          handleUpdateTopicsArray={handleUpdateTopicsArray}
          handleRemoveTopicFromArray={handleRemoveTopicFromArray}
          handleUpdateTopicsArrayWithBasicTopicData={handleUpdateTopicsArrayWithBasicTopicData}
        />

        {/* Divider */}
        <div className="divide-y my-2 border-t border-gray-300" />

        <PaginationBar 
          page={page}
          setPage={setPage}
          elemsPerPage={elemsPerPage}
          totalElems={lengths?.numberOfTopics}
          handleClickPrevious={handleClickPrevious}
          handleClickNext={handleClickNext}
        />

      </div>
    );
  } 
};

export default AdminViewUnapprovedTopicsPage;