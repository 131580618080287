import { returnMagnifyingGlassIcon } from "../helpers/icons";
import { useStore } from "../store/store";

const SearchAtBottom = props => {

  const setSearchModalOpen = useStore(state => state.setSearchModalOpen);

  return (
    <div className="w-full justify-center inline-block align-bottom  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full">

      <div className="bg-gray-700 p-8">
        <button 
          className="w-full rounded bg-white shadow text-white flex flex-col items-start "
          onClick={() => { setSearchModalOpen(true) }}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-start items-center px-4 w-full">
              <div className="text-gray-400 ">{returnMagnifyingGlassIcon('h-5 w-5')}</div>
              <input
                className="bg-white px-2 py-4 w-full outline-none cursor-pointer"
                placeholder={`Search for a new case... `}
                disabled={true}
              />
            </div>
          </div>
        </button>
      </div>

    </div>
  );
};

export default SearchAtBottom;