import { returnChevronLeft, returnChevronRight } from "../helpers/icons";

const PaginationBar = ({
  page,
  setPage,
  elemsPerPage,
  totalElems,
  handleClickPrevious,
  handleClickNext,
}) => {

  const startingElem = page * elemsPerPage + 1;
  const endingElem = (page + 1) * elemsPerPage;
  // const totalPages = Math.ceil(totalElems / elemsPerPage);



  return (
    <div className="flex items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6">


      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startingElem}</span> to <span className="font-medium">{Math.min(endingElem, totalElems)}</span> of{' '}
            <span className="font-medium">{totalElems}</span> results
          </p>
        </div>


        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">

            {/* Previous button */}
            <button
              onClick={() => { handleClickPrevious()}}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-blue-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              {returnChevronLeft('h-5 w-5')}
            </button>

            {/* Next button */}
            <button
              onClick={() => { handleClickNext() }}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-blue-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              {returnChevronRight('h-5 w-5')}
            </button>


          </nav>
        </div>
      </div>
    </div>
  )
}

export default PaginationBar;