import LoadingPage from './LoadingPage';
import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import { getArticlesFromTopicId } from '../actions/articles';
import { exclamationIcon } from "../helpers/icons";
import { useParams } from 'react-router-dom';
import AdminSmallArticlesList from './AdminSmallArticlesList';
import AdminCreateArticle from './AdminCreateArticle';
import HorrizontalTabs from './HorrizontalTabs';
import AdminViewArticleDetails from './AdminViewArticleDetails';
import AdminUpdateArticleContent from './AdminUpdateArticleContent';
import AdminPublishArticle from './AdminPublishArticle'
import AdminTopicHeader from './AdminTopicHeader';
import AdminApprovedSwitch from './AdminApprovedSwitch';
import AdminCreateArticleFromText from './AdminCreateArticleFromText';
import AdminShowcaseSwitch from './AdminShowcaseSwitch';
import AdminCreateArticleWithAutoPrompt from './AdminCreateArticleWithAutoPrompt';

export const AdminReviewTopicPage = () => {

  // Topic
  const params = useParams();
  const {topicId} = params;
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [approved, setApproved] = useState(null);
  const [showcased, setShowcased] = useState(null);
  const [publishedArticleId, setPublishedArticleId] = useState();
  const [published, setPublished] = useState(false);
    // const [hasArticle, setHasArticle] = useState(false);

  // Articles
  const [articles, setArticles] = useState([]);
  const sortedArticles = articles?.sort((a, b) => a?.dateCreated - b?.dateCreated);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const selectedArticle = articles?.find(article => article.articleId === selectedArticleId);
  const [articlesLoading, setArticlesLoading] = useState(true);
  
  // Settings
  const [errorMessage, setErrorMessage] = useState(false);
  const user = useStore(state => state.user);
  
  // Tab settings
  let tabs = [
    {
      label: 'View article details',
      value: 'view-article-details',
      condition: 'update'
    }, {
      label: 'Update article content',
      value: 'update-article-content',
      condition: 'update'
    }, {
      label: 'Create article from text',
      value: 'create-article-from-text',
      condition: 'create'
    }, {
      label: 'Create article with AI',
      value: 'create-article-with-ai',
      condition: 'create'
    },
    {
      label: 'Create article with auto-prompt',
      value: 'create-article-with-auto-prompt',
      condition: 'create'
    },
  ]

  const updateOrCreate = selectedArticle?.articleId ? 'update' : 'create';
  tabs = tabs.filter((tab) => tab?.condition === updateOrCreate);
  const [selectedTabValue, setSelectedTabValue] = useState(tabs[0]?.value)


  // On start, get articles
  useEffect(() => {
    setArticlesLoading(true);
    getArticlesFromTopicId({
      user,
      topicId,
    }).then(({ articles, topic }) => {
      setTitle(topic?.title);
      setCode(topic?.code);
      setApproved(topic?.approved);
      setShowcased(topic?.showcased)
      setPublishedArticleId(topic?.publishedArticleId);
      setPublished(topic?.published);
      setArticles(articles);
      setArticlesLoading(false);
      if (articles?.length > 0) setSelectedArticleId(articles[0]?.articleId);
      // setHasArticle(topic?.hasArticle);
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error retrieving articles', errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setArticlesLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When an article is created, update state
  const pushArticlesToArticlesArray = (article) => {
    setArticles([...articles, article]);
  }

  // When an article is updated, update state
  const updateArticlesArray = (selectedArticle) => {
    const updatedArticles = articles.map((article) => {
      return article?.articleId === selectedArticle.articleId ? selectedArticle : article;
    })
    setArticles(updatedArticles);
  }

  // When the selected article Id is changed, change the selected article.
  useEffect(() => {
    setSelectedTabValue(tabs[0]?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticleId])

  

  return articlesLoading ? <LoadingPage /> : (
    <div className="min-h-screen-adj bg-gray-100 py-6 sm:px-8 lg:px-8">

        <AdminTopicHeader 
          topicId={topicId}
          title={title}
          setTitle={setTitle}
          code={code}
          setCode={setCode}
        />

        <div className="flex gap-x-4">

          <div>
            <AdminSmallArticlesList
              selectedArticle={selectedArticle}
              setSelectedArticleId={setSelectedArticleId}
              articles={sortedArticles}
              topicId={topicId}
              publishedArticleId={publishedArticleId}
            />
          </div>


          <div className="w-full flex flex-col space-y-2">
            <div className="flex justify-between">
              <HorrizontalTabs
                tabs={tabs}
                selectedTabValue={selectedTabValue}
                setSelectedTabValue={setSelectedTabValue}
              />

              <div className="flex space-x-4 items-center">
                {updateOrCreate === 'update' ? <AdminShowcaseSwitch
                  topicId={topicId}
                  showcased={showcased}
                  setShowcased={setShowcased}
                /> : ''}
                
                {updateOrCreate === 'update' ? <AdminApprovedSwitch
                  topicId={topicId}
                  approved={approved}
                  setApproved={setApproved}
                /> : ''}

                {updateOrCreate === 'update' ? <AdminPublishArticle
                  topicId={topicId}
                  publishedArticleId={publishedArticleId}
                  selectedArticle={selectedArticle}
                  setPublishedArticleId={setPublishedArticleId}
                  setPublished={setPublished}
                /> : ''}
              </div>
            </div>

            {updateOrCreate === 'create' && selectedTabValue === 'create-article-with-ai' ? (
              <AdminCreateArticle 
                topicId={topicId}
                title={title}
                code={code}
                setSelectedArticleId={setSelectedArticleId}
                selectedArticle={selectedArticle}
                pushArticlesToArticlesArray={pushArticlesToArticlesArray}
              />
            ) : ''}

          {updateOrCreate === 'create' && selectedTabValue === 'create-article-from-text' ? (
            <AdminCreateArticleFromText
              topicId={topicId}
              setSelectedArticleId={setSelectedArticleId}
              selectedArticle={selectedArticle}
              pushArticlesToArticlesArray={pushArticlesToArticlesArray}
            />
          ) : ''}

          {updateOrCreate === 'create' && selectedTabValue === 'create-article-with-auto-prompt' ? (
            <AdminCreateArticleWithAutoPrompt 
              topicId={topicId}
              published={published}
              pushArticlesToArticlesArray={pushArticlesToArticlesArray}
              setPublished={setPublished}
              setPublishedArticleId={setPublishedArticleId}
              setSelectedArticleId={setSelectedArticleId}
            />

          ) : ''}

            {updateOrCreate === 'update' && selectedTabValue === 'view-article-details' ? (
              <AdminViewArticleDetails
                selectedArticle={selectedArticle}
              />
            ) : ''}

            {updateOrCreate === 'update' && selectedTabValue === 'update-article-content' ? (
              <AdminUpdateArticleContent
                topicId={topicId}
                updateArticlesArray={updateArticlesArray}
                selectedArticle={selectedArticle}
              />
            ) : ''}

          </div>


        </div>
        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

    </div>
  )
}

export default AdminReviewTopicPage;