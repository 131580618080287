import { useState } from 'react';
import { removeTrailingColon } from '../helpers/utilities';

const NavigationSidePanel = ({anchorList}) => {

  const [activeAnchor, setActiveAnchor] = useState(anchorList[0]?.value);

  const handleClick = ({anchor}) => {
    if (!anchor) return console.log('No anchor');
    const element = document.getElementById(anchor);
    if (!element) return console.log('No element');
    const yOffset = -64;
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    setActiveAnchor(anchor);
  }

  return (
    <div
      className="flex flex-col space-y-2 rounded-lg w-48 max-h-96 text-sm text-gray-700 pt-3"
    >
      {anchorList.map(({ label, value }, index) => {

        if (value === activeAnchor) {
          // console.log({ activeAnchor, value })
        }
        return (
          <button
            key={index}
            className={` ${activeAnchor === value ? 'font-bold' : ''} py-1 px-4 hover:underline text-left }`}
            onClick={() => handleClick({anchor: value})}
          >
            {removeTrailingColon(label)}
          </button>
        )
      })}
    </div>
  )
}

export default NavigationSidePanel;