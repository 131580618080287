import { appName, navbarHeight } from '../helpers/data';
import { useNavigate} from 'react-router-dom';
import { useStore } from '../store/store';
import { useState, useEffect } from 'react';
import AccountMenu from './AccountMenu';
import { /* returnUserCornerIcon, smallChevronLeftIcon returnUserIcon, */ returnMagnifyingGlassIcon } from '../helpers/icons';
import { 
  returnHomePath, 
  returnSignInPath, 
  returnLogoPath,
  returnAdminConsolePath
} from '../helpers/urls';
import Avatar from './Avatar';
import SearchModal from './SearchModal';
import {useKeyPress} from '../helpers/useKeyPress';
import { returnDaysLeftInTrial, /* returnMetaKeyShort */ } from '../helpers/utilities';

const Navbar = ({hideSearchBar = false}) => {

  let navigate = useNavigate();
  const account = useStore(state => state.account);
  const user = useStore(state => state.user);
  const roles = useStore(state => state.roles);
  const searchModalOpen = useStore(state => state.searchModalOpen);
  const setSearchModalOpen = useStore(state => state.setSearchModalOpen);


  const [menuOpen, setMenuOpen] = useState(false);
  const profileImageCroppedUrl = account?.profileImageCroppedUrl;

  const [resetIncrement, setResetIncrement] = useState(0);
  const cmdIsPressed = useKeyPress({ key: "Meta", resetIncrement })
  const kIsPressed = useKeyPress({ key: "k", resetIncrement })

  useEffect(() => {
    if (searchModalOpen) {
      setResetIncrement(state => state + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchModalOpen]);

  useEffect(() => {
    if (cmdIsPressed && kIsPressed) {
      setSearchModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmdIsPressed, kIsPressed]);

  useEffect(() => {
    setResetIncrement(state => state + 1);
  }, [])
  
  

  return (
    // <div className={`bg-gray-700 h-${navbarHeight} px-4 w-full flex justify-between sticky top-0 z-50`}>
    <div className={`bg-gray-700 h-${navbarHeight} px-4 w-full flex justify-between sticky top-0`}>
      <div className="flex items-center justify-between w-full space-x-6">
        <button
          className="col-span-4 text-white font-semibold flex items-center gap-1"
          onClick={() => { navigate(returnHomePath()) }}
        >
          <img src={returnLogoPath()} className="w-6 h-6" alt={appName} />
          <span className="text-2xl p-0.5 font-cuprum">{appName}</span>
        </button>
        <div className="flex items-center gap-x-8">

          {/* Admin */}
          {!user?.uid || !roles?.includes('admin') ? '' : <button
            className={`text-white text-sm py-1 px-2 border rounded flex items-center justify-center gap-x-2 font-light hover:bg-gray-600`}
            onClick={() => navigate(returnAdminConsolePath())}
          >
            <span>Admin Page</span>
          </button>}

          {/* Trial */}
          {user?.uid && account?.subscriptions?.includes('trial') ? <div
            className={`text-white text-sm py-1 px-2 border rounded flex items-center justify-center gap-x-2 font-light`}
          >
            <span>{`Trial expires in ${returnDaysLeftInTrial({ dateOfTrialExpiration: account?.dateOfTrialExpiration })} days`}</span>
          </div> : ''}

          {/* Search */}
          {user?.uid && !hideSearchBar && <button
            className={`text-white text-sm py-1 px-2 border rounded flex items-center justify-center gap-x-2 font-light hover:bg-gray-600`}
            onClick={() => setSearchModalOpen(true)}
          >
            <span>Search</span>
            <span>{returnMagnifyingGlassIcon('h-3.5 w-3.5')}</span>
            {/* { <div className="text-xs text-gray-100">{returnMetaKeyShort()}K</div>} */}
          </button>}
          {user?.uid && !hideSearchBar && <SearchModal 
            open={searchModalOpen}
            setOpen={setSearchModalOpen}
          />}

          {/* Account menu */}
          {!user?.uid ? (
            <button 
              className="px-2 py-0.5 rounded text-white border border-white hover:bg-gray-500 text-sm"
              onClick={() => navigate(returnSignInPath())}
            >Sign in</button>
          ) : <Avatar 
            onClick={() => { setMenuOpen(true) }}
            profileImageCroppedUrl={profileImageCroppedUrl}
            className={'w-7 h-7 text-white ring-white border-white'}
          />}
          {!menuOpen ? '' : (
            <div>
              <AccountMenu open={menuOpen} setOpen={setMenuOpen} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar;