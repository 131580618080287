import {create} from 'zustand'
// import produce from 'immer';

export const useStore = create(set => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  authLoading: true,
  setAuthLoading: (authLoading) => set(() => ({authLoading})),
  appLoading: true,
  setAppLoading: (appLoading) => set(() => ({ appLoading })),
  account: {},
  setAccount: (account) => set(() => ({ account })),
  creatingAccount: false,
  setCreatingAccount: (creatingAccount) => set(() => ({ creatingAccount })),
  email: '',
  setEmail: (email) => set(() => ({email})),
  draggingQuestion: false,
  setDraggingQuestion: (draggingQuestion) => set(() => ({ draggingQuestion })),
  profileImageCroppedUrl: null,
  setProfileImageCroppedUrl: (profileImageCroppedUrl) => set(() => ({ profileImageCroppedUrl})),
  profileImagesMap: {},
  setProfileImagesMap: (profileImagesMap) => set(() => ({ profileImagesMap })),
  company: {},
  setCompany: (company) => set(() => ({ company })),
  searchModalOpen: false,
  setSearchModalOpen: (searchModalOpen) => set(() => ({ searchModalOpen })),
  roles: [],
  setRoles: (roles) => set(() => ({ roles })),
}))