import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import 'react-quill/dist/quill.snow.css';
import { spinnerIcon, exclamationIcon, returnChevronRight, returnCheckCircleIcon } from "../helpers/icons";
import Joi from 'joi';
import { promptSchema } from '../helpers/schemas';
import { returnSamplePromptContent } from '../helpers/data';
import RequiredAsterisk from './RequiredAsterisk';
import BasicCodeBlock from './BasicCodeBlock';
import { createArticle } from '../actions/articles';
import Select from 'react-select';
import {trackEvent} from '../dependencies/mixpanel';


export const AdminCreateArticle = ({
  
  // Topic
  topicId,
  title,
  code,
  pushArticlesToArticlesArray,
  setSelectedArticleId,

  // Article
  // selectedArticle,
  
}) => {


  // Settings
  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonSucceeded, setButtonSucceeded] = useState(false);

  /* Request */

  // Model
  const modelOptions = [
    {
      value: 'gpt-4',
      label: 'gpt-4',
    }, {
      value: 'gpt-3.5-turbo',
      label: 'gpt-3.5-turbo',
    }, {
      value: 'gpt-3.5-turbo-16k',
      label: 'gpt-3.5-turbo-16k',
    }
  ]
  const [selectedModelOption, setSelectedModelOption] = useState(modelOptions[0]);
  const modelName = selectedModelOption?.value;

  // Temperature
  const temperatureMax = 1;
  const temperatureMin = 0;
  const [temperature, setTemperature] = useState(0);

  // Max tokens
  const maxTokensMax = 8000;
  const maxTokensMin = 1;
  const [maxTokens, setMaxTokens] = useState(8000);

  // Messages
  let topicName = `${title},  ${code}`
  const [messages, setMessages] = useState([
    {
      role: "system",
      content: "You are a helpful assistant."
    }, {
      role: "user",
      content: returnSamplePromptContent({topicName})
    }

  ]);
  const minMessages = 2;
  const [messageToRemoveIndex, setMessageToRemoveIndex] = useState(0);

  // Other parts of the prompt
  const promptLength = prompt.length;
  const charsPerToken = 4;
  const tokensInPrompt = Math.ceil(promptLength / charsPerToken);

  // Request body
  const requestBody = {
    model: modelName,
    messages,
    max_tokens: maxTokens,
    temperature,
  }

  /* Helper functions */
  const handleAddMessage = () => {
    let newMessages = [
      ...messages, 
    {
      role: 'user',
      content: '',
      name: '',
      function_call: {},
    }];
    console.log({ newMessages })

    setMessages(newMessages);
  }

  const handleRemoveMessage = () => {
    if (messages.length === minMessages) return;
    let newMessages = messages.splice(messageToRemoveIndex, 1);
    setMessages(newMessages);
  }

  const handleMessageChange = (value, index, fieldName) => {
    let data = [...messages];
    data[index][fieldName] = value;
    setMessages(data);
  }

  const isRequestBodyValid = () => {
    try {
      Joi.attempt(requestBody, promptSchema);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /* Create article */
  const handleCreateArticle = () => {
    if (!isRequestBodyValid()) return;
    setButtonLoading(true);
    createArticle({ 
      user, 
      topicId, 
      requestBody 
    }).then((responseBody) => {
      console.log({responseBody});
      pushArticlesToArticlesArray(responseBody?.article);
      setSelectedArticleId(responseBody?.article?.articleId)
      setButtonLoading(false);
      // setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({ name: 'Admin article created', params: { topicId, articleId: responseBody?.article?.articleId } })
    }).catch((error) => {
      console.log(error)
      setErrorMessage('Article could not be created.');
      setButtonLoading(false);
    })
  }


  useEffect(() => {
    if (!buttonSucceeded) return;
    setTimeout(() => {
      setButtonSucceeded(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSucceeded]);



  return (
    <div className="w-full flex flex-col space-y-4">

      {/* Title */}
      <div className="flex items-end justify-between">
        <div className="font-semibold text-3xl">Add an article</div>
      </div>

      {/* Add section */}
      {/* Model */}
      <div>
        <label className="text-sm font-semibold">Model<RequiredAsterisk /></label>
        <Select
          classNamePrefix="select"
          isClearable={false}
          // isRtl={isRtl}
          isSearchable={true}
          value={selectedModelOption}
          options={modelOptions}
          onChange={(option) => setSelectedModelOption(option)}
        />
      </div>

      {/* Temperature */}
      <div>
        <label className="text-sm font-semibold">Temperature<RequiredAsterisk /></label>
        <input
          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
          type="number"
          value={temperature}
          onChange={(e) => setTemperature(Number(e.target.value))}
          max={temperatureMax}
          min={temperatureMin}
        />
      </div>

      {/* Max tokens */}
      <div>
        <label className="text-sm font-semibold">Max tokens<RequiredAsterisk /></label>
        <input
          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
          type="number"
          value={maxTokens}
          max={maxTokensMax}
          min={maxTokensMin}
          onChange={(e) => setMaxTokens(Number(e.target.value))}
        />
      </div>

      {/* Messages controls */}
      <div className="flex items-end justify-between bg-gray-300 p-2 rounded">
        <div>
          <label className="text-sm font-semibold">Number of messages<RequiredAsterisk /></label>
          <button
            className="rounded appearance-none relative block px-3 py-2 border border-gray-500 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            onClick={() => handleAddMessage()}
          >
            Add Message
          </button>
        </div>
        <div className="flex space-x-2">
          <input
            className="rounded appearance-none relative block px-3 py-2 border border-gray-500 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            type='number'
            value={messageToRemoveIndex + 1}
            max={messages.length}
            min={0}
            onChange={(e) => setMessageToRemoveIndex(e.target.value - 1)}
          />
          <button
            className="rounded appearance-none relative block px-3 py-2 border border-gray-500 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            onClick={() => handleRemoveMessage()}
          >
            Remove
          </button>
        </div>
      </div>

      {/* Messages */}
      {messages?.map((message, index) => {
        return (
          <div className="flex flex-col space-y-2 rounded p-2 border bg-gray-300 border-gray-300" key={index}>
            <div className="text-sm text-gray-700 font-semibold">Message {index + 1}</div>
            <div>
              <div className="text-sm text-gray-700 font-semibold py-1">Role</div>
              <textarea
                value={message?.role || ''}
                className='rounded p-2 text-sm text-gray-700 w-full resize-none'
                rows={2}
                onChange={(e) => {
                  handleMessageChange(e.target.value, index, 'role')
                }}
              />
            </div>
            <div>
              <div className="text-sm text-gray-700 font-semibold py-1">Content</div>
              <textarea
                value={message?.content || ''}
                rows={20}
                className='rounded p-2 text-sm text-gray-700 w-full resize-none'
                onChange={(e) => {
                  handleMessageChange(e.target.value, index, 'content')
                }}
              />
            </div>
          </div>
        )
      })}

      {/* Length */}
      <div>
        <label className="text-sm font-semibold">Length</label>
        <div className="text-gray-700 text-sm">{promptLength} characters in prompt.</div>
        <div className="text-gray-700 text-sm">{tokensInPrompt} tokens in prompt.</div>
      </div>


      {/* Formatted request */}
      <div className="flex flex-col space-y-2">
        <div className="text-sm text-gray-700 font-semibold">Request Body</div>
        <BasicCodeBlock
          code={JSON.stringify(requestBody, null, 2)}
          setCode={() => { console.log('Code block is read only.') }}
        />
      </div>
      {isRequestBodyValid() ? (
        <div className="text-sm text-blue-500 font-semibold">Request body is valid.</div>
      ) : (
        <div className="text-sm text-red-500 font-semibold">Request body is not valid.</div>
      )}

      {/* Submit */}
      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => {handleCreateArticle() }}
          disabled={buttonLoading}
        >
          {buttonLoading ? spinnerIcon :
            (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
              {returnChevronRight()}
            </span>)
          }
          {buttonLoading || buttonSucceeded ? '' : 'Create article with AI'}
          {buttonLoading || !buttonSucceeded ? '' : <span
          >{returnCheckCircleIcon('w-5 h-5')}</span>}
        </button>
      </div>

      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>
  )
}

export default AdminCreateArticle;