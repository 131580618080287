import { returnUserIcon } from '../helpers/icons';

const Avatar = ({ onClick, profileImageCroppedUrl, className = "w-7 h-7 " }) => {
  
  return !profileImageCroppedUrl ? (
    <div
      className={`${className}  border rounded-full p-1 flex items-center justify-center cursor-pointer `}
      onClick={() => { onClick() }}
    >
      {returnUserIcon(`${className}`)}
    </div>
  ) : (
    <div
      className="cursor-pointer"
    >
        <img
          className={` border ${className}} rounded-full`}
          src={profileImageCroppedUrl}
          alt="avatar"
          onClick={() => { onClick() }}
        />
    </div>
  )
};

export default Avatar;