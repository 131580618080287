import { useState } from 'react';
import { useStore } from '../store/store';
import ProfileImageModal from './ProfileImageModal';

const ProfilePhotoTab = ({incrementTab}) => {

  const account = useStore(state => state.account);
  const profileImageCroppedUrl = account?.profileImageCroppedUrl;
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full">
      <div className="font-semibold text-3xl">Profile Image</div>
      <div className="divide-y my-2 border-t border-gray-300" />

        <div className="mt-2 space-y-4">

        {!profileImageCroppedUrl ? '' : <img
          src={profileImageCroppedUrl}
          alt="Avatar"
          className="h-52 w-52 border rounded"
        />}
        <ProfileImageModal open={open} setOpen={setOpen} />
        <div className="w-52">
        <button
          type="button"
          className="border border-gray-300 rounded p-2 my-2 text-sm bg-white hover:bg-gray-50"
          onClick={() => setOpen(true)}
        >
          Upload photo
        </button>
        </div>
      </div>

      {/* <div className="w-96 space-y-1 mt-4">
        <button
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => { incrementTab() }}
        >
          <span>Next</span>
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">{returnChevronRight('w-4 h-6')}</span>          
        </button>
      </div> */}

    </div>
  );
};



export default ProfilePhotoTab;