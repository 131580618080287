import { useState } from 'react';
import { useStore } from '../store/store';
import { returnSpinnerIcon, exclamationIcon, returnCheckCircleIcon, returnCreditCardIcon, returnChevronRight } from "../helpers/icons";
import { trackEvent } from '../dependencies/mixpanel'
import { beginStripeBilling, beginStripeCheckout } from '../actions/stripe';

const BillingTab = () => {

  const user = useStore(state => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const buttonSucceeded = false;
  const account = useStore(state => state.account);
  // const userIsSubscribed = account?.subscriptions?.length > 0
  const userIsSubcribedBeyondTrial = account?.subscriptions?.filter((elem) => elem !== 'trial')?.length > 0


  const handleClick = () => {
  setButtonLoading(true);
    beginStripeBilling({ user }).then(({ sessionUrl }) => {
      window.open(sessionUrl, "_self");
      setButtonLoading(false);
      setErrorMessage('');
      trackEvent({name: 'Stripe billing session created'});
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Stripe billing session could not be created.');
      setButtonLoading(false);
    })
  }

  const handleBeginCheckout = () => {
    setButtonLoading(true);
    beginStripeCheckout({ user }).then(({ sessionUrl }) => {
      window.open(sessionUrl, "_self");
      setButtonLoading(false);
      trackEvent({name: 'Stripe checkout session created'})
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Stripe checkout session could not be initiated.');
      setButtonLoading(false);
    })
  }

  



  return (
    <div className="w-full">
      <div className="font-semibold text-3xl">Billing</div>
      <div className="divide-y my-2 border-t border-gray-300" />

        <div className="mt-2 space-y-4">

        {userIsSubcribedBeyondTrial && <div className="w-72">
          <button
            className="group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-sm rounded  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={() => { handleClick() }}
            disabled={buttonLoading}
          >
            {buttonLoading ? returnSpinnerIcon('w-5 h-5 text-gray-500') :
              (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                {returnCreditCardIcon()}
              </span>)
            }
            {buttonLoading || buttonSucceeded ? '' : 'View your billing information'}
            {buttonLoading || !buttonSucceeded ? '' : <span
            >{returnCheckCircleIcon('w-5 h-5')}</span>}
          </button>
        </div>}

        {!userIsSubcribedBeyondTrial && <div className="w-72">
          <button
            className="group relative w-full flex justify-center py-2 px-4 border text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 border-gray-300 text-sm rounded  focus:outline-none focus:ring-2 focus:ring-offset-2 "
            onClick={() => { handleBeginCheckout() }}
            disabled={buttonLoading}
          >
            {buttonLoading ? returnSpinnerIcon('w-5 h-5 text-white') :
              (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                {returnChevronRight()}
              </span>)
            }
            {buttonLoading || buttonSucceeded ? '' : 'Subscribe'}
            {buttonLoading || !buttonSucceeded ? '' : <span
            >{returnCheckCircleIcon('w-5 h-5')}</span>}
          </button>
        </div>}
        
      </div>
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

    </div>
  );
};


export default BillingTab;