import { useState, useRef, useEffect } from 'react'
import { returnMagnifyingGlassIcon, exclamationIcon, returnCheckCircleIcon } from '../helpers/icons';
import { useDebounceEffect } from '../helpers/useDebounceEffect';
import { useKeyPress } from '../helpers/useKeyPress';
import { useNavigate } from 'react-router-dom';
import { returnViewTopicWithPublishedArticlePath, returnRequestTopicPath } from '../helpers/urls';
import { typesenseClient } from '../dependencies/typesenseClient';
import { returnRequestNewText, returnSearchText } from '../helpers/data';
import { returnMetaKeyDescription } from '../helpers/utilities';
import { captureAndFormatException } from '../dependencies/sentry';

const SearchForTopic = ({ showEsc, closeCallback, inputRef, showCmdK }) => {

  const [query, setQuery] = useState('');
  const maxResults = 10;
  const [results, setResults] = useState([]);
  const resultsRef = useRef(results);
  useEffect(() => {
    resultsRef.current = results;
  }, [results]);
  // let maxIndex = resultsRef?.current?.slice(0, maxResults)?.length || 0; // Use - 1 if there is no create option.
  // const maxIndexRef = useRef(maxIndex);
  // useEffect(() => {
  //   maxIndexRef.current = maxIndex;
  // }, [maxIndex]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  const navigate = useNavigate();
  const returnMaxIndex = () => resultsRef?.current?.slice(0, maxResults)?.length || 0; // Use - 1 if there is no create option.
  

  const incrementSelectedResultIndex = () => {
    setSelectedResultIndex(state => {
      return state === returnMaxIndex().current ? 0 : state + 1;
    });
  }

  const decrementSelectedResultIndex = () => {
    setSelectedResultIndex(state => {
      return state === 0 ? returnMaxIndex().current : state - 1;
    });
  }

  useKeyPress({
    key: "ArrowDown",
    onKeyDown: () => {incrementSelectedResultIndex()},
  })

  useKeyPress({
    key: "ArrowUp",
    onKeyDown: () => {decrementSelectedResultIndex()},
  })

  useKeyPress({
    key: "Escape",
    onKeyDown: () => { setQuery('') },
  })

  useKeyPress({
    key: "Enter",
    onKeyDown: () => { setIncrementHandleClick(state => state + 1) },
  })
  
  const handleSearch = async () => {
    // if (!query) return console.log('Cannot search.  No query');
    if (!query) return setResults([]);

    let searchParameters = {
      'q': query,
      // 'query_by': ['title', 'code', 'topicId'],
      'query_by' : 'title',
      'filter_by' : 'published:true',
      'limit_hits': maxResults,
    }

    await typesenseClient.collections('topics').documents().search(searchParameters).then((result) => {
      const results = result.hits.map(
        (hit) => hit?.document
      ).sort(
        (a, b) => a.title.localeCompare(b.title)
      );
      setResults(results);
      setErrorMessage('');
    }).catch((error) => {
      captureAndFormatException(error);
      console.error({error})
      setErrorMessage('Could not search for topics.');
    })
  }

  useDebounceEffect(
    handleSearch,
    200,
    [query]
  )

  const handleClick = () => {
    const requestNewTopic = selectedResultIndex === results.slice(0, maxResults).length
    if (query.length === 0) {
      console.log('No query');  
    } else if (requestNewTopic) {
      navigate(returnRequestTopicPath());
      if (!!closeCallback) closeCallback();
    } else {
      let topicId = results[selectedResultIndex]?.topicId;
      let path = returnViewTopicWithPublishedArticlePath({ topicId })
      navigate(path);
      if (!!closeCallback) closeCallback();
    }
  }

  const [incrementHandleClick, setIncrementHandleClick] = useState(0);
  useEffect(() => {
    if (incrementHandleClick > 0) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incrementHandleClick])



  return (
    <div className="w-full justify-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle max-w-lg sm:w-full border border-gray-300 ">
      <div className="flex justify-between items-center w-full">
        <div className="flex justify-start items-center px-4 w-full">
          <div className="text-gray-400 ">{returnMagnifyingGlassIcon('h-5 w-5')}</div>
          <input
            className="bg-white px-2 py-4 w-full outline-none"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={`${returnSearchText()}`}
            ref={inputRef}
          />
        </div>

        {!showEsc ? '' : <div className="mx-4 text-gray-400 border p-1 rounded text-xs">Esc</div>}
        {!showCmdK ? '' : <div className="mx-4 text-gray-400 border p-1 rounded text-xs">{returnMetaKeyDescription()} + K</div>}
      </div>

      <div className="flex flex-col">
        {results.slice(0, maxResults).map((result, index) => {
          return (
            <button
              className={`${selectedResultIndex === index ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-blue-100'} border-t p-2  text-left flex items-start justify-between }`}
              key={index}
              onClick={handleClick}
            >
              <div className="px-2">
                <div className="text-sm text-gray-700">{result.title}</div>
                <div className="text-xs text-gray-500">{result.code}</div>
              </div>
              <div className="text-blue-500 text-xs">{!result?.approved ? '' : (<div className="flex space-x-1 items-center">{returnCheckCircleIcon('h-4 w-4')}<span>Editor approved</span></div>)}</div>
            </button>
          )
        })}
        {query.length === 0 ? '' : <button
          className={`${selectedResultIndex === results.slice(0, maxResults).length ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-blue-100'} border-t p-2  text-left }`}
          onClick={handleClick}
        >
          <div className="text-sm text-gray-700 px-2">{returnRequestNewText()}</div>
          <div className="text-xs text-gray-500 invisible">invisible text</div>
        </button>}
      </div>

      <div>
        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
    </div>
  )
}

export default SearchForTopic;