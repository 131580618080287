import { spinnerIcon, lockIcon, exclamationIcon } from "../helpers/icons";
import { useState } from 'react';
import {auth} from '../firebase/firebase';
import { useStore } from '../store/store';
import {returnSignUpPath, returnPathAfterSignIn} from '../helpers/urls';
import {validateEmail} from '../helpers/validation';
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { trackEvent } from '../dependencies/mixpanel';
import { convertFirebaseErrorToHumanReadable } from '../helpers/errorHandling';

const SignIn = () => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const setUser = useStore(state => state.setUser);
  const signupPath = returnSignUpPath();
  const navigate = useNavigate();


  const validateSignInForm = () => {
    let validated = false
    if (password.length < 8) {
      setErrorMessage('Your password must be at least 8 characters');
    } else if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = () => {
    if (!validateSignInForm()) return;
    setButtonLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user);
        setErrorMessage('');
        trackEvent({name: 'User signed in with email and password'})
        navigate(returnPathAfterSignIn());
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;
        console.log(errorCode, errorMessage)
        errorMessage = convertFirebaseErrorToHumanReadable(error)
        setErrorMessage(errorMessage);
        setButtonLoading(false);
      });
  }

  

  return (
    <div className="flex flex-col min-h-screen-adj bg-gray-100 py-24 px-4 sm:px-6 lg:px-8 items-center">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className ="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
          </h2>
          <p className ="mt-2 text-center text-sm text-gray-600">
          {`Are you a new user? `}
          <span onClick={() => {navigate(signupPath)}} className ="cursor-pointer font-medium text-blue-600 hover:text-blue-500">
          Create your account
          </span>.
          </p>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Email address"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={buttonLoading}
              onClick={(e) => { handleClick(e.target.value) }}
            >
              {buttonLoading ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {lockIcon}
                </span>)
              }
              {buttonLoading ? '' : 'Sign in'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </form>
      </div>
    </div>
  )
}

export default SignIn;