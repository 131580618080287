import BasicCodeBlock from './BasicCodeBlock';

export const AdminViewArticleDetails = ({
  selectedArticle,
}) => {

  const promptLength = prompt.length;
  const charsPerToken = 4;
  const tokensInPrompt = Math.ceil(promptLength / charsPerToken);

  return (
    <div className="w-full flex flex-col space-y-4">

    {/* Title */}
    <div className="flex items-end justify-between">
        <div className="font-semibold text-3xl">View article details {selectedArticle?.articleId}</div>
    </div>

      {/* Length */}
      <div>
        <label className="text-sm font-semibold">Length</label>
        <div className="text-gray-700 text-sm">{promptLength} characters in prompt.</div>
        <div className="text-gray-700 text-sm">{tokensInPrompt} tokens in prompt.</div>
      </div>


      {/* Formatted request */}
      <div className="flex flex-col space-y-2">
        <div className="text-sm text-gray-700 font-semibold">Request Body</div>
        <BasicCodeBlock
          code={JSON.stringify(selectedArticle?.requestBody, null, 2)}
          setCode={() => { console.log('Code block is read only.') }}
        />
      </div>

      {/* Response */}
      <div className="flex flex-col space-y-2">
        <div className="text-sm text-gray-700 font-semibold">Response</div>
        <BasicCodeBlock
          code={JSON.stringify(selectedArticle, null, 2)}
          setCode={() => { console.log('Code block is read only.') }}
        />
      </div>

    </div>
  )
}

export default AdminViewArticleDetails;