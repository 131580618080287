import { useNavigate } from "react-router-dom";
import { returnHomePath, returnBlackLogoPath, returnSignInPath, contactUrl } from "../helpers/urls";
import { appName } from "../helpers/data";

const FallbackPage = ({message="Page not found", subMessage="", loginButton=false}) => {

  const navigate = useNavigate();
  
  return (
    <div className="min-h-screen-adj flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">

      <div className="max-w-md w-full space-y-8 text-center">
        <div className="flex justify-center">
          <img src={returnBlackLogoPath()} className="w-10 h-10" alt={appName} />
          <span className="text-4xl p-0.5 font-cuprum">{appName}</span>
        </div>
        <div className="flex flex-col space-y-2">
          <h1 className="text-xl text-gray-500">{message}</h1>
          <p className="text-gray-500 text-sm">{subMessage}</p>
        </div>

        {/* <p className="text-gray-700">Please check the URL in the address bar and try again.</p> */}

        <div className="flex space-x-4 justify-center">
          {!loginButton ? (
            <button
              className="bg-blue-500 rounded-lg hover:bg-blue-600 font-semibold text-white p-3"
              onClick={() => { navigate(returnHomePath()) }}
            >
              Go back home
            </button>
          ) : (
            <button
              className="bg-blue-500 rounded-lg hover:bg-blue-600 font-semibold text-white p-3"
              onClick={() => { navigate(returnSignInPath()) }}
            >
              Log in
            </button>
          )}
          <button
            className="border border-gray-500 rounded-lg hover:bg-gray-100 font-semibold text-gray-500 p-3"
            onClick={() => { window.open(contactUrl, "__blank") }}
          >
            Contact us
          </button>

        </div>

      </div>
    </div>
  )
}

export default FallbackPage;