import { captureAndFormatException } from '../dependencies/sentry';

const dayInMilliseconds = 1000 * 60 * 60 * 24; // 1000 milliseconds * 60 seconds * 60 minutes * 24 hours

export const roundMillisecondsToNearestDay = (milliseconds) => {
  return Math.round(milliseconds / dayInMilliseconds)
}

export const returnDaysLeftInTrial = ({dateOfTrialExpiration}) => {
  let result = (dateOfTrialExpiration - Date.now()) / dayInMilliseconds;
  return Math.round(result)
}

export const convertStringToReadingTime = ({str}) => {
  const numWords = str.split(" ").length;
  const wordsPerMinute = 400;
  const readingMin = numWords / wordsPerMinute;
  const msPerMin = 1000 * 60;
  const readingMs = readingMin * msPerMin;
  return readingMs;
}

export const replaceTemplateLiterals = (expression, valueObj) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  let text = expression.replace(templateMatcher, (substring, value, index) => {
    value = valueObj[value];
    return value;
  });
  return text
}

export const readStream = ({ reader, handleText, handleEnd, handleError }) => {
  reader.read().then(({ done, value }) => {
    if (done) {
      handleEnd();
    } else {
      const decoder = new TextDecoder();
      let str = decoder.decode(value);
      handleText(str);
      readStream({ reader, handleText, handleEnd });
    }
  }).catch((error) => {
    captureAndFormatException(error);
    handleError(error);
  });
} 

export const generateAnchorSlugForReactMarkdown = (string) => {
  let str = string.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return str;
};

export const extractH2 = (markdown) => {
  return markdown
    .split('\n')                                   // Split by lines
    .filter(line => line.trim().startsWith('##'))  // Filter lines that start with ##
    .map(line => line.trim().substring(3).trim()); // Remove the ## and extra spaces
};

export const removeTrailingColon = (string) => {
  return string.slice(-1) === ':' ? string.slice(0, -1) : string;
}

export const returnMetaKeyDescription = () => {
  return navigator?.userAgent?.includes('Mac') ? 'Cmd' : '⊞';
}

export const returnMetaKeyShort = () => {
  return navigator?.userAgent?.includes('Mac') ? '⌘' : '⊞';
}