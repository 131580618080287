import Typesense from 'typesense';
import { returnTypesenseHostAndApiKey } from '../config/config';

console.log({returnTypesenseHostAndApiKey: returnTypesenseHostAndApiKey()})

export const typesenseClient = new Typesense.Client({
  nodes: [{
    host: returnTypesenseHostAndApiKey().host, // For Typesense Cloud use xxx.a1.typesense.net
    port: '443',      // For Typesense Cloud use 443
    protocol: 'https'   // For Typesense Cloud use https
  }],
  apiKey: returnTypesenseHostAndApiKey().apiKey, // Typesense search only api key.  Swap it later
  connectionTimeoutSeconds: 5
})