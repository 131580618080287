import Hotjar from '@hotjar/browser';
import { returnHotjarSiteId } from '../config/config';

const siteId = returnHotjarSiteId();
const hotjarVersion = 6;

if (siteId) {
  console.log({ siteId })
  Hotjar.init(siteId, hotjarVersion);
}

export { Hotjar };