import {useState, useEffect} from 'react';
import { returnPlusIcon, returnCheckCircleIcon, exclamationIcon } from "../helpers/icons";
import AdminFilter from './AdminFilter';
import AdminSort from './AdminSort';


const AdminQueryBuilder = ({
  handleRetrieveData,
  filters,
  setFilters,
  sorts,
  setSorts,
}) => {


  const handleAddFilter = () => {
    setFilters([...filters, {
      field: '',
      operand: '',
      value: '',
    }])
  }

  const handleRemoveFilter = (index) => {
    const updatedFilters = filters.filter((filter, i) => i !== index);
    setFilters(updatedFilters);
  }

  const handleAddSort = () => { 
    if (sorts.length > 0) return console.log('Cannot add more than one sort.');
    setSorts([...sorts, {
      field: '',
      direction: '',
    }])
  }

  const handleRemoveSort = () => {
    setSorts([]);
  }

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonSucceeded, setButtonSucceeded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateQuery = () => {
    let errors = [];
    filters?.forEach(({field, operand, value}, index) => {
      if (!field || typeof field !== 'string' ) {
        errors.push(`Filter ${index + 1}: Field is not valid.`)
      } else if (!operand || typeof operand !== 'string') {
        errors.push(`Filter ${index + 1}: Operand is not valid.`)
      } else if (value === null || value === undefined || typeof value !== 'boolean') {
        errors.push(`Filter ${index + 1}: Value is not valid.`)
      }
    });
    sorts?.forEach(({field, direction}, index) => {
      if (!field || typeof field !== 'string' ) {
        errors.push(`Sort ${index + 1}: Field is not valid.`)
      } else if (!direction || typeof direction !== 'string') {
        errors.push(`Sort ${index + 1}: Direction is not valid.`);
      }
    });
    setErrorMessage(errors.length === 0 ? '' : JSON.stringify(errors));
    return errors.length === 0;
  }

  const handleClick = () => {
    if (!validateQuery()) return console.log('Errors in query.');
    setButtonLoading(true);
    handleRetrieveData().then(() => {
      setButtonLoading(false);
      setButtonSucceeded(true);
    }).catch((error) => {
      setButtonLoading(false);
    })
  }

  useEffect(() => {
    if (!buttonSucceeded) return;
    setTimeout(() => {
      setButtonSucceeded(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSucceeded]);


  return (
    <div>
      
      <div className="flex flex-col space-y-2">

        {/* Filters */}
        <div className="text-sm font-semibold mt-2">Filters</div>
        {filters.map((filter, index) => {
          return (
            <AdminFilter
              key={index}
              filters={filters}
              setFilters={setFilters}
              handleAddFilter={handleAddFilter}
              handleRemoveFilter={handleRemoveFilter}
              index={index}
            />
          )
        })}

        {/* Add filter button */}
        <div className="">
          <button
            className="p-2 rounded bg-blue-200 hover:bg-blue-300 flex space-x-2 items-center border border-gray-500 text-xs text-gray-700"
            onClick={() => { handleAddFilter() }}
          >
            {returnPlusIcon('h-4 w-4')}
            <span>Add filter</span>
          </button>
        </div>

        {/* Sort */}
        <div className="text-sm font-semibold mt-2">Sort</div>
        {sorts.map((sort, index) => {
          return (
            <AdminSort
              key={index}
              sorts={sorts}
              setSorts={setSorts}
              handleAddSort={handleAddSort}
              handleRemoveSort={handleRemoveSort}
              index={index}
            />
          )
        })}

        {/* Add sort button */}
        {sorts.length > 0 ? '' : <div className="">
          <button
            className="p-2 rounded bg-violet-300 hover:bg-violet-300 flex space-x-2 items-center border border-gray-500 text-xs text-gray-700"
            onClick={() => { handleAddSort() }}
          >
            {returnPlusIcon('h-4 w-4')}
            <span>Add sort</span>
          </button>
        </div>}

        {/* Apply filters */}
        <div className="">
          <button
            type="submit"
            className="group w-24 flex justify-center py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => { handleClick() }}
            disabled={buttonLoading}
          >
            {buttonLoading || buttonSucceeded ? '' : 'Apply'}
            {buttonLoading || !buttonSucceeded ? '' : <span className="flex items-center"
            >{returnCheckCircleIcon('w-5 h-5')}</span>}
          </button>
        </div>


      </div>
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

    </div>
  );
};

export default AdminQueryBuilder;