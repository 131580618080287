export const navbarHeight = 12;

export const appName = 'Skillite';

export const returnH1 = () => `The world's best case briefs`;

export const returnH2 = () => `Make the most of your study time.`;

export const returnSearchText = () => 'Search for a case...';

export const returnRequestNewText = () => 'Or request a new case brief...';


export const articleOptions = [
  { "title": "Marbury v. Madison", "code": "5 U.S. 137 (1803)", "subject": ["constitutional law"] },
  { "title": "Brown v. Board of Education", "code": "347 U.S. 483 (1954)", "subject": ["constitutional law", "civil rights"] },
  { "title": "Roe v. Wade", "code": "410 U.S. 113 (1973)", "subject": ["constitutional law"] },
  { "title": "Miranda v. Arizona", "code": "384 U.S. 436 (1966)", "subject": ["criminal procedure"] },
  { "title": "Plessy v. Ferguson", "code": "163 U.S. 537 (1896)", "subject": ["constitutional law"] },
  { "title": "Gideon v. Wainwright", "code": "372 U.S. 335 (1963)", "subject": ["criminal procedure"] },
  { "title": "United States v. Nixon", "code": "418 U.S. 683 (1974)", "subject": ["constitutional law"] },
  { "title": "New York Times Co. v. Sullivan", "code": "376 U.S. 254 (1964)", "subject": ["constitutional law"] },
  { "title": "United States v. Microsoft Corp.", "code": "253 F.3d 34 (D.C. Cir. 2001)", "subject": ["administrative law"] },
  { "title": "Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc.", "code": "467 U.S. 837 (1984)", "subject": ["administrative law"] },
  { "title": "Bush v. Gore", "code": "531 U.S. 98 (2000)", "subject": ["constitutional law"] },
  { "title": "Lawrence v. Texas", "code": "539 U.S. 558 (2003)", "subject": ["constitutional law"] },
  { "title": "Mapp v. Ohio", "code": "367 U.S. 643 (1961)", "subject": ["criminal procedure"] },
  { "title": "Texas v. Johnson", "code": "491 U.S. 397 (1989)", "subject": ["constitutional law"] },
  { "title": "Griswold v. Connecticut", "code": "381 U.S. 479 (1965)", "subject": ["constitutional law"] },
  { "title": "Tinker v. Des Moines Independent Community School District", "code": "393 U.S. 503 (1969)", "subject": ["constitutional law"] },
  { "title": "Hazelwood School District v. Kuhlmeier", "code": "484 U.S. 260 (1988)", "subject": ["constitutional law"] },
  { "title": "Dred Scott v. Sandford", "code": "60 U.S. 393 (1857)", "subject": ["constitutional law"] },
  { "title": "Korematsu v. United States", "code": "323 U.S. 214 (1944)", "subject": ["constitutional law"] },
  { "title": "Grutter v. Bollinger", "code": "539 U.S. 306 (2003)", "subject": ["constitutional law"] },
  { "title": "Furman v. Georgia", "code": "408 U.S. 238 (1972)", "subject": ["criminal law"] },
  { "title": "Gregg v. Georgia", "code": "428 U.S. 153 (1976)", "subject": ["criminal law"] },
  { "title": "Hamdi v. Rumsfeld", "code": "542 U.S. 507 (2004)", "subject": ["constitutional law"] },
  { "title": "Hamdan v. Rumsfeld", "code": "548 U.S. 557 (2006)", "subject": ["constitutional law"] },
  { "title": "Roper v. Simmons", "code": "543 U.S. 551 (2005)", "subject": ["constitutional law"] },
  { "title": "Atkins v. Virginia", "code": "536 U.S. 304 (2002)", "subject": ["constitutional law"] },
  { "title": "District of Columbia v. Heller", "code": "554 U.S. 570 (2008)", "subject": ["constitutional law"] },
  { "title": "McDonald v. Chicago", "code": "561 U.S. 742 (2010)", "subject": ["constitutional law"] },
  { "title": "Citizens United v. FEC", "code": "558 U.S. 310 (2010)", "subject": ["constitutional law"] },
  { "title": "Shelby County v. Holder", "code": "570 U.S. 529 (2013)", "subject": ["constitutional law"] },
  { "title": "Obergefell v. Hodges", "code": "576 U.S. 644 (2015)", "subject": ["constitutional law"] },
  { "title": "Whole Woman's Health v. Hellerstedt", "code": "579 U.S. 15 (2016)", "subject": ["constitutional law"] },
  { "title": "Burwell v. Hobby Lobby Stores, Inc.", "code": "573 U.S. 682 (2014)", "subject": ["constitutional law"] },
  { "title": "Loving v. Virginia", "code": "388 U.S. 1 (1967)", "subject": ["constitutional law"] },
  { "title": "United States v. Windsor", "code": "570 U.S. 744 (2013)", "subject": ["constitutional law"] },
  { "title": "Terry v. Ohio", "code": "392 U.S. 1 (1968)", "subject": ["criminal procedure"] },
  { "title": "Garcia v. San Antonio Metropolitan Transit Authority", "code": "469 U.S. 528 (1985)", "subject": ["constitutional law"] },
  { "title": "Baker v. Carr", "code": "369 U.S. 186 (1962)", "subject": ["constitutional law"] },
  { "title": "West Virginia State Board of Education v. Barnette", "code": "319 U.S. 624 (1943)", "subject": ["constitutional law"] },
  { "title": "Reynolds v. Sims", "code": "377 U.S. 533 (1964)", "subject": ["constitutional law"] },
  { "title": "Katz v. United States", "code": "389 U.S. 347 (1967)", "subject": ["criminal procedure"] },
  { "title": "Chisholm v. Georgia", "code": "2 U.S. 419 (1793)", "subject": ["constitutional law"] },
  { "title": "Buckley v. Valeo", "code": "424 U.S. 1 (1976)", "subject": ["constitutional law"] },
  { "title": "McCulloch v. Maryland", "code": "17 U.S. 316 (1819)", "subject": ["constitutional law"] },
  { "title": "United States v. Lopez", "code": "514 U.S. 549 (1995)", "subject": ["constitutional law"] },
  { "title": "United States v. Morrison", "code": "529 U.S. 598 (2000)", "subject": ["constitutional law"] },
  { "title": "Gonzales v. Raich", "code": "545 U.S. 1 (2005)", "subject": ["constitutional law"] },
  { "title": "Lemon v. Kurtzman", "code": "403 U.S. 602 (1971)", "subject": ["constitutional law"] },
  { "title": "Employment Division v. Smith", "code": "494 U.S. 872 (1990)", "subject": ["constitutional law"] },
  { "title": "United States v. Booker", "code": "543 U.S. 220 (2005)", "subject": ["constitutional law"] },
  { "title": "Hammer v. Dagenhart", "code": "247 U.S. 251 (1918)", "subject": ["constitutional law"] },
  { "title": "Wickard v. Filburn", "code": "317 U.S. 111 (1942)", "subject": ["constitutional law"] },
  { "title": "Brown v. Entertainment Merchants Association", "code": "564 U.S. 786 (2011)", "subject": ["constitutional law"] },
  { "title": "Diamond v. Chakrabarty", "code": "447 U.S. 303 (1980)", "subject": ["patent law"] },
  { "title": "Perry v. Brown", "code": "671 F.3d 1052 (9th Cir. 2012)", "subject": ["constitutional law"] },
  { "title": "Bowers v. Hardwick", "code": "478 U.S. 186 (1986)", "subject": ["constitutional law"] },
  { "title": "Boumediene v. Bush", "code": "553 U.S. 723 (2008)", "subject": ["constitutional law"] },
  { "title": "Ledbetter v. Goodyear Tire & Rubber Co.", "code": "550 U.S. 618 (2007)", "subject": ["employment law"] },
  { "title": "National Federation of Independent Business v. Sebelius", "code": "567 U.S. 519 (2012)", "subject": ["constitutional law"] },
  { "title": "Smith v. Maryland", "code": "442 U.S. 735 (1979)", "subject": ["constitutional law"] },
  { "title": "Hampton v. United States", "code": "425 U.S. 484 (1976)", "subject": ["constitutional law"] },
  { "title": "Riley v. California", "code": "573 U.S. 373 (2014)", "subject": ["criminal procedure"] },
  { "title": "United States v. Leon", "code": "468 U.S. 897 (1984)", "subject": ["criminal procedure"] },
  { "title": "Lawrence v. Texas", "code": "539 U.S. 558 (2003)", "subject": ["criminal procedure"] },
  { "title": "Kiobel v. Royal Dutch Petroleum Co.", "code": "569 U.S. 108 (2013)", "subject": ["constitutional law"] },
  { "title": "Graham v. Florida", "code": "560 U.S. 48 (2010)", "subject": ["constitutional law"] },
  { "title": "Snyder v. Phelps", "code": "562 U.S. 443 (2011)", "subject": ["constitutional law"] },
  { "title": "Granholm v. Heald", "code": "544 U.S. 460 (2005)", "subject": ["constitutional law"] },
  { "title": "Safford Unified School District v. Redding", "code": "557 U.S. 364 (2009)", "subject": ["constitutional law"] },
  { "title": "National Labor Relations Board v. Noel Canning", "code": "573 U.S. 513 (2014)", "subject": ["constitutional law"] },
  { "title": "Morrison v. Olson", "code": "487 U.S. 654 (1988)", "subject": ["constitutional law"] },
  { "title": "Florida v. Jardines", "code": "569 U.S. 1 (2013)", "subject": ["criminal procedure"] },
  { "title": "United States v. Jones", "code": "565 U.S. 400 (2012)", "subject": ["criminal procedure"] },
  { "title": "Myriad Genetics, Inc. v. Association for Molecular Pathology", "code": "569 U.S. 576 (2013)", "subject": ["patent law"] },
  { "title": "Gonzales v. Carhart", "code": "550 U.S. 124 (2007)", "subject": ["constitutional law"] },
  { "title": "Miller v. California", "code": "413 U.S. 15 (1973)", "subject": ["constitutional law"] },
  { "title": "Monsanto Co. v. Geertson Seed Farms", "code": "561 U.S. 139 (2010)", "subject": ["administrative law"] },
  { "title": "Massachusetts v. Environmental Protection Agency", "code": "549 U.S. 497 (2007)", "subject": ["administrative law"] },
  { "title": "United States v. Comstock", "code": "560 U.S. 126 (2010)", "subject": ["constitutional law"] },
  { "title": "Shelby County v. Holder", "code": "570 U.S. 529 (2013)", "subject": ["constitutional law"] }
];


export const lawQuotes = [
  { quote: "Injustice anywhere is a threat to justice everywhere.", author: "Martin Luther King Jr.", famousFor: "Civil Rights Activist" },
  // { quote: "Justice delayed is justice denied.", author: "William E. Gladstone", famousFor: "British Prime Minister" },
  { quote: "Laws are like sausages, it is better not to see them being made.", author: "Otto von Bismarck", famousFor: "German Statesman" },
  { quote: "It may be true that the law cannot make a man love me, but it can keep him from lynching me, and I think that's pretty important.", author: "Martin Luther King Jr.", famousFor: "Civil Rights Activist" },
  { quote: "Lawyers are the only persons in whom ignorance of the law is not punished.", author: "Jeremy Bentham", famousFor: "Philosopher and Social Reformer" },
  { quote: "The more laws, the less justice.", author: "Marcus Tullius Cicero", famousFor: "Roman Philosopher" },
  { quote: "Good people do not need laws to tell them to act responsibly, while bad people will find a way around the laws.", author: "Plato", famousFor: "Philosopher" },
  { quote: "I busted a mirror and got seven years bad luck, but my lawyer thinks he can get me five.", author: "Steven Wright", famousFor: "Stand-up Comedian" },
  { quote: "Where there's a will, there's a lawyer.", author: "Robert Elliott Gonzales", famousFor: "Poet" },
  { quote: "If there weren't any lawyers, we wouldn't need them.", author: "Mark Twain", famousFor: "Author and Humorist" },
  { quote: "The only difference between a tax man and a taxidermist is that the taxidermist leaves the skin.", author: "Mark Twain", famousFor: "Author and Humorist" },
  { quote: "I was going to sue for defamation of character, but then I realized I have no character.", author: "Charles Barkley", famousFor: "NBA Basketball Player" },
  { quote: "A lawyer with a briefcase can steal more than a thousand men with guns.", author: "Mario Puzo", famousFor: "Author" },
  { quote: "The problem with political jokes is they get elected.", author: "Henry Cate VII", famousFor: "Author" },
  { quote: "If you steal from one author it's plagiarism; if you steal from many it's research.", author: "Wilson Mizner", famousFor: "Playwright" },
  { quote: "Always borrow money from a pessimist. He won’t expect it back.", author: "Oscar Wilde", famousFor: "Poet and Playwright" },
  { quote: "Before I refuse to take your questions, I have an opening statement.", author: "Ronald Reagan", famousFor: "40th U.S. President" },
  // { quote: "The reports of my death are greatly exaggerated.", author: "Mark Twain", famousFor: "Author and Humorist" }
];

// export const defaultConversationCompletionPromptString = `{
//   "model": "gpt-4",
//   "messages": [
//     {
//       "role": "system", 
//       "content": "You are a helpful assistant."
//     }, 
//     {
//       "role": "user", 
//       "content": "Hello world."
//     }
//   ],
//   "temperature": 1.0,
// }` 

export const returnSamplePromptContent = ({topicName}) => 
`You are an A+ law student.  Write a case brief for this case: ${topicName ? topicName : ''}.

Write an extremely lengthy and detailed response.  Your response should have between 1800 and 2500 words.  Include the following sections: 
- Case name and citation
- Decided by (include the name of the court and the judge who wrote the majority opinion) 
- Summary (a 2-3 sentence summary explaining the main proposition established by the case.)
- Procedural posture 
- Facts 
- Issues (provide between 1 and 5 issues) 
- Rules cited (These should not be the rules established by the case. Instead, they should be the main rules from  cases or statutes that were cited in the majority opinion.  If prior cases or statutes were named, state the name of the case or statute (with citation) and describe the facts and the rule established by that case.  Very importantly, if the prior rules were quoted, provide the language that was quoted.  If prior statutes or cases were not relied on, instead describe the general principles that formed the basis for this decision.) 
- Holding 
- Reasoning of the majority opinion 
- Reasoning of the minority opinion 
- Memorable quotes
- Impact and significance  (On a scale of 1/10, how influential is the holding of this case?  Format your answer by saying: “X/10 in importance.”  Then, explain how the holding of the case impacted future cases. Provide the three most influential future cases that relied on this case as precedent. For each of those future cases, state the name of the case, the citation, the court, the judge, the facts, and the rule.)
- Mnemonic devices (
  Provide a list of 10 mnemonic devices to help students remember the main holding of this case. Good mnemonic devices include the following… 
* Imagine that a famous movie scene to be about this case.  What does the actor say or do?  Provide a vivid description.
* Imagine that a famous song is about this case.  Whats the song, and provide a quote.
* What would a famous celebrity say about this case?
* An acronym 
* Rhymes
* Alliteration 
* What would a famous historical figure do or say about this case?
* A memorable phrase that sounds like the case name or relates to the case name.
)
- Hypotheticals (What are 3 hypothetical fact patterns that a law professor might use to analogize to this case or distinguish from it? After each one, state the issue. Then state how it should be resolved and give your reasoning. Reference the current case in your reasoning. Include at least one fact pattern where, it's not clear how the case should be resolved.  For those, present arguments on both sides. )
- Practice questions (You are the most challenging a law professor at the country's most elite law school who wants to test students’ understanding of the case. Ask 10 questions about the case. After each question, write the answer. Your questions should be difficult.  They should should require students to understand the arguments presented in the case, consider counterarguments, apply the case to new fact patterns, distinguish the case from other cases, consider the impact of the case, and understand procedural nuances.)

Further instructions:
- Whenever you reference an argument made, state which judge made the argument. For example, instead of saying, “The minority argued…”, state which judge in the minority wrote the argument. 
- Whenever you reference an argument made, summarize the argument and then provide the quote from the text of the case. 
- For both the reasoning of the majority opinion and reasoning of the minority opinion, provide a lengthy and detailed response. If the opinion cites statutes or precedent, state which statutes or precedent was cited. Then state what the opinion said about the precedent. If the opinion considered counterarguments, state all of the counterarguments (every single one of the counterarguments in the opinion), who made those counterarguments (e.g., one of the parties to the case, one of the dissenting judges), state those counterarguments, and state the opinion’s response. If the opinion used multiple lines of reasoning, explain each line of reasoning. If the opinion cited memorable quotes from statutes or prior cases, state those quotes, and then state what the judge said about those quotes. State the top 3-5 quotes from the judge that are most cited in future opinions. 
- Format the response in markdown.  Begin with:
# {case name}
{{ citation }} 
Subsequent section headings should begin with ##.
All other text should be in standard paragraph size (without any #).
For questions and answers and hypotheticals, format text as:
1. **Q:**: …
**A:**…

2. **Q:**:…
**A:** 
- Print the Question (with **Q:**) and the Answer (with **A**) on separate lines.
- Do not provide any “meta” text that is not a response to this question. For example, do not say, “Given the length limitation, it is important to delve deeper into each of these sections for a more in-depth analysis.”
- Do not write an introduction before the case brief or a conclusion after it.  For example, do not write, “Certainly! Here is a comprehensive case brief for your case.”
- If you don't know the answer, return JSON (and only JSON) in this format: {"error": "reason", "errorNumber": errorNumber}.  Replace the words "reason" and "codeNumber" with the option that best fits:
  Option 1: {"error": "You did not tell me which case to analyze."  "errorNumber": 1}
  Option 2: {"error": "I'm not aware of that case.  It may have been written after the cutoff date of my training data.", "errorNumber": 2}
  Option 3: {"error": "Other: [Provide a custom 1--sentence explanation of why you don't know the answer.]", "errorNumber": 3}
`

export const fieldValueOptions = [{
    label: 'Approved',
    value: 'approved',
    type: 'string',
  }, {
    label: 'Published',
    value: 'published',
    type: 'string',
  }, {
    label: 'In showcase',
    value: 'showcased',
    type: 'string',
  }];

export const operandValueOptions = [{
    label: 'equals',
    value: '==',
    type: 'string',
  }, {
    label: 'does not equal',
    value: '!=',
    type: 'string',
  }];

export const filterValueOptions = [{
    label: 'True',
    value: true,
    type: 'boolean',
  }, {
    label: 'False',
    value: false,
    type: 'boolean',
  }];

export const sortDirectionOptions = [{
  label: '↑',
  value: 'asc',
  type: 'string',
}, {
  label: '↓',
  value: 'desc',
  type: 'string',
}];

export const sortFieldOptions = [{
  label: 'Date created',
  value: 'dateCreated',
  type: 'string',
}, {
  label: 'Date updated',
  value: 'dateUpdated',
  type: 'string',
}, {
  label: 'Date published',
  value: 'datePublished',
  type: 'string',
}, {
  label: 'Title',
  value: 'title',
  type: 'string',
}, {
  label: 'Code',
  value: 'code',
  type: 'string',
}, {
  label: 'Topic ID',
  value: 'topicId',
  type: 'string',
}, {
  label: 'Date approved',
  value: 'dateApproved',
  type: 'string',
}, {
  label: 'Showcase approved',
  value: 'dateShowcased',
  type: 'string',
}];

