import moment from "moment";

const AdminSmallArticleItem = ({ 
  article, 
  index, 
  arr, 
  selectedArticle,
  setSelectedArticleId, 
  topicId,
  publishedArticleId,
}) => {

  const { 
    articleId,
    // dateCreated,
    dateUpdated,
  } = article;
  

  return (
    <div>
      <button 
      className={`
        text-left px-4 py-2 w-full
        ${selectedArticle?.articleId !== article?.articleId && 'hover:bg-gray-50'}
        ${selectedArticle?.articleId === article?.articleId && 'bg-blue-50 hover:bg-blue-100'}
        ${index === 0 && 'rounded-t'} 
        ${index === arr.length - 1 && 'rounded-b'} 
      `}
      onClick={() => {
        if (selectedArticle?.articleId === article?.articleId) {
          setSelectedArticleId(null);
        } else {
          setSelectedArticleId(article?.articleId);
        }
      }}
    >
        <div className="text-gray-700 font-semibold text-sm">Article {index + 1}</div>
        <div className="text-xs text-gray-500">Updated: {moment(dateUpdated).format('MMM Do YY h:mm:ss')}</div>
        <div className="text-xs text-gray-500">ArticleId: {articleId}</div>
        <div className="flex">
          {articleId === publishedArticleId && <div className="text-xs text-blue-700 bg-blue-200 border border-blue-700 py-0.5 px-1  rounded my-1 ">Published</div>}
        </div>
      </button>
      {index !== arr.length - 1 && <div className={`divide-y border-t border-gray-300`} />} { /* my-2 */ }
    </div>

  )

}

export default AdminSmallArticleItem;