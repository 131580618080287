import RequiredAsterisk from "./RequiredAsterisk";
import {useState, useEffect} from 'react';
import Select from 'react-select';
import { useStore } from "../store/store";
import { validateEmail } from "../helpers/validation";
import { updateAccount } from "../actions/accounts";
import { spinnerIcon, exclamationIcon, returnChevronRight, returnCheckCircleIcon } from "../helpers/icons";
import { useNavigate } from 'react-router-dom';
import { returnHomePath } from "../helpers/urls";
import { trackEvent } from "../dependencies/mixpanel";



const EnterAccountInformationPage = () => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonSucceeded, setButtonSucceeded] = useState(false);
  const user = useStore(state => state.user);
  const account = useStore(state => state.account);
  const setAccount = useStore(state => state.setAccount);
  const [name, setName] = useState('');
  const [eduEmail, setEduEmail] = useState('');
  const [lawSchool, setLawSchool] = useState('');
  const yearOfGraduationList = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  const convertListToOptions = (array) => array.map(elem => ({value: elem, label: elem}))
  const yearOfGraduationOptions = convertListToOptions(yearOfGraduationList);
  const [yearOfGraduation, setYearOfGraduation] = useState(yearOfGraduationOptions[0]);
  const degreeProgramList = ['JD', 'LLM', 'PhD', 'BA', 'Other'];
  const degreeProgramOptions = convertListToOptions(degreeProgramList);
  const [degreeProgram, setDegreeProgram] = useState(degreeProgramOptions[0]);
  const navigate = useNavigate();


  useEffect(() => {
    if (account?.email && account?.email.endsWith('.edu')) {
      setLawSchool(account?.email);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.email]);

  const validateForm = () => {
    let validated = false
    if (name.length <= 2 || !name.includes(' ')) {
      setErrorMessage('You must enter your full name.');
    } else if (!eduEmail || !validateEmail(eduEmail) || !eduEmail.endsWith('.edu')) {
      setErrorMessage('You must enter a valid .edu email address.');
    } else if (name.length <= 3) {
      setErrorMessage('You must enter a valid law school.');
    } else if (!yearOfGraduation) {
      setErrorMessage('You must select a year of graduation.');
    } else if (!degreeProgram) {
      setErrorMessage('You must select a degree program.');
    } else {
      validated = true;
    }
    console.log(errorMessage);
    return validated;
  }

  const handleClick = () => {
    if (!validateForm()) return;
    setButtonLoading(true);
    let params = {
      name,
      eduEmail,
      lawSchool,
      yearOfGraduation,
      degreeProgram,
    }
    updateAccount({ user, account: { ...params } }).then(() => {
      setAccount({ ...account, ...params });
      setButtonLoading(false);
      setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({name: 'Extra account data submitted', params})
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Account could not be updated.');
      setButtonLoading(false);
    })
  }

  useEffect(() => {
    if (!buttonLoading) return;
    setTimeout(() => {
      setButtonSucceeded(false);
      navigate(returnHomePath())
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonLoading])




  return (
    <div className="bg-blue-500">
      {/* Container for demo purpose */}
      <div className="container flex min-h-screen-adj items-center mx-auto md:px-6 justify-center">
        {/* Section: Design Block */}
        <section className="mb-12 bg-neutral-50 text-center dark:bg-neutral-900 lg:text-left">
          <div className="px-6 py-12 md:px-12">
            <div className="grid items-center gap-12 lg:grid-cols-2">
              <div className="mt-12 lg:mt-0">
                <h1 className="mb-4 text-5xl font-bold leading-tight tracking-tight">
                  Get started
                </h1>
                <p className="text-neutral-600 dark:text-neutral-200">
                  Tell us about yourself so that we can optimize your experience.
                </p>
              </div>
              <div className="mb-12 lg:mb-0">
                <div className="flex flex-col bg-gray-100 p-8 items-center max-w-md w-full space-y-8">
                  {/* <h2 className="text-center text-xl font-extrabold text-gray-900">
                    Your account
                  </h2>      */}
                  <div className="max-w-md w-full space-y-4">
            
                  {/* Name */}
                  <div>
                    <label htmlFor="name" className="text-sm font-semibold">Name<RequiredAsterisk /></label>
                    <input
                      id="name"
                      type="text"
                      autoComplete="name"
                      required
                      className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                      value={name}
                      onChange={(e) => { setName(e.target.value) }}
                      placeholder="John Smith"
                    />
                  </div>

                    {/* Law school */}
                    <div>
                      <label htmlFor="law-school" className="text-sm font-semibold">Law School<RequiredAsterisk /></label>
                      <input
                        id="law-school"
                        type="text"
                        autoComplete="name"
                        required
                        className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        value={lawSchool}
                        onChange={(e) => { setLawSchool(e.target.value) }}
                        placeholder="Stanford Law School"
                      />
                    </div>

                      {/* .edu email */}
                      <div>
                        <label htmlFor="edu-email" className="text-sm font-semibold">Law School Email<RequiredAsterisk /></label>
                        <div className="text-xs text-gray-500">Must be .edu.</div>
                        <input
                          id="edu-email"
                          type="text"
                          autoComplete="email"
                          required
                          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                          value={eduEmail}
                          onChange={(e) => { setEduEmail(e.target.value) }}
                          placeholder="john@stanford.edu"
                        />
                      </div>

                        {/* Year of graduation */}
                        <div>
                          <label htmlFor="year-of-graduation" className="text-sm font-semibold">Year of graduation<RequiredAsterisk /></label>
                          <Select
                            id="year-of-graduation"
                            classNamePrefix="select"
                            isClearable={false}
                            // isRtl={isRtl}
                            isSearchable={true}
                            value={yearOfGraduationOptions.filter(elem => elem.value === yearOfGraduation)[0]}
                            options={yearOfGraduationOptions}
                            onChange={(option) => setYearOfGraduation(option.value)}
                          />
                        </div>

                        {/* Degree program */}
                        <div>
                          <label htmlFor="degree-program" className="text-sm font-semibold">Degree program<RequiredAsterisk /></label>
                          <Select
                            id="degree-program"
                            classNamePrefix="select"
                            isClearable={false}
                            // isRtl={isRtl}
                            isSearchable={true}
                            value={degreeProgramOptions.filter(elem => elem.value === degreeProgram)[0]}
                            options={degreeProgramOptions}
                            onChange={(option) => setDegreeProgram(option.value)}
                          />
                        </div>
                      </div>

                      <div className="w-full">
                        <button
                          type="submit"
                          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          onClick={(e) => { handleClick(e.target.value) }}
                          disabled={buttonLoading}
                        >
                          {buttonLoading ? spinnerIcon :
                            (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                              {returnChevronRight()}
                            </span>)
                          }
                          {buttonLoading || buttonSucceeded ? '' : 'Continue'}
                          {buttonLoading || !buttonSucceeded ? '' : <span
                          >{returnCheckCircleIcon('w-5 h-5')}</span>}
                        </button>
                      </div>
                      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}


                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section: Design Block */}
      </div>
      {/* Container for demo purpose */}
    </div>
  )
}

export default EnterAccountInformationPage;