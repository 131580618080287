import React, { useState } from 'react';
import { returnCheckCircleIcon, returnCheckIcon, returnChevronUp, returnExclamationIcon, returnSpinnerIcon } from '../helpers/icons';
import { saveTopicUpvote } from '../actions/topics';
import { useStore } from '../store/store';
import ReportTopicModal from './ReportTopicModal';
import pluralize from 'pluralize';
import { trackEvent } from '../dependencies/mixpanel';
import NavigationSidePanel from './NavigationSidePanel';

const ViewTopicSidePanel = ({
  topicId, 
  numUpvotes, 
  setNumUpvotes, 
  upvoted, 
  setUpvoted,
  streamingResponse,
  streamingSuccessful,
  // readingTime,
  behindPaywall,
  anchorList,
}) => {

  // Set up
  const user = useStore(state => state.user);
  const [reportModalOpen, setReportModalOpen] = useState(false);


  const handleToggleUpvote = async () => {
    await saveTopicUpvote({
      user,
      topicId,
      upvoted: !upvoted
    }).then(({ topicId, upvoted, upvotedBy }) => {
      setUpvoted(upvoted)
      setNumUpvotes(state => upvoted ? state + 1 : state - 1);
      trackEvent({ name: 'Topic updated', params: { topicId, upvoted } })

      // setErrorMessage('');
    }).catch((error) => {
      console.log(error)
      console.error('Error changing upvote status: ', error)
      // setErrorMessage('Topic upvote status could not be changed.');
    })
  };

  return (

    <div className="flex flex-col space-y-4 sticky inset-y-16">

      {/* Top section */}
      <div className={`border rounded-lg bg-white py-4 w-48 flex flex-col space-y-2 `}>

        {/* Voting */}
        <button
          className="flex px-4 space-x-2 items-center hover:underline"
          onClick={() => { handleToggleUpvote() }}
          disabled={behindPaywall}
        >
          <div
            className={`p-2 border border-blue-500 rounded-full  ${upvoted ? 'bg-blue-500 text-white' : 'text-blue-500 '}`}
          >
            {upvoted ? returnCheckIcon({ className: 'h-4 w-4' }) : returnChevronUp('h-4 w-4')}
          </div>
          <div className={`text-center text-sm text-blue-500`}>{numUpvotes} {pluralize('vote', numUpvotes)}</div>
        </button>

        {/* Report */}
        <button
          className="flex px-4 space-x-2 items-center hover:underline"
          onClick={() => { setReportModalOpen(true) }}
          disabled={behindPaywall}
        >
          <div
            className={`p-2 rounded-full text-blue-500`}
          >
            {returnExclamationIcon('h-4 w-4')}
          </div>
          <div className={`text-center text-sm text-blue-500`}>Report</div>
        </button>
        <ReportTopicModal
          topicId={topicId}
          open={reportModalOpen}
          setOpen={setReportModalOpen}
        />

        {/* Streaming response */}
        {streamingResponse && <div
          className="flex px-4 space-x-2 items-center hover:underline"
        >
          <div
            className={`p-2 rounded-full text-blue-500`}
          >
            {returnSpinnerIcon('w-4 h-4 text-blue-500')}
          </div>
          <div className={`text-left text-sm text-blue-500`}>Generating...</div>
        </div>}

        {/* Streaming successful */}
        {streamingSuccessful && <div
          className="flex px-4 space-x-2 items-center"
        >
          <div
            className={`p-2 rounded-full text-blue-500`}
          >
            {returnCheckCircleIcon('w-4 h-4 text-blue-500')}
          </div>
          <div className={`text-left text-sm text-blue-500`}>Generated</div>
        </div>}
      </div>
      <NavigationSidePanel anchorList={anchorList} />


      {/* Bottom section
      {false && <div>
        {readingTime && <div
          className="flex px-4 space-x-2 items-center"
        >
          <div className={`text-left text-sm text-blue-500`}>{moment(readingTime).format('m')} min. read</div>
        </div>}
      </div>} */}


    </div>

  )
}

export default ViewTopicSidePanel;

// const ViewTopicSidePanel = () => {
//   return (
//     <div>Test</div>
//   )
// }

// export default ViewTopicSidePanel;