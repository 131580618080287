import SearchForTopic from './SearchForTopic';
// import { returnBlackLogoPath } from '../helpers/urls';
import { /* appName, */ returnH1, returnH2 } from '../helpers/data';
import TopicShowcase from './TopicShowcase';
import { useEffect, useRef } from 'react';
import { useKeyPress } from '../helpers/useKeyPress';


const SearchForTopicPage = () => {


  const cmdIsPressed = useKeyPress({ key: "Meta" })
  const kIsPressed = useKeyPress({ key: "k" })
  const inputRef = useRef();

  useEffect(() => {
    if (cmdIsPressed && kIsPressed) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmdIsPressed, kIsPressed]);



  return (
    // bg-gradient-to-br from-gray-100 via-white to-gray-200
    <div className="flex flex-col min-h-screen-adj bg-gray-100 py-8 sm:px-6 lg:px-8 items-center">
        <div className="flex flex-col items-center lg:mt-8 space-y-4">
          {/* <div
            className=" text-gray-900 font-semibold flex space-x-2 space-y-2 items-start text-center"
          >
            <img src={returnBlackLogoPath()} className="w-16 h-16 text-gray-700 " alt={appName} />
            <span className="text-5xl p-0.5 font-cuprum">{appName}</span>
          </div> */}
          <div className="text-6xl font-semibold">{returnH1()}</div>
        <div className="text-xl text-gray-700">{returnH2()}</div>
        </div>

        <div className="flex justify-center w-full z-50">
          <SearchForTopic showEsc={false} inputRef={inputRef} showCmdK={false} />
        </div>

      <div className="flex justify-center w-full">
        <TopicShowcase />
      </div>

      </div>
  )
}

export default SearchForTopicPage;