import AdminTopicsListItem from './AdminTopicsListItem';

const AdminTopicsList = ({
  topics,
  handleUpdateTopicsArray,
  handleRemoveTopicFromArray,
  handleUpdateTopicsArrayWithBasicTopicData,
}) => {

  
  return (
    <div className="flex flex-col space-y-4 mt-4">

      <div className="grid grid-cols-8 text-sm font-semibold text-gray-700">
        <div className="col-span-1 text-gray-700">Topic</div>
        <div className="col-span-1 text-white-500">Updates</div>
        <div className="col-span-1 text-gray-700">Publish</div>
        <div className="col-span-1 text-gray-700">Approve</div>
        <div className="col-span-1 text-gray-700">Showcase</div>
        <div className="col-span-1 text-gray-700">Update</div>
        <div className="col-span-1 text-gray-700">Delete</div>
        <div className="col-span-1 text-white-500">Complete info</div>
      </div>


      {topics.map((topic, index) => {
        return (
          <AdminTopicsListItem 
            key={index}
            topic={topic}
            handleUpdateTopicsArray={handleUpdateTopicsArray}
            handleRemoveTopicFromArray={handleRemoveTopicFromArray}
            handleUpdateTopicsArrayWithBasicTopicData={handleUpdateTopicsArrayWithBasicTopicData}
          />
        )
      })}
    </div>

  );
};


export default AdminTopicsList;