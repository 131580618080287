import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import 'react-quill/dist/quill.snow.css';
import { spinnerIcon, exclamationIcon, returnChevronRight, returnCheckCircleIcon } from "../helpers/icons";
import MDEditor from '@uiw/react-md-editor';
import { updateArticle } from '../actions/articles';
import { trackEvent } from '../dependencies/mixpanel';


export const AdminUpdateArticleContent = ({
  topicId,
  updateArticlesArray,
  selectedArticle,
}) => {


  // Settings
  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonSucceeded, setButtonSucceeded] = useState(false);

  // Variables
  const [articleContent, setArticleContent] = useState(selectedArticle?.content || null);

  // When article is changed, update content
  useEffect(() => {
    setArticleContent(selectedArticle?.content || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticle?.articleId])

    /* Update article */
  const handleUpdateArticle = () => {
    setButtonLoading(true);
    updateArticle({ 
      user, 
      topicId, 
      article: {...selectedArticle, content: articleContent},
    }).then((article) => {
      console.log({article})
      updateArticlesArray(article);
      setButtonLoading(false);
      setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({name: 'Admin article updated', params: {topicId, article}})
    }).catch((error) => {
      console.log(error)
      setErrorMessage('Article content could not be updated.');
      setButtonLoading(false);
    })
  }

  useEffect(() => {
    if (!buttonSucceeded) return;
    setTimeout(() => {
      setButtonSucceeded(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSucceeded]);


  return (
    <div className="w-full flex flex-col space-y-4">

      {/* Title */}
      <div className="flex items-end justify-between">
        <div className="font-semibold text-3xl">Update article content: {selectedArticle?.articleId}</div>
      </div>

      {/* Content section */}
      {/* <div className="text-sm text-gray-700 font-semibold my-1">Content</div> */}
      <div className="prose">
        {/* <ReactMarkdown>{selectedArticle?.content}</ReactMarkdown> */}
        <MDEditor
          value={articleContent}
          onChange={setArticleContent}
        />
      </div>


        {/* Submit */}
        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => { handleUpdateArticle()}}
            disabled={buttonLoading}
          >
            {buttonLoading ? spinnerIcon :
              (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                {returnChevronRight()}
              </span>)
            }
            {buttonLoading || buttonSucceeded ? '' : 'Update article content'}
            {buttonLoading || !buttonSucceeded ? '' : <span
            >{returnCheckCircleIcon('w-5 h-5')}</span>}
          </button>
        </div>

        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>
  )
}

export default AdminUpdateArticleContent;