import {useState, useEffect} from 'react';
import {useStore} from '../store/store';
import { returnViewTopicWithPublishedArticlePath } from '../helpers/urls';
import { getTopicsInShowcaseWithoutArticles } from '../actions/topics';
import { makeSuspenseful } from '../helpers/makeSuspenseful';

const TopicShowcase = () => {

  const user = useStore(state => state.user);
  const [topics, setTopics] = useState([]);
  const publishedTopics = topics.filter((topic) => !!topic?.published);
  // const evenTopics = publishedTopics?.length % 2 === 1 ? publishedTopics.splice(0, publishedTopics.length - 1) : publishedTopics;
  const evenNumberOfTopics = publishedTopics?.length % 2 === 0;
  const [loading, setLoading] = useState(true);
  const [succeeded, setSucceeded] = useState(false);


  // On start, get articles
  useEffect(() => {
    setLoading(true);
    makeSuspenseful(getTopicsInShowcaseWithoutArticles({ user }).then(({ topics, lengths }) => {
      setTopics(topics);
      setLoading(false);
      setSucceeded(true);
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error retrieving showcase', errorCode, errorMessage)
      setLoading(false);
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (loading || !succeeded || !publishedTopics || publishedTopics?.length === 0) {
    return '';
  } else {
    return (

      <div className="w-full sm:align-middle max-w-lg sm:w-full flex flex-col items-center space-y-4">

        <div className="text-xl text-gray-700">Trending</div>

        
        <div className="w-full grid grid-cols-2 h-full gap-4">

          {publishedTopics?.map(({ title, code, topicId, numVotes }, index) => {
            return (<button
              className={`${!evenNumberOfTopics && index === publishedTopics?.length - 1 ? 'col-span-2' : 'col-span-1'} p-4 rounded-lg border border-gray-300 hover:bg-blue-100 hover:border-blue-100`}
              key={index}
              onClick={() => { window.open(returnViewTopicWithPublishedArticlePath({ topicId }), "_self") }}
            >
              <div className="text-gray-700 font-semibold">{title}</div>
              <div className="text-xs text-gray-500">{code}</div>
              {numVotes && numVotes !== 0 ? <div className="text-xs text-gray-500">{numVotes} votes</div> : ''}
            </button>)

          })}

      </div>


      </div>
    )
  }
};

export default TopicShowcase;