
import { useState } from 'react';
import { useStore } from '../store/store';
import { exclamationIcon } from "../helpers/icons";
import Switch from './Switch';
import { saveTopicShowcase } from '../actions/topics';
import { trackEvent } from '../dependencies/mixpanel';



export const AdminShowcaseSwitch = ({
  topicId,
  showcased,
  setShowcased,
}) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);

  // When an article is published, update state
  const handleTopicShowcase = async () => {
    saveTopicShowcase({
      user,
      topicId,
      showcased: !showcased
    }).then(({ topicId, showcased }) => {
      setShowcased(showcased);
      setErrorMessage('');
      trackEvent({name: 'Admin topic showcased', params: {topicId, showcased}})
    }).catch((error) => {
      console.log(error)
      console.error('Error changing topic showcased status', error)
      setErrorMessage('Topic showcased status could not be changed.');
    })
  }

  return (
    <div className="mt-1 flex space-x-2 items-center">
        <Switch
          on={!!showcased}
          setOn={() => { handleTopicShowcase() }}
        />
        <div className={`text-xs ${showcased ? 'text-blue-500' : 'text-gray-500'}`}>{showcased ? 'In showcase' : 'Now in showcase'}</div>
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
  );
};

export default AdminShowcaseSwitch;