import { useState, useEffect } from 'react';
import { useStore } from "../store/store";
import { beginStripeCheckout } from "../actions/stripe";
import { spinnerIcon, exclamationIcon, returnCheckCircleIcon } from "../helpers/icons";
import { useNavigate } from 'react-router-dom';
import { returnHomePath } from "../helpers/urls";
import { trackEvent } from '../dependencies/mixpanel';



const TrialExpiredPage = () => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonSucceeded, setButtonSucceeded] = useState(false);
  const user = useStore(state => state.user);
  // const account = useStore(state => state.account);
  // const setAccount = useStore(state => state.setAccount);
  const navigate = useNavigate();


  const handleClick = () => {
    setButtonLoading(true);
    beginStripeCheckout({ user }).then(({sessionUrl}) => {
      window.open(sessionUrl, "_self");
      setButtonLoading(false);
      trackEvent({name: 'Stripe checkout session created'});
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Stripe checkout session could not be initiated.');
      setButtonLoading(false);
    })
  }

  useEffect(() => {
    if (!buttonLoading) return;
    setTimeout(() => {
      setButtonSucceeded(false);
      navigate(returnHomePath())
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonLoading])




  return (
    <div className="bg-blue-500">
      {/* Container for demo purpose */}
      <div className="container flex min-h-screen-adj items-center mx-auto md:px-6">
        {/* Section: Design Block */}
        <section className="mb-32 bg-neutral-50 text-center dark:bg-neutral-900 lg:text-left">
          <div className="px-6 py-12 md:px-12">
            <div className="grid items-center gap-12 lg:grid-cols-2">

              {/* Left side */}
              <div className="mt-12 lg:mt-0">
                <h1 className="mb-12 text-5xl font-bold leading-tight tracking-tight">
                  Your trial expired.
                </h1>
                <p className="text-neutral-600 dark:text-neutral-200">
                  Subscribe to Access unlimited case briefs for 14 days.
                  To get the best experience, tell us about yourself.
                </p>
              </div>

              {/* Right side */}
              <div className="mb-12 lg:mb-0 mx-auto">
                <div className="flex flex-col bg-gray-100 p-8 items-center max-w-md w-full space-y-8">
                  {/* <h2 className="text-center text-xl font-extrabold text-gray-900">
                    Your account
                  </h2>      */}
                  <div className="max-w-md w-full space-y-4">

                    <div className=" bg-gray-100 overflow-auto">
                      <div className="container mx-auto max-w-4xl">
                        
                        <div className="flex space-x-10">
                          
                          <div className="py-12">
                            <div className="bg-white w-96 pt-4 rounded-xl space-y-2 overflow-hidden transition-all duration-500 transform hover:-translate-y-6 -translate-y-2 scale-105 shadow-xl hover:shadow-2xl cursor-pointer">
                                <h4 className="px-4 w-full text-center text-xl font-bold text-gray-800">Unlimited access</h4>
                              <h1 className="text-4xl text-center font-bold">$19.99</h1>
                              <div className="text-center text-gray-500">per month</div>
                              {/* <p className="px-4 text-center text-sm ">
                                It is a long established fact that a reader will be distracted by
                                the readable content of a page when looking at its layout. The point
                                of using Lorem
                              </p> */}
                              <ul className="text-center text-gray-700 text-sm ">
                                <li>
                                  <div className="list-item">
                                    The highest quality case briefs.
                                  </div>
                                </li>
                                <li>
                                  <div className="">
                                    Thousands of cases.
                                  </div>
                                </li>
                                <li>
                                  <div className="">
                                    Cancel at any time.
                                  </div>
                                </li>
                              </ul>
                              <div className="w-full">
                                <button
                                  type="submit"
                                  className="h-16 group relative w-full flex justify-center items-center text-xl py-2 px-4 border border-transparent font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  onClick={(e) => { handleClick(e.target.value) }}
                                  disabled={buttonLoading}
                                >
                                  {buttonLoading ? spinnerIcon :
                                    (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                                      {/* {returnChevronRight()} */}
                                    </span>)
                                  }
                                  {buttonLoading || buttonSucceeded ? '' : 'Subscribe now'}
                                  {buttonLoading || !buttonSucceeded ? '' : <span
                                  >{returnCheckCircleIcon('w-5 h-5')}</span>}
                                </button>
                              </div>

                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}


                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section: Design Block */}
      </div>
      {/* Container for demo purpose */}
    </div>
  )
}

export default TrialExpiredPage;