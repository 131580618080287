/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { returnPencilIcon } from '../helpers/icons';
import {useStore} from '../store/store';
import { spinnerIcon, exclamationIcon } from "../helpers/icons";
import { updateTopic } from '../actions/topics';
import { trackEvent } from '../dependencies/mixpanel';


const UpdateTopicModal = ({ 
  open, 
  setOpen, 
  topicId,
  title,
  setTitle,
  code,
  setCode,
  handleUpdateTopicsArrayWithBasicTopicData,
  toastCallback,
}) => {

  const user = useStore(state => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const textAreaRef = useRef(null);
  // const cancelButtonRef = useRef(null)

  const [tempTitle, setTempTitle] = useState(title);
  const [tempCode, setTempCode] = useState(code);
  
  const handleUpdate = () => {
    setButtonLoading(true);
    updateTopic({user, topicId, title: tempTitle, code: tempCode}).then((data) => {
      console.log(`Updated topic: ${topicId}`);
      const {title, code} = data;
      if (handleUpdateTopicsArrayWithBasicTopicData) {
        handleUpdateTopicsArrayWithBasicTopicData({topicId, title, code})
      } else {
        setTitle(title)
        setCode(code);
      }
      setButtonLoading(false);
      setErrorMessage('');
      setOpen(false);
      trackEvent({ name: 'Topic updated', params: { topicId, title, code } })
      if (toastCallback) {
        toastCallback(`Topic updated: ${title}, ${code}`);
      }
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setButtonLoading(false);
    })
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={textAreaRef} onClose={() => {setOpen(false)}}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen-adj" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex items-center">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    {returnPencilIcon('h-6 w-6 text-blue-600')}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">

                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Update topic
                    </Dialog.Title>

                    {/* Title */}
                    <div>
                      <div className="text-sm text-gray-700 font-semibold my-1">Title</div>
                      <input
                        value={tempTitle}
                        onChange={(e) => setTempTitle(e.target.value)}
                        className="px-2 py-1 text-gray-700 border border-gray-300 w-full text-sm"
                      />
                    </div>

                    {/* Code */}
                    <div>
                      <div className="text-sm text-gray-700 font-semibold my-1">Code</div>
                      <input
                        value={tempCode}
                        onChange={(e) => setTempCode(e.target.value)}
                        className="px-2 py-1 text-gray-700 border border-gray-300 w-full text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { handleUpdate() }}
                  disabled={buttonLoading}
                >
                  <span className={buttonLoading ? "invisible" : ''}>Update</span>
                  {buttonLoading ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                </button>
              </div>
              <div>
                {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpdateTopicModal;