// From https://easysnippets.com/how-to-detect-key-press-using-react-hooks/
import { useEffect, useState } from 'react';

export const useKeyPress = ({ key, onKeyUp, onKeyDown, resetIncrement = 0 }) => {
  const [isPressed, setPressed] = useState(false)

  useEffect(() => {
    const handleDown = event => {
      const { key: pressedKey } = event
      if (key === pressedKey) {
        if (onKeyDown) onKeyDown()

        setPressed(true);
      }
    }

    const handleUp = event => {
      const { key: releasedKey } = event
      if (key === releasedKey) {
        if (onKeyUp) onKeyUp()

        setPressed(false);
      }
    }

    window.addEventListener("keyup", handleUp)
    window.addEventListener("keydown", handleDown)

    return () => {
      window.removeEventListener("keyup", handleUp)
      window.removeEventListener("keydown", handleDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPressed(false);
  }, [resetIncrement])

  return isPressed
}
