import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import { spinnerIcon, exclamationIcon, returnChevronRight, returnCheckCircleIcon } from "../helpers/icons";
import { returnViewStreamingTopicWithPublishedArticlePath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import { isCaseTitle, validateCode } from '../helpers/validation';
import {createTopic} from '../actions/topics';
import { trackEvent } from '../dependencies/mixpanel';
import RequiredAsterisk from './RequiredAsterisk';


const RequestTopicPage = () => {

  const user = useStore(state => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [buttonSucceeded, setButtonSucceeded] = useState(false);
  const navigate = useNavigate();
  let maxTitleLength = 200;
  const [topicId, setTopicId] = useState('');

  const validateForm = () => {
    let validated = false
    if (title.length === 0 && code.length === 0 ) {
      setErrorMessage('You must enter the full case title or code.');
    } else if (!isCaseTitle(title) && title.length > 0) {
      setErrorMessage('Your entry must be a legal case.  Please use "v." or "in re".');
    } else if (title.length >= maxTitleLength || code.length >= maxTitleLength) {
      setErrorMessage(`Your entry must be less than or equal to ${maxTitleLength} characters.`);
    } else if (!validateCode(code) && code.length > 0) {
      setErrorMessage(`Your must enter a valid citation.`);
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = () => {
    if (!validateForm()) return;
    setButtonLoading(true);
    createTopic({
      user,
      topic: {
        title,
        code,
      }
    }).then((topic) => {
      // setAccount({ ...account, accountType, title, name, shortBio });
      setTopicId(topic?.topicId)
      setButtonLoading(false);
      setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({ name: 'Admin topic created', params: topic })
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Topic could not be created.');
      setButtonLoading(false);
    })
  }


  // const handleClick = () => {
  //   if (!validateForm()) return;
  //   setButtonLoading(true);

  //   let topicRequest = {title, code};

  //   requestTopic({user, topicRequest}).then(({topic, article}) => {
  //     let topicId = topic?.topicId;
  //     setTopicId(topicId);
  //     let published = topic?.published;
  //     if (published && topicId) {
  //       setErrorMessage('');
  //       setButtonSucceeded(true);
  //     } else {
  //       setErrorMessage(`We're sorry.  We could not generate content for that topic.`);
  //       setButtonSucceeded(false);
  //     }
  //     setButtonLoading(false);
  //     trackEvent({ name: 'Topic requested', params: { topicId: topic?.topicId, title, code } })
  //   }).catch((error) => {
  //     console.log({error})
  //     setErrorMessage('Topic request failed.');
  //     setButtonLoading(false);
  //   })
  // }

  useEffect(() => {
    if (!topicId || topicId === undefined || topicId === 'undefined' || topicId.length <= 2 ) return;
    if (!buttonSucceeded) return;
    if (errorMessage) return console.log({errorMessage});
    navigate(returnViewStreamingTopicWithPublishedArticlePath({topicId}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId])

  return (
    <div className="flex flex-col min-h-screen-adj bg-gray-100 py-24 px-4 sm:px-6 lg:px-8 items-center">

      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create a case brief
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">

          </p>
        </div>

        {/* Topic type */}
        {/* <div>
        <label htmlFor="title" className="text-sm font-semibold">Is this topic a case brief? <RequiredAsterisk /></label>
        <div className="mt-1 flex space-x-2">
          <Switch
            on={type==='case-brief'}
            setOn={() => { setType('case-brief') }}
          />
          <div className={`text-xs ${type === 'case-brief' ? 'text-blue-500' : 'text-gray-500'}`}>{type === 'case-brief' ? 'Yes' : 'No'}</div>
          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </div>
      </div> */}

        {/* Topic title */}
        <div>
          <label htmlFor="title" className="text-sm font-semibold">Name<RequiredAsterisk /></label>
          <input
            id="name"
            type="text"
            autoComplete="name"
            required
            className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            value={title}
            onChange={(e) => { setTitle(e.target.value) }}
            placeholder="Marbury v. Madison"
          />
        </div>

        {/* Code */}
        <div>
          <label htmlFor="title" className="text-sm "><span className="font-semibold">Citation</span></label>
          <input
            id="name"
            type="text"
            autoComplete="name"
            required
            className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            value={code}
            onChange={(e) => { setCode(e.target.value) }}
            placeholder="5 U.S. 137 (1803)"
          />
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={(e) => { handleClick(e.target.value) }}
            disabled={buttonLoading}
          >
            {buttonLoading ? spinnerIcon :
              (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                {returnChevronRight()}
              </span>)
            }
            {buttonLoading || buttonSucceeded ? '' : 'Request now'}
            {buttonLoading || !buttonSucceeded ? '' : <span
            >{returnCheckCircleIcon('w-5 h-5')}</span>}
          </button>
        </div>

        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
    </div>
  )
}

export default RequestTopicPage;