import CodeEditor from '@uiw/react-textarea-code-editor';


const BasicCodeBlock = ({ code, setCode, language = 'js'}) => {
  
  return (
    <div>
      <CodeEditor
        value={code}
        language={language}
        placeholder="Please enter JS code."
        onChange={(evn) => setCode(evn.target.value)}
        padding={15}
        data-color-mode="dark"
        style={{
          fontSize: 12,
          backgroundColor: "#000000",
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
        className="rounded "
      />
    </div>
  );

}

export default BasicCodeBlock;