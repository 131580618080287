export const returnRoles = async ({user}) => {
  let result = await user.getIdTokenResult();
  return result?.claims?.roles;
}

export const didUserCompleteExtraSignUpInfo = ({account}) => {
  return !!account?.degreeProgram;
}

export const isUserSubscribed = ({account}) => {
  try {
    return account?.subscriptions.length > 0;
  } catch {
    return false;
  }
}

export const daysLeftInTrial = ({ account }) => {
  try {
    return account?.subscriptions.length > 0;
  } catch {
    return false;
  }
}