import React, { useState } from 'react'
import { returnMagnifyingGlassIcon, exclamationIcon, returnEyeIcon, returnCheckCircleIcon } from '../helpers/icons';
import { useDebounceEffect } from '../helpers/useDebounceEffect';
import { useKeyPress } from '../helpers/useKeyPress';
import { useNavigate } from 'react-router-dom';
import { returnAdminCreateTopicPath, returnAdminReviewTopicPath } from '../helpers/urls';
import { typesenseClient } from '../dependencies/typesenseClient';
import { returnSearchText, returnRequestNewText } from '../helpers/data';
import { captureAndFormatException } from '../dependencies/sentry';


const AdminSearchForTopics = () => {

  const [query, setQuery] = useState('');
  const maxResults = 10;
  const [results, setResults] = useState([]);
  let maxIndex = results.slice(0, maxResults).length; // Use - 1 if there is no create option.
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const incrementSelectedResultIndex = () => {
    setSelectedResultIndex(state => {
      return state === maxIndex ? 0 : state + 1;
    });
  }

  const decrementSelectedResultIndex = () => {
    setSelectedResultIndex(state => {
      return state === 0 ? maxIndex : state - 1;
    });
  }

  useKeyPress({
    key: "ArrowDown",
    onKeyDown: () => incrementSelectedResultIndex(),
  })

  useKeyPress({
    key: "ArrowUp",
    onKeyDown: () => decrementSelectedResultIndex(),
  })

  useKeyPress({
    key: "Escape",
    onKeyDown: () => { setQuery('') },
  })

  const handleSearch = async () => {
    // if (!query) return console.log('Cannot search.  No query');
    if (!query) return setResults([]);
    // setLoading(true);

    let searchParameters = {
      'q': query,
      // 'query_by': ['title', 'code', 'topicId'],
      'query_by' : 'title',
      'limit_hits': maxResults,
    }

    await typesenseClient.collections('topics').documents().search(searchParameters).then((result) => {
      const results = result.hits.map(
        (hit) => hit?.document
      ).sort(
        (a, b) => a.title.localeCompare(b.title)
      );
      // setLoading(false);
      setResults(results);
      setErrorMessage('');
    }).catch((error) => {
      captureAndFormatException(error);
      console.error({error})
      setErrorMessage('Could not search for topics.');
      // setLoading(false);
    })
  }

  useDebounceEffect(
    handleSearch,
    200,
    [query]
  )


  return (
    <div className="w-full justify-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center px-4">
          <div className="text-gray-400 ">{returnMagnifyingGlassIcon('h-5 w-5')}</div>
          <input
            className="bg-white px-2 py-4 w-full outline-none"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={`${returnSearchText()}`}
          />
        </div>

        <div className="mx-4 text-gray-400 border p-1 rounded text-xs">Esc</div>

      </div>

      <div className="flex flex-col">
        {results.slice(0, maxResults).map((result, index) => {
          return (
            <button
              className={`${selectedResultIndex === index ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-blue-100'} border-t p-2  text-left flex items-start justify-between }`}
              key={index}
              onClick={() => { navigate(returnAdminReviewTopicPath({topicId: result?.topicId})) }}
            >
              <div>
                <div className="text-sm text-gray-700">{result.title}</div>
                <div className="text-xs text-gray-500">{result.code}</div>
              </div>
              <div>
                <div className="text-blue-500 text-xs">{<div className={`flex space-x-1 items-center ${!result?.approved  ? 'invisible' : ''}`}>{returnCheckCircleIcon('h-4 w-4')}<span>Editor approved</span></div>}</div>
                <div className="text-blue-500 text-xs">{<div className={`flex space-x-1 items-center ${!result?.published ? 'invisible' : ''}`}>{returnEyeIcon('h-4 w-4')}<span>Published</span></div>}</div>
              </div>
            </button>
          )
        })}
        <button
          className={`${selectedResultIndex === results.slice(0, maxResults).length ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-blue-100'} border-t p-2  text-left }`}
          onClick={() => { navigate(returnAdminCreateTopicPath()) }}
        >
          <div className="text-sm text-gray-700 px-2">{returnRequestNewText()}</div>
          <div className="text-xs text-gray-500 invisible">invisible text</div>
        </button>
      </div>

      <div>
        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
    </div>
  )
}

export default AdminSearchForTopics;