import { 
  returnAdminSearchForTopicPath, 
  returnAdminViewTopicReportsPath, 
  returnAdminTypesensePath,
  returnAdminViewUnapprovedTopicsPath,
} from '../helpers/urls';
import { useNavigate } from 'react-router-dom';


const AdminConsolePage = props => {

  const navigate = useNavigate();

  return (
      <div className="min-h-screen-adj bg-gray-100 py-6 sm:px-8 lg:px-8">
        <div className="grid grid-cols-4 px-8 space-x-8">

          <button 
            className="rounded bg-gray-300 hover:bg-gray-400 col-span-1 px-2 h-16 text-sm"
            onClick={() => {navigate(returnAdminSearchForTopicPath())}}
          >
            Search for a topic
          </button>

          <button
            className="rounded bg-gray-300 hover:bg-gray-400 col-span-1 px-2 h-16 text-sm"
            onClick={() => { navigate(returnAdminViewTopicReportsPath()) }}
          >
            View topic reports
          </button>

          <button
            className="rounded bg-gray-300 hover:bg-gray-400 col-span-1 px-2 h-16 text-sm"
            onClick={() => { navigate(returnAdminViewUnapprovedTopicsPath()) }}
          >
            View unapproved topics
          </button>

          <button
            className="rounded bg-gray-300 hover:bg-gray-400 col-span-1 px-2 h-16 text-sm"
            onClick={() => { navigate(returnAdminTypesensePath()) }}
          >
            Typesense
          </button>

        </div>

    </div>
  );
};

export default AdminConsolePage;