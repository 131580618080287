import { captureAndFormatException } from '../dependencies/sentry';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateLinkedInLink = (link) => {
  return String(link)
    .toLowerCase()
    .match(
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
    );
}

export const validateUrl = (link) => {
  return String(link)
    .toLowerCase()
    .match(
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/gm
    );
}

export const isCaseTitle = (title) => {
  title = title.toLowerCase();
  return  title.includes(' v ') || 
          title.includes(' vs ') ||
          title.includes(' v. ') ||
          title.includes(' vs. ') ||
          title.includes('in re') ||
          title.includes('case of')
}

export const validateCode = (code) => {
  try {
    code = code.trim()
    return  typeof code === 'string' &&
            code.split(' ').length > 1 &&
            /\d/.test(code);
  } catch (error) {
    captureAndFormatException(error);
    console.error(error);
    return false;
  }
}