import { useState, useEffect } from 'react';
import {reindexTypesense} from '../actions/typesense';
import { useStore } from '../store/store';
import {spinnerIcon, returnChevronRight, returnCheckCircleIcon, exclamationIcon} from "../helpers/icons";
import { trackEvent } from '../dependencies/mixpanel';

const AdminTypesensePage = props => {

  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonSucceeded, setButtonSucceeded] = useState(false);


  const handleClick = () => {
    setButtonLoading(true);
    reindexTypesense({
      user,
    }).then((responseBody) => {
      console.log({ responseBody });
      setButtonLoading(false);
      setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({name: 'Admin typesense reindexed'})
    }).catch((error) => {
      console.log(error)
      setErrorMessage('Typesense could not be reindexed.');
      setButtonLoading(false);
    })  
  }

  useEffect(() => {
    if (!buttonSucceeded) return;
    setTimeout(() => {
      setButtonSucceeded(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSucceeded]);

  return (
      <div className="flex flex-col min-h-screen-adj bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => { handleClick() }}
            disabled={buttonLoading}
          >
            {buttonLoading ? spinnerIcon :
              (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                {returnChevronRight()}
              </span>)
            }
            {buttonLoading || buttonSucceeded ? '' : 'Reindex typesense'}
            {buttonLoading || !buttonSucceeded ? '' : <span className="flex space-x-2 items-center"
            >
              {returnCheckCircleIcon('w-5 h-5')} {`Typesense successfully reindexed`}
            </span>}
          </button>
        </div>

        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>
  )

};

export default AdminTypesensePage;