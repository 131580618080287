import AdminSearchForTopics from './AdminSearchForTopics';

const AdminSearchForTopicPage = () => {

  return (
    <div className="flex flex-col min-h-screen-adj bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-end justify-between">
        <div className="font-semibold text-3xl">Search for a topic</div>
      </div>
      <div className="divide-y my-2 border-t border-gray-300" />
      <div className="flex justify-center">
        <AdminSearchForTopics />
      </div>
    </div>
  )
}

export default AdminSearchForTopicPage;