import { useState, useEffect } from 'react';
import { returnMinusIcon } from "../helpers/icons";
import AdminFilterElement from './AdminFilterElement';
import { sortFieldOptions, sortDirectionOptions } from '../helpers/data';

const AdminSort = ({
  sorts,
  setSorts,
  handleAddSort,
  index,
  handleRemoveSort,
}) => {

  const [fieldValue, setFieldValue] = useState('');
  const [fieldMenuOpen, setFieldMenuOpen] = useState(false);

  const [directionValue, setDirectionValue] = useState('');
  const [directionMenuOpen, setDirectionMenuOpen] = useState(false);

  useEffect(() => {
    if (fieldMenuOpen) {
      setDirectionMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldMenuOpen])

  useEffect(() => {
    if (directionMenuOpen) {
      setFieldMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directionMenuOpen])


  const handleUpdateValue = ({value, part}) => {
    setSorts(sorts => {
      return sorts.map((sort, i) => {
        return i !== index ? sort : {
          field: part === 'field' ? value : sort?.field,
          direction: part === 'direction' ? value : sort?.direction,
        }
      })
    })
  }


  return (
    <div className="flex items-center space-x-4 rounded">

      {/* Remove field */}
      <button
        className="p-2 rounded bg-violet-200 hover:bg-violet-300 flex space-x-2 items-center border border-gray-500 text-xs text-gray-700"
        onClick={() => { handleRemoveSort(index) }}
      >
        {returnMinusIcon('h-4 w-4')}
      </button>


      {/* Field */}
      <AdminFilterElement 
        open={fieldMenuOpen}
        setOpen={setFieldMenuOpen}
        options={sortFieldOptions}
        value={fieldValue}
        setValue={setFieldValue}
        defaultText='Field'
        part='field'
        handleUpdateValue={handleUpdateValue}
        color='violet'
      />

      {/* Operand */}
      <AdminFilterElement
        open={directionMenuOpen}
        setOpen={setDirectionMenuOpen}
        options={sortDirectionOptions}
        value={directionValue}
        setValue={setDirectionValue}
        defaultText='Direction'
        part='direction'
        handleUpdateValue={handleUpdateValue}
        color='violet'
      />

    </div>

  )
}

export default AdminSort;