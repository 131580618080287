import Joi from 'joi';

export const promptSchema = Joi.object({
  model: Joi.string()
    // .valid('text-davinci-002', 'text-curie-002', 'text-babbage-002', 'text-ada-002')  // Include all the models you want to allow
    .required(),
  messages: Joi.array().items(
    Joi.object({
      role: Joi.string(),
      content: Joi.string(),
      name: Joi.string(),
      function_call: Joi.object(),
    })
  ).min(1),
  max_tokens: Joi.number()
    .integer()
    .min(1),
  temperature: Joi.number()
    .min(0)
    .max(1),
  top_p: Joi.number()
    .min(0)
    .max(1),
  frequency_penalty: Joi.number()
    .min(-1.0)
    .max(1.0),
  presence_penalty: Joi.number()
    .min(-1.0)
    .max(1.0),
});