import { useEffect, useState } from 'react';
import moment from 'moment';
import { returnAdminReviewTopicPath } from '../helpers/urls';
import Switch from './Switch';
import { saveTopicApproval, saveTopicShowcase, retrieveTitleOrCodeFromTopic, getTopicWithPublishedArticle } from '../actions/topics';
import {useStore} from '../store/store';
import { returnCheckCircleIcon, returnClipboardIcon, returnMinusCircleIcon, spinnerIcon, exclamationIcon } from '../helpers/icons';
import AdminDeleteTopicModal from './AdminDeleteTopicModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trackEvent } from '../dependencies/mixpanel';
import AdminUpdateTopicModal from './AdminUpdateTopicModal';
import { createArticleAndPublishWithStream } from '../actions/articles';


const AdminTopicsListItem = ({
  topic,
  handleUpdateTopicsArray,
  handleRemoveTopicFromArray,
  handleUpdateTopicsArrayWithBasicTopicData,
}) => {

  const {
    title,
    code,
    topicId,
    approved,
    approvedBy,
    dateApproved,
    // publishedArticleId,
    published,
    // datePublished,
    dateUpdated,
    dateCreated,
    showcased,
    showcasedBy,
    dateShowcased,
  } = topic;
  const user = useStore(state => state.user);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const launchToast = (message) => { 
    toast(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }



  const handleApproveTopic = () => {
    saveTopicApproval({
      user,
      topicId,
      approved: !approved,
    }).then(({topicId, approved, approvedBy, dateApproved}) => {
      handleUpdateTopicsArray({ ...topic, approved, approvedBy, dateApproved });
      trackEvent({ name: 'Admin topic approval saved', params: { topicId, approved, approvedBy } })
    }).catch((error) => {
      console.log(error)
      console.error('Error changing topic status', error)
      // setErrorMessage('Topic publication status could not be changed.');
    })
  }

  const handleShowcaseTopic = () => {
    saveTopicShowcase({
      user,
      topicId,
      showcased: !showcased,
    }).then(({ showcased, showcasedBy, dateShowcased }) => {
      handleUpdateTopicsArray({ ...topic, showcased, showcasedBy, dateShowcased });
      trackEvent({ name: 'Admin topic showcase saved', params: { topicId, showcased, showcasedBy } })
    }).catch((error) => {
      console.log(error)
      console.error('Error changing topic status', error)
      // setErrorMessage('Topic publication status could not be changed.');
    })
  }

  const [retrieveTitleOrCodeButtonLoading, setRetrieveTitleOrCodeButtonLoading] = useState(false);
  const handleRetrieveTitleOrCode = () => {
    setRetrieveTitleOrCodeButtonLoading(true)
    retrieveTitleOrCodeFromTopic({
      user,
      topicId,
    }).then(({ topic }) => {
      const { topicId, title, code } = topic;
      handleUpdateTopicsArrayWithBasicTopicData({ topicId, title, code });
      setRetrieveTitleOrCodeButtonLoading(false)
      trackEvent({ name: 'Admin title or code retrieved', params: { topicId, title, code } })
    }).catch((error) => {
      setRetrieveTitleOrCodeButtonLoading(false)
      console.log(error)
      console.error('Error retrieving title or code', error)
      // setErrorMessage('Topic publication status could not be changed.');
    })
  }      

  const [createAndPublishArticleLoading, setCreateAndPublishArticleLoading] = useState(false);
  const [createAndPublishArticleSucceeded, setCreateAndPublishArticleSucceeded] = useState(false);
  const [createAndPublishArticleErrorMessage, setCreateAndPublishArticleErrorMessage] = useState('');
  const handleCreateAndPublishArticle = () => {
    setCreateAndPublishArticleLoading(true)
    createArticleAndPublishWithStream({
      user,
      topicId
    }, {
      appendToContent: (str) => {console.log(str)},
    }).then( async () => {
      console.log('Ended stream');
      return getTopicWithPublishedArticle({user, topicId})
    }).then((topic) => {
      console.log('Retrieved article');
      setCreateAndPublishArticleLoading(false);
      setCreateAndPublishArticleSucceeded(true);
      setCreateAndPublishArticleErrorMessage('')
      let params = { topicId, published: topic?.published, publishedArticleId: topic?.publishedArticleId, datePublished: topic?.datePublished, publishedBy: topic?.publishedBy }
      handleUpdateTopicsArray(params);
      trackEvent({ name: 'Admin article created and published', params })
    }).catch((error) => {
      console.log('Error creating article', error)
      setCreateAndPublishArticleLoading(false);
      setCreateAndPublishArticleErrorMessage('Article could not be created.')
    })
  }

  useEffect(() => {
    if (!createAndPublishArticleSucceeded) return;
    setTimeout(() => {
      setCreateAndPublishArticleSucceeded(false);
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAndPublishArticleSucceeded]);

  return (
    <div className="grid grid-cols-8 text-xs">

      {/* Topic */}
      <div className="col-span-1 text-gray-700 w-full pr-4 flex flex-col space-y-1">
        <button
          className="text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded w-full"
          onClick={() => { window.open(returnAdminReviewTopicPath({ topicId }), '__blank') }}
        >
          <div>{title}</div>
          <div className="text-xs">{code}</div>
        </button>
        <div
          className="flex items-center justify-end mr-2 truncate cursor-pointer hover:bg-gray-200 p-1 rounded"
          onClick={() => {
            navigator.clipboard.writeText(topicId);
            launchToast(`topicID copied to clipboard: ${topicId}`);
          }}
        >
          <div className="text-xs text-gray-500 truncate">{topicId}</div>
          <span>{returnClipboardIcon('h-4 w-4')}</span>
        </div>
      </div>


      {/* Updates */}
      <div className="col-span-1 text-gray-700">
        <div>Created {moment(dateCreated).format('MMM Do YY h:mm:ss')}</div>
        <div className="text-xs text-gray-500">Updated {moment(dateUpdated).format('MMM Do YY h:mm:ss')}</div>
      </div>


      {/* Publish and create article */}
      <div className="col-span-1 ">
        <div className="flex flex-col justify-center space-y-2 mr-4">
          {published ? '' : <button
            className={`${published ? 'text-gray-700 border border-gray-500  hover:bg-gray-200' : 'text-white bg-blue-500 hover:bg-blue-600'} px-2 py-2 rounded flex items-center justify-center`}
            onClick={() => { handleCreateAndPublishArticle() }}
            disabled={createAndPublishArticleLoading}
          >
            <div className={`absolute text-center flex space-x-2 items-center ${createAndPublishArticleLoading ? '' : 'invisible'}`}>{spinnerIcon} <div>Creating article...</div></div>
            <div className={`absolute text-center flex space-x-2 items-center ${createAndPublishArticleSucceeded ? '' : 'invisible'}`}>{returnCheckCircleIcon('h-4 w-4')} <div>Article created</div></div>
            <div className={`${!createAndPublishArticleSucceeded && !createAndPublishArticleLoading ? '' : 'invisible'}`}>Create and publish article</div>
          </button>}
          {!createAndPublishArticleErrorMessage ? '' : <label className="mt-2 text-blue-500 flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {createAndPublishArticleErrorMessage}</label>}
          <div className="flex space-x-2 items-center">
            {published ? (
              <div className="text-blue-500">{returnCheckCircleIcon('h-4 w-4')}</div>
            ) : (
              <div className="text-gray-500">{returnMinusCircleIcon('h-4 w-4')}</div>
            )}
            <div className={` ${published ? 'text-blue-500' : 'text-gray-500'}`}>{published ? 'Published' : 'Not published'}</div>
          </div>
          {/* {!published ? '' : <div className="text-xs text-gray-500">Published {publishedArticleId} on {moment(datePublished).format('MMM Do YY h:mm:ss')}</div>} */}
        </div>
      </div>

      {/* Approve */}
      <div className="col-span-1 ">
        <div className="flex space-x-2">
          <Switch
            on={!!approved}
            setOn={() => {handleApproveTopic() }}
          />
          <div className={`text-xs ${approved ? 'text-blue-500' : 'text-gray-500'}`}>{approved ? 'Approved' : 'Not approved'}</div>
        </div>
        {!approved ? '' : <div className="text-xs text-gray-500">Approved by {approvedBy} on {moment(dateApproved).format('MMM Do YY h:mm:ss')}</div>}
      </div>

      {/* Showcase */}
      <div className="col-span-1 ">
        <div className="flex space-x-2">
          <Switch
            on={!!showcased}
            setOn={() => { handleShowcaseTopic() }}
          />
          <div className={`text-xs ${showcased ? 'text-blue-500' : 'text-gray-500'}`}>{showcased ? 'In showcase' : 'Not in showcase'}</div>
        </div>
        {!approved ? '' : <div className="text-xs text-gray-500">Showcased by {showcasedBy} on {moment(dateShowcased).format('MMM Do YY h:mm:ss')}</div>}
      </div>

      
      {/* Update topic */}
      <div className="col-span-1 ">
        <button
          className=" rounded border border-gray-500  hover:bg-gray-200 px-2 py-2"
          onClick={() => { setUpdateModalOpen(true) }}
        >
          Update
        </button>
        <AdminUpdateTopicModal
          open={updateModalOpen}
          setOpen={setUpdateModalOpen}
          topicId={topicId}
          title={title}
          setTitle={() => () => {console.log('Updated')}}
          code={code}
          setCode={() => {console.log('Updated')}}
          handleUpdateTopicsArrayWithBasicTopicData={handleUpdateTopicsArrayWithBasicTopicData}
          toastCallback={(message) => { launchToast(message) }}
        />
        <ToastContainer />
      </div>

      {/* Delete topic */}
      <div className="col-span-1 ">
        <button
          className=" rounded border border-red-200 bg-red-200 hover:bg-red-300 px-2 py-2"
          onClick={() => { setDeleteModalOpen(true) }}
        >
          Delete
        </button>
        <AdminDeleteTopicModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          topicId={topicId}
          callback={() => { 
            handleRemoveTopicFromArray({ topicId }) 
            toast(`Topic deleted: ${title}`, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }}
        />
        <ToastContainer />
      </div>

      {/* Complete topic information */}
      <div className="col-span-1">
        <button
          className="text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded flex items-center justify-center"
          onClick={() => { handleRetrieveTitleOrCode() }}
          disabled={retrieveTitleOrCodeButtonLoading}
        >
          <span className={`absolute text-center ${retrieveTitleOrCodeButtonLoading ? '' : 'invisible'}`}>{spinnerIcon}</span>
          <span className={retrieveTitleOrCodeButtonLoading ? 'invisible' : ''}>Complete Title or Code </span>
        </button>
      </div>


    </div>
  );
};

export default AdminTopicsListItem;