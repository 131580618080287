import { useState, useEffect } from 'react';
import { useStore } from '../store/store';
import { spinnerIcon, exclamationIcon, returnChevronRight, returnCheckCircleIcon } from "../helpers/icons";
import { createTopic } from '../actions/topics';
import { returnAdminReviewTopicPath, returnAdminSearchForTopicPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../dependencies/mixpanel';
import Switch from './Switch';
import { captureAndFormatException } from '../dependencies/sentry';


export const AdminCreateTopicPage = () => {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonSucceeded, setButtonSucceeded] = useState(false);
  const maxTitleLength = 1000;
  const maxCodeLength = 1000;
  const [topicId, setTopicId] = useState();
  const [withArticle, setWithArticle] = useState(false);

  const validateForm = () => {
    let validated = false
    if (!title || title.length > maxTitleLength) {
      setErrorMessage('You must add a title.');
    } else if (!code || code.length > maxCodeLength) {
      setErrorMessage('You must add a code.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = () => {
    if (!validateForm()) return;
    setButtonLoading(true);
    createTopic({
      user, 
      topic: {
        title,
        code,
      }, 
      withArticle,
    }).then((topic) => {
      // setAccount({ ...account, accountType, title, name, shortBio });
      setTopicId(topic?.topicId)
      setButtonLoading(false);
      setButtonSucceeded(true);
      setErrorMessage('');
      trackEvent({name: 'Admin topic created', params: topic})
    }).catch((error) => {
      console.log(error.errorCode, error.errorMessage)
      setErrorMessage('Topic could not be created.');
      setButtonLoading(false);
    })
  }


  useEffect(() => {
    // if (!buttonSucceeded) return console.log('Button did not succeed');
    if (!topicId) return console.log('No topicId');
    setTimeout(() => {
      setButtonSucceeded(false);
      navigate(returnAdminReviewTopicPath({topicId}));
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);



  return (
    <div className="flex flex-col min-h-screen-adj bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">

      <div className="flex items-end justify-between">
        <div className="font-semibold text-3xl">Create topic</div>
      </div>
      <div className="divide-y my-2 border-t border-gray-300" />
      <div className="flex mb-4">
          <button onClick={() => {navigate(returnAdminSearchForTopicPath())}} className="text-gray-500 text-sm hover:underline">{`Search`}</button>
          <div className="text-gray-500 text-sm px-2">{`>>`}</div>
          <button className="text-gray-500 text-sm hover:underline underline">{`Create topic`}</button>
      </div>


      <div className="flex justify-center items-center w-full">
          <div className="flex flex-col space-y-4 w-96">
            {/* Title */}
            <div>
              <div className="text-sm text-gray-700 font-semibold my-1">Title</div>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="px-2 py-1 text-gray-700 border border-gray-300 w-full text-sm"
              />
            </div>

            {/* Code */}
            <div>
              <div className="text-sm text-gray-700 font-semibold my-1">Code</div>
              <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="px-2 py-1 text-gray-700 border border-gray-300 w-full text-sm"
              />
            </div>

          {/* With article */}
            <div className="mt-1 flex space-x-2 items-center">
              <Switch
                on={withArticle}
                setOn={setWithArticle}
              />
              <div className={`text-xs ${withArticle ? 'text-blue-500' : 'text-gray-500'}`}>{withArticle ? 'Use AI to generate article' : `Do not generate article.`}</div>
            </div>

            {/* Submit */}
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={(e) => { handleClick(e.target.value) }}
                disabled={buttonLoading}
              >
                {buttonLoading ? spinnerIcon :
                  (<span className="absolute left-0 inset-y-0 flex items-center pl-3 opacity-25">
                    {returnChevronRight()}
                  </span>)
                }
                {buttonLoading || buttonSucceeded ? '' : 'Create topic'}
                {buttonLoading || !buttonSucceeded ? '' : <span
                >{returnCheckCircleIcon('w-5 h-5')}</span>}
              </button>
            </div>

          </div>

      </div>

      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>
  )
}

export default AdminCreateTopicPage;