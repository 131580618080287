// Uncomment if you used npm or yarn in Step 1
import mixpanel from 'mixpanel-browser';
import { returnMixpanelProjectToken } from '../config/config';
import { Hotjar } from '../dependencies/hotjar';
import { environment } from '../config/config';
import { captureAndFormatException } from '../dependencies/sentry';

const mixpanelProjectToken = returnMixpanelProjectToken();

mixpanel.init(mixpanelProjectToken, { debug: true, track_pageview: true, persistence: 'localStorage' });

const trackEvent = ({name, params}) => {
  try {
    mixpanel.track(name, params);
    if (environment !== 'local') Hotjar.event(name); // Cannot track user params for hotjar events
  } catch (error) {
    captureAndFormatException(error);
    console.error('Could not track mixpanel or hotjar event: ', error)
  }
}

const identifyUser = ({uid}) => {
  try {
    mixpanel.identify(uid);
    if (environment !== 'local') Hotjar.identify(uid)
  } catch (error) {
    captureAndFormatException(error);
    console.error('Could not identify user for mixpanel or Hotjar: ', error)
  }
}

export {
  mixpanel,
  trackEvent,
  identifyUser
};

// export const sendToMixpanel = ({uid, event, props}) => {
//   mixpanel.identify(uid)
//   mixpanel.track(event, props)
// }


