import { useState, useEffect } from 'react';
import { returnMinusIcon } from "../helpers/icons";
import AdminFilterElement from './AdminFilterElement';
import { fieldValueOptions, operandValueOptions, filterValueOptions } from '../helpers/data';

const AdminFilter = ({
  setFilters,
  index,
  handleRemoveFilter,
}) => {

  const [fieldValue, setFieldValue] = useState('');
  const [fieldMenuOpen, setFieldMenuOpen] = useState(false);
  const [operandValue, setOperandValue] = useState('');
  const [operandMenuOpen, setOperandMenuOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  useEffect(() => {
    if (fieldMenuOpen) {
      setOperandMenuOpen(false);
      setFilterMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldMenuOpen])

  useEffect(() => {
    if (operandMenuOpen) {
      setFieldMenuOpen(false);
      setFilterMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operandMenuOpen])

  useEffect(() => {
    if (filterMenuOpen) {
      setFieldMenuOpen(false);
      setOperandMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMenuOpen])

  const handleUpdateValue = ({value, part}) => {
    setFilters(filters => {
      return filters.map((filter, i) => {
        return i !== index ? filter : {
          field: part === 'field' ? value : filter?.field,
          operand: part === 'operand' ? value : filter?.operand,
          value: part === 'value' ? value : filter?.value,
        }
      })
    })
  }

  return (
    <div className="flex items-center space-x-4 rounded">

      {/* Remove field */}
      <button
        className="p-2 rounded bg-blue-200 hover:bg-blue-300 flex space-x-2 items-center border border-gray-500 text-xs text-gray-700"
        onClick={() => { handleRemoveFilter(index) }}
      >
        {returnMinusIcon('h-4 w-4')}
      </button>


      {/* Field */}
      <AdminFilterElement 
        open={fieldMenuOpen}
        setOpen={setFieldMenuOpen}
        options={fieldValueOptions}
        value={fieldValue}
        setValue={setFieldValue}
        defaultText='Field'
        part='field'
        handleUpdateValue={handleUpdateValue}
        color='blue'
      />

      {/* Operand */}
      <AdminFilterElement
        open={operandMenuOpen}
        setOpen={setOperandMenuOpen}
        options={operandValueOptions}
        value={operandValue}
        setValue={setOperandValue}
        defaultText='Operand'
        part='operand'
        handleUpdateValue={handleUpdateValue}
        color='blue'
      />

      {/* Add filter value */}
      <AdminFilterElement
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        options={filterValueOptions}
        value={filterValue}
        setValue={setFilterValue}
        defaultText='Value'
        part='value'
        handleUpdateValue={handleUpdateValue}
        color='blue'
      />



    </div>

  )
}

export default AdminFilter;