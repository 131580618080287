export const environment = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'local';

console.log({environment})

// App domain
export const returnAppDomain = () => {
  let env = environment;
  const options = {
    local: `http://localhost:3000`,
    staging: `stgx.skillite.com`, // `https://studybook-6912e.web.app`,
    production: `app.skillite.com` //`studybook-prod.web.app`,
  }
  return options[env];
}

// Landing page
export const returnLandingPageUrl = () => {
  let env = environment;
  const options = {
    local: `https://skillite.com`,
    staging: `https://skillite.com`,
    production: `https://skillite.com`,
  }
  return options[env];
}

// API
export const returnApiUrl = () => {
  let env = environment;
  const options = {
    // local: `http://localhost:5001/studybook-6912e/us-central1/api`, // Relies on local emulator
    local: 'http://localhost:8081', // *** Importantly, this cannot be https ***
    staging: `https://studybook-staging.up.railway.app`, // `https://us-central1-studybook-6912e.cloudfunctions.net/api`,
    production: `https://studybook-production.up.railway.app` // 'https://us-central1-studybook-prod.cloudfunctions.net/api'
  }
  return options[env];
}

// Firebase
export const returnFirebaseConfig = () => {
  let env = environment;
  const options = {
    local: {
      apiKey: "AIzaSyDLSt1x3d4LJU6F0FO88H9KWPpMuNsbOig",
      authDomain: "studybook-6912e.firebaseapp.com",
      projectId: "studybook-6912e",
      storageBucket: "studybook-6912e.appspot.com",
      messagingSenderId: "492741382353",
      appId: "1:492741382353:web:deea1217eb0a2072b1b5f0",
      measurementId: "G-X7M89QXJYE"
    },
    production: {
      apiKey: "AIzaSyCLB1iJQvkvbSOi9B_AO3jkVMVJyWznz_0",
      authDomain: "studybook-prod.firebaseapp.com",
      projectId: "studybook-prod",
      storageBucket: "studybook-prod.appspot.com",
      messagingSenderId: "805142812761",
      appId: "1:805142812761:web:e4dd939da14182560ab948",
      measurementId: "G-KTGDZ82Y6K"
    }
  }
  if (env === 'staging') env = 'local';
  return options[env];
}

export const returnFirebaseToggleEmulator = () => {
  let env = environment;
  const options = {
    local: true,
    staging: false,
    production: false,
  }
  return options[env];
}

// Typesense
export const returnTypesenseHostAndApiKey = () => {
  let env = environment;
  // if (env === 'local') env = 'staging';
  const options = {
    local: {
      host: 'localhost',
      apiKey: 'test-api-key' // This matches the admin key that I use to start localhost in test mode. It's not a real key.
    },
    staging: {
      host: '49tbqr2puzlfcg3ep-1.a1.typesense.net',
      apiKey: 'vM0othqU6AlCRhZIHVL0arMP8XrSQGVk' // Search only
    },
    production: {
      host: 'knh3dtylrwpeofv7p-1.a1.typesense.net',
      apiKey: 'aGAOTfwlNvtwvvDGJSoWJn0a2fEjAQeD' // Search only
    }
  }
  return options[env];
}

// Mixpanel
export const returnMixpanelProjectToken = () => {
  let env = environment;
  const options = {
    local: '57b20adc3f85f51c66bff4c5ccd5050b',
    production: 'f7721b0b239fe944c5fb726d4ab4ff74',
  }
  if (env === 'staging') env = 'local';
  return options[env]
}


// Sentry
export const returnSentryDsn = () => {
  return 'https://0a327ecbe26e33128e1116fe7632f480@o426466.ingest.sentry.io/4505645286490112';
}

export const returnSentryEnvironment = () => {
  let env = environment;
  return env;
}

// Hotjar
export const returnHotjarSiteId = () => {
  let env = environment;
  const options = {
    local: null,
    staging: '3624006',
    production: '3623993',
  }
  return options[env];
}


// Stripe