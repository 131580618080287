import { useState } from 'react';
import { useStore } from '../store/store';
import { exclamationIcon } from "../helpers/icons";
import Switch from './Switch';
import { saveTopicPublication } from '../actions/topics';
import { trackEvent } from '../dependencies/mixpanel';



export const AdminUpsertArticleSection = ({
  topicId,
  publishedArticleId,
  selectedArticle,
  setPublishedArticleId,
  setPublished,
}) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const user = useStore(state => state.user);
  const isPublished = selectedArticle?.articleId === publishedArticleId;

  // When an article is published, update state
  const handlePublishArticle = async () => {
    saveTopicPublication({ 
      user, 
      topicId, 
      articleId: selectedArticle?.articleId,
      publish: !isPublished 
    }).then(({ topicId, publishedArticleId }) => {
      setPublishedArticleId(publishedArticleId);
      setPublished(!isPublished);
      // setButtonSucceeded(true);
      trackEvent({name: 'Admin topic publication saved', params: { topicId, articleId: publishedArticleId, publish: !isPublished }})
      setErrorMessage('');
    }).catch((error) => {
      console.log(error)
      console.error('Error changing topic publication status', error)
      setErrorMessage('Topic publication status could not be changed.');
    })
  }

  return (
      <div className="mt-1 flex space-x-2 items-center">
        <Switch
          on={isPublished}
          setOn={() => { handlePublishArticle() }}
        />
        <div className={`text-xs ${isPublished ? 'text-blue-500' : 'text-gray-500'}`}>{isPublished ? 'Published' : 'Not published'}</div>
        {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
      </div>

  )
}

export default AdminUpsertArticleSection;