export const convertFirebaseErrorToHumanReadable = (error) => {
  const errorMap = {
    'auth/invalid-email': 'Invalid email address.',
    'auth/user-not-found': 'User not found.  Click "Sign up" to create an account.',
    'auth/wrong-password': 'Incorrect password.',
    'auth/email-already-in-use': 'Email address already in use.',
    'auth/weak-password': 'Password must be at least 6 characters.',
    'auth/invalid-verification-code': 'Invalid verification code.',
    'auth/invalid-verification-id': 'Invalid verification ID.',
    'auth/invalid-credential': 'Invalid credential.',    
  }
  return errorMap[error.code] || error.message || 'Error.';
}