import { returnApiUrl } from '../config/config';
import { readStream } from '../helpers/utilities';
import { captureAndFormatException } from '../dependencies/sentry';

const apiUrl = returnApiUrl();

export const getArticles = async ({ user, page, elemsPerPage }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/articles?` + new URLSearchParams({
      page,
      elemsPerPage
    }), {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const getArticle = async ({ user, articleId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    // fetch(`${apiUrl}/my`, {
    fetch(`${apiUrl}/articles/${articleId}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const oldUpsertArticle = async ({ user, article }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/articles`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ article })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const getArticlesFromTopicId = async ({ user, topicId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/articlesFromTopicId/${topicId}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const createArticle = async ({ user, topicId, requestBody }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/articles`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, requestBody })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const updateArticle = async ({ user, topicId, article }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/articles`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, article })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const createArticleFromText = async ({ user, topicId, content }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/articles/from-content`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId, content })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          captureAndFormatException(error);
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}

export const createArticleAndPublishWithStream = async ({ user, topicId }, { handleStartStreaming, appendToContent }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/createArticleAndPublishWithStream`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ topicId })
    }).then(res => {
      if (res.ok) {
        console.log({res});
        const reader = res.body.getReader();
        console.log({ reader, res })
        if (handleStartStreaming) {handleStartStreaming();}
        readStream({
          reader, 
          handleText: appendToContent, 
          handleEnd: resolve, 
          handleError: reject,
        })
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      captureAndFormatException(error);
      reject(error);
    });
  });
}